import React, { useState, useEffect } from "react";
import "./VerifyEmail.css";
import { Button, CircularProgress } from "@mui/material";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleOtpSend, userLogin, userData } from "../../../Services/Utils/Utils";
import { userEmailVerify, sendEmailAgain } from "../../../Services/Api/Api";
import OtpInput from "react-otp-input";
import Carousal from "../../../Components/Carousal/Carousal";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

function VerifyEmail() {
  const navigate = useNavigate();
  const { iev } = userData();
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const [otpValidation, setOtpValidation] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (iev) {
      window.history.replaceState(null, null, "/");
      navigate("/"); 
    }
  }, []);

  // Handle Send OTP
  const handleConfirmClick = async () => {
    if (handleOtpSend(otp, setOtp, setOtpValidation)) {
      setLoading(true);
      const result = await userEmailVerify(otp);
      const { data, status, error, message } = result || {};
      if (status === 200) {
        notification.success({ message: data?.message?.en });
        userLogin(i18n, setLoading, navigate)
      } else {
        setLoading(false);
        notification.error({ message: message?.en || error?.en || message});
      }
    }
  };

  // Handle Send Emial Again
  const handleClick = async () => {
    const result = await sendEmailAgain();
    const { data, error, status, message } = result;
    if (status === 200) {
      notification.success({ message: data?.message?.en });
    } else {
      notification.error({ message: message?.en || error?.en || message});
    }
  };


  // Submit Code - When Pree Enter
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();      
      handleConfirmClick();
    }
  };

  return (
    <div className="verify-email">
      <Header />
      <div className="container">
        <div className="verify-email-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>

          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="verify-email-section">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="main-image"
                  src="assets/images/auth/envelop.png"
                  alt="image"
                />
                <h1 className="heading">Verify Your Email</h1>
                <p className="description">Enter the code you received</p>
              </div>
              <div>
                <div className="otp-input">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="error">{otpValidation}</p>
                  </div>
                </div>

                <div className="verify-otp-button d-flex align-items-center justify-content-between">
                  <Button
                    color="primary"
                    varient="text"
                    disabled={loading}
                    onClick={handleConfirmClick}
                    onKeyPress={handleKeyPress}
                  >
                    {loading ? (
                      <CircularProgress
                        disableShrink
                        size={24}
                        sx={{ color: "white" }}
                      />
                    ) : (
                      <>
                        <p>Confirm </p>
                        <img src="assets/images/auth/arrow.png" alt="arrow" />
                      </>
                    )}
                  </Button>
                </div>

                <div className="send-again-section d-flex align-items-center justify-content-center">
                  <p>Code not received yet?</p>
                  <Button onClick={handleClick} disableRipple={true}>
                    Send again{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VerifyEmail;
