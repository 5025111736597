import React, { useEffect, useState } from "react";
import { Spin, notification } from "antd";
import "./SecondaryMarket.css";
import { getMarketPaintings, getPaintingDetails } from "../../Services/Api/Api";
import LineChart from "../../Components/Charts/LineCharts";
import MarketSlider from "../../Components/MarketSlider/MarketSlider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { scrollTop } from "../../Services/Utils/Utils";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";
import LoadMore from "../../Components/LoadMore/LoadMore";
import dayjs from "dayjs";

function SecondaryMarket() {
  const { t, i18n } = useTranslation();
  const currency = localStorage.getItem("currency");
  const [curr, setCurr] = useState(currency);
  const [current, setCurrent] = useState(0);
  const [secData, setSecData] = useState([]);
  const [baseURL, setBaseURL] = useState("");
  const [limit, setLimit] = useState(9);
  const [offset, setOffset] = useState(0);
  const [checkLength, setLength] = useState(0);
  const [isLoading, setLoading] = useState(true);
  // const [paintingDetails, setPaintingDetails] = useState();

  useEffect(() => {
    scrollTop();
    setCurrent(0);
  }, []);

  // To Show Loading
  useEffect(() => {
    if (secData.length > 0){
      setLoading(false)
    }
  }, [secData]);

  useEffect(() => {
    getDataSecondary();
  }, [limit, offset]);

  // Currency Change for Secondary Market Paintings 
  useEffect(() => {
    if (curr) {
      const dataLength = secData?.length;
      if (dataLength > 0 && offset === 0) {
        setSecData([]);
        getDataSecondary();
        setLoading(true);
      } else if (dataLength > 0) {
        setSecData([]);
        setOffset(0);
        setLoading(true);
      }
    }
  }, [curr]);

  // Get Secondary Market Paintings Data
  const getDataSecondary = async () => {
    const result = await getMarketPaintings(limit, offset, "secondary", curr);
    const { data, error, status } = result || {};
    if (status === 200) {
      setSecData((prev) => [...prev, ...data?.paintings]);
      setLength(data?.paintings?.length);
      setBaseURL(data?.base_url);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  return (
    <div className="secondary-market">
      <Header setCurr={setCurr} setCurrent={setCurrent} />
      <div className="container">
        <div className="row">
          <div className="secondary-market-section">
            <h1 className="artist-heading">{t("SecondaryMarketHead")}</h1>
            
            {isLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : secData?.length === 0 ? (
              <div className="head-tbox-no-data">
                <h4>{t("SecondaryMarketNoData")}</h4>
              </div>
            ) : (
              <div className="secondary-market-content d-flex flex-wrap">
                {secData?.map((artist, index) => (
                  <div
                    key={index}
                    className="details col-md-3 d-flex flex-column"
                  >
                    <div className="image-section">
                      <img
                        src={baseURL + artist?.cover_img}
                        alt="image"
                      />
                    </div>

                    <div className="content-section">
                      <div className="user-details d-flex align-artists-start justify-content-between">
                        <div className="d-flex align-artists-center">
                          <div>
                            <img
                              src={baseURL + artist?.artist?.img}
                              alt={`User`}
                            />
                          </div>

                          <div>
                            <div>
                              <p className="username">
                                {artist?.artist?.name}
                              </p>
                            </div>
                            <div>
                              <p className="years">
                                ({dayjs(artist?.life_dates?.date_of_birth).format("YYYY") + (
                                  artist?.life_dates?.date_of_death !== ""  ? " - " +
                                  dayjs(artist?.life_dates?.date_of_death).format( "YYYY") : "")
                                })
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <div className="d-flex">
                          <p className="price">
                            {artist?.painting_price?.value}
                          </p>{" "}
                          <p className="currency">
                            {artist?.painting_price?.curr == "USD"
                              ? "$"
                              : "€"}
                          </p>{" "}
                        </div> */}
                      </div>

                      <div className="painting-details d-flex justify-content-between mb-3">
                        <div>
                          <div>
                            <p className="name">
                              {i18n?.language === "en"
                                ? artist?.title?.en
                                : artist?.title?.fr}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="years">
                              {dayjs(artist?.year).format("YYYY")}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex flex-column align-artists-end">
                          <div className="d-flex">
                            <p className="price">
                              {artist?.price_per_part?.value}
                            </p>{" "}
                            <p className="currency">
                              {artist?.price_per_part?.curr == "USD"? "$": "€"}
                            </p>{" "}
                            <p className="part">/Part</p>
                          </div>
                          <div>
                            {artist?.percentage !=0 ? (
                              artist?.percentage >= 0 ? 
                              <p className="increament">+{artist?.percentage} %</p>
                              : 
                              <p className="decreament">{artist?.percentage} %</p>
                              ) : null
                            }
                          </div>
                        </div>
                      </div>

                      <div className="line-chart">
                        <LineChart
                          evolution_list={artist?.evolution_list}
                          details="homeSec"
                        />
                      </div>

                      <div>
                        <Link to={`/secondary-market/details/${artist?._id}`}  state={{ path: "secondary-market" }}>
                          <Button variant="outlined">{t("SecondaryMarketButton")}</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Load More */}
            <LoadMore
              limit={limit}
              setOffset={setOffset}
              data={secData}
              checkLength={checkLength}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SecondaryMarket;
