import React, { useState, useEffect } from "react";
import {
  getAdminWithdrawals,
  getProfile,
  userPhoneVerify,
  userPhoneResend,
  userWithdrawalConfirm,
} from "../../../Services/Api/Api";
import { handleOtpConfirm } from "../../../Services/Utils/Utils";
import { Table, Spin, notification } from "antd";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SmartAccOtpModal from "../../../Components/Modals/Withdraw/SmartAccOtpModal/SmartAccOtpModal";
import WithdrawModal from "../../../Components/Modals/Withdraw/WithdrawModal/WithdrawModal";
import WithdrawProcessModal from "../../../Components/Modals/Withdraw/WithdrawProcessModal/WithdrawProcessModal";
import CustomPagination from "../../../Components/Pagination/CustomPagination";
import dayjs from "dayjs";
import "./Withdraw.css";

function Withdraw() {
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [tablePendingData, setPendingTableData] = useState([]);
  const [tableApprovedData, setApprovedData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [pagePendingData, setPendingDataPage] = useState(1);
  const [limitPendingData, setPendingDataLimit] = useState(3);
  const [profileData, setProfileData] = useState();
  const [withdrawalData, setwithdrawalData] = useState();
  const amount = profileData?.wallet_amount?.value ? profileData?.wallet_amount?.value : 0;
  const curr = profileData?.wallet_amount?.curr == "USD" || !profileData ? "$" : "€";

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    getDataApproved();
  }, [page, limit]);

  useEffect(() => {
    getDataPending();
  }, [pagePendingData, limitPendingData]);

  // Get Pending Withdrwals Data
  const getDataPending = async () => {
    const offset = (pagePendingData - 1) * limitPendingData;
    const result = await getAdminWithdrawals(limitPendingData, offset, "PENDING");
    const { data, error, status } = result || {};
    if (status === 200) {
      setPendingTableData(data);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Get Approved Withdrwals Data
  const getDataApproved = async () => {
    const offset = (page - 1) * limit;
    const result = await getAdminWithdrawals(limit, offset, "APPROVED");
    const { data, error, status } = result || {};
    if (status === 200) {
      setApprovedData(data);
      setLoading2(false);
    } else {
      setLoading2(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Get User Profile Data to Show Balance
  const getProfileData = async () => {
    const result = await getProfile();
    const { data, status, error } = result || {};
    if (status === 200) {
      setProfileData(data);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  // Modals
  const [otpModal, setOtpModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [withdrawProcessModal, setWithdrawProcessModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpValidation, setOtpValidation] = useState("");

  // Open Request a Withdraw Modal
  const handleWithdrawRequest = () => {
    setWithdrawModal(true); 
    setOtp("") 
  };

  // Close Request a Withdraw Modal - And Open OTP Model
  const handleRequestWithdraw = (values) => {
    setwithdrawalData(values);
    setWithdrawModal(false);
    setOtpModal(true);
  };

  // Handle Smart Account OTP Modal
  const [loadingOtp, setLoadingOTP] = useState(false);

  // Handle Confirm Click For - Smart Account OTP Modal
  const handleConfirmClick = async () => {
    if (handleOtpConfirm(otp, setOtpValidation)) {
      setLoadingOTP(true);
      const result = await userPhoneVerify(otp, profileData);
      const { data, status, error } = result || {};
      if (status === 200) {
        // Handle Withdrwawal Confirm Request - Smart Account OTP Modal
        const withdrawal_result = await userWithdrawalConfirm(withdrawalData);
        const {
          data: withdrawalConfirmData,
          status: withdrawalStatus,
          error: withdrawalError,
        } = withdrawal_result || {};
        if (withdrawalStatus === 200) {
          setLoadingOTP(false);
          setOtpModal(false);
          setWithdrawProcessModal(true);
          setOtpModal(false);
          setOtp("")
          pageLoad();
          notification.success({ message: withdrawalConfirmData?.message?.en });
        } else {
          notification.error({ message: withdrawalError?.en });
        }
        //
      } else {
        setLoadingOTP(false);
        setWithdrawProcessModal(false);
        setOtpModal(false);
        notification.error({ message: error?.en });
      }
    }
  };

  // Phone OTP Resend
  const handlePhoneOTPResend = async () => {
    const result = await userPhoneResend(otp, profileData);
    const { data, status, error } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message?.en });
    } else {
      notification.error({ message: error?.en });
    }
  };

  // Again Call PAge Apis
  const pageLoad = () => {
    setPage(1);
    setPendingDataPage(1);
    setLimit(3);
    setPendingDataLimit(3);
    getDataPending();
    getDataApproved();
  };

  const columns = [
    {
      title: t("WithdrawReference"),
      dataIndex: "_id",
      key: "_id",
      render: (_id) => <div className="reference">{_id}</div>,
    },
    {
      title: t("WithdrawAmount"),
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount) => (
        <div>
          {amount?.value} {amount?.curr}
        </div>
      ),
    },
    {
      title: t("WithdrawDate"),
      dataIndex: "t",
      key: "t",
      align: "center",
      render: (t) => <div>{dayjs(t).format("DD MMM, YYYY")}</div>,
    },
  ];

  return (
    <div className="withdraw">
      <div className="d-flex align-items-end justify-content-between">
        <h1 className="heading">{t("Withdraw")}</h1>
        <Button
          className="withdraw-button"
          onClick={() => handleWithdrawRequest()}
        >
          {t("WithdrawRequestWithdrawal")}
        </Button>
      </div>

      <div className="d-flex">
        <p className="balance">{t("WithdrawBalance")} : </p>
        <p className="currency">
          {amount} {curr}
        </p>
      </div>

      <div className="in-progress">
        <h1 className="sub-heading">{t("WithdrawInProgress")}</h1>
        <div className="table">
          {isLoading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <div>
              <Table
                pagination={false}
                dataSource={tablePendingData}
                columns={columns}
                rowKey="_id"
              />
              <CustomPagination
                tableLength={tablePendingData?.length < 3}
                page={pagePendingData}
                setPage={setPendingDataPage}
              />
            </div>
          )}
        </div>
      </div>

      <div className="withdrawal-history">
        <h1 className="sub-heading">{t("WithdrawHistory")}</h1>
        <div className="table">
          {isLoading2 ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <div>
              <Table
                pagination={false}
                dataSource={tableApprovedData}
                columns={columns}
                rowKey="_id"
              />
              <CustomPagination
                tableLength={tableApprovedData?.length < 3}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>

      {/* Withdraw Modals */}
      <WithdrawModal
        visible={withdrawModal}
        onClose={setWithdrawModal}
        handleRequestWithdraw={handleRequestWithdraw}
        profileData={profileData}
        curr={curr}
      />
      <SmartAccOtpModal
        visible={otpModal}
        onClose={setOtpModal}
        handleConfirmClick={handleConfirmClick}
        handlePhoneOTPResend={handlePhoneOTPResend}
        otp={otp}
        setOtp={setOtp}
        otpValidation={otpValidation}
        profileData={profileData}
        loadingOtp={loadingOtp}
      />
      <WithdrawProcessModal
        visible={withdrawProcessModal}
        onClose={setWithdrawProcessModal}
      />
    </div>
  );
}

export default Withdraw;
