import React from "react";
import "./HomeSecondary.css";
import { Button } from "@mui/material";
import Newsletter from "../Newsletter/Newsletter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function HomeSecondary({ secondaryPainting, baseURL, currency }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="home-secondary">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomeSecondaryHeading")}</h2>
            </div>

            {secondaryPainting?.length === 0 ? (
              <div className="head-tbox-no-data">
                <h4>{t("HomeSecondaryNoData")}</h4>
              </div>
              ) : (
              <Swiper
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                navigation={false}
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                  1920: { slidesPerView: 4, spaceBetween: 20 },
                  1680: { slidesPerView: 4, spaceBetween: 20 },
                  1536: { slidesPerView: 4, spaceBetween: 20 },
                  1440: { slidesPerView: 4, spaceBetween: 20 },
                  1366: { slidesPerView: 4, spaceBetween: 20 },
                  1280: { slidesPerView: 4, spaceBetween: 20 },
                  1200: { slidesPerView: 4, spaceBetween: 20 },
                  1024: { slidesPerView: 3, spaceBetween: 20 },
                  768: { slidesPerView: 3, spaceBetween: 20 },
                  428: { slidesPerView: 1, spaceBetween: 20 },
                  390: { slidesPerView: 1, spaceBetween: 20 },
                  375: { slidesPerView: 1, spaceBetween: 20 },
                  360: { slidesPerView: 1, spaceBetween: 20 },
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {secondaryPainting?.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="secondarybox">
                      <div className="image-box">
                        <img
                          src={`${baseURL + data?.cover_img}`}
                          alt="secondary-image"
                        />
                      </div>
                      {data?.percentage != 0 ? (
                        data?.percentage >= 0 ? (
                          <img
                            src="/assets/images/progress-arrow.png"
                            alt="progress-arrow-up"
                            className="progress-image"
                          />
                        ) : (
                          <img
                            src="/assets/images/decreament.png"
                            alt="progress-arrow-down"
                            className="progress-image"
                          />
                        )
                      ) : null}

                      <h3>
                        {i18n?.language === "en"
                          ? data?.title?.en.substring(0, 15)
                          : data?.title?.fr}
                        , <span>{data?.artist?.name}</span>
                      </h3>
                      <h5 className="purchase">
                        {t("HomeSecondaryPurchasePrice")}
                        <span>
                          {currency === "USD" ? "$" : "€"}
                          {data?.purchase_price_per_part?.value}
                        </span>
                      </h5>
                      <h5>
                        {t("HomeSecondarySalePrice")}
                        <span>
                          {currency === "USD" ? "$" : "€"}
                          {data?.price_per_part?.value}
                        </span>
                      </h5>

                      <Link
                        to={`/secondary-market/details/${data?._id}`}
                        state={{ path: "secondary-market" }}
                      >
                        <Button>{t("HomeSecondaryDetailsButton")}</Button>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
      <Newsletter type="secondary_market" />
    </div>
  );
}

export default HomeSecondary;
