import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { BsFacebook } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineInstagram } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";
import { BiLogoYoutube } from "react-icons/bi";
import { footer } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFooterData as setFooter } from "../../Services/Store/Store";

function Footer() {
  const dispatch = useDispatch();
  const [footerData, setFooterData] = useState({
    contact_information: {
      phone: {}
    },
    social_media_links: {},
  });
  const { siteSettings, footerIcon, base_url } = footerData || {};
  const { contact_information, social_media_links,  } = siteSettings || {};
  const { email, address, phone } = contact_information || {};
  const { facebook, instagram, linkedin, twitter, youtube } = social_media_links || {};
  const socialMediaLinks = [
    { href: youtube, icon: <BiLogoYoutube /> },
    { href: linkedin, icon: <BiLogoLinkedin /> },
    { href: instagram, icon: <AiOutlineInstagram /> },
    { href: facebook, icon: <BsFacebook /> },
    { href: twitter, icon: <FaXTwitter /> },
  ];

  useEffect(() => {
    getData();
  }, []);

  const { t, i18n } = useTranslation();

  // Get Footer Data from API and Stored it in Session Storage
  const getData = async () => {
    const storedData = sessionStorage.getItem("footerData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFooterData(parsedData);
      dispatch(setFooter(parsedData));
    } else {
      const result = await footer();
      if (result) {
        setFooterData(result?.data);
        sessionStorage.setItem("footerData", JSON.stringify(result?.data));
        dispatch(setFooter(result?.data));
      } else {
        console.log("Internal Server Error");
      }
    }
  };

  return (
    <div className="footer">
      <div className="container">
        
        <div className="head-tbox">
            <img src="/assets/images/footer-artImage.png" alt="WEART"/>
        </div>
        

        <div className="row top-row">
          <div className="footer-box logo-box">
            <img src="/assets/images/Footer-Logo.png" alt="footer-logo" className="logo"/>
          </div>

          <div className="footer-box info-box">
              <h4>{t("FooterContactInfoHeading")}</h4>
              <ul>
                <li className="address-s">
                  <span><img src="/assets/images/location-icon.png" alt="image" /></span>
                  <a href="#">{address}</a>
                </li>
                <li>
                  <img src="/assets/images/phone-icon.png" alt="image" />
                  <a href={`tel:+${phone?.code}${phone?.number}`}>
                    {phone?.code} {phone?.number}
                  </a>
                </li>
                <li>
                  <img src="/assets/images/mail-icon.png" alt="image" />
                  <a href="mailto:Tse@exemple.fr">{email}</a>
                </li>
              </ul>
          </div>

          <div className="footer-box">
            <h4>{t("FooterContactHeading")}</h4>
            <ul>
              <li><Link to='/'>{t("FooterContact1")}</Link></li>
              <li><Link to='/'>{t("FooterContact2")}</Link></li>
              <li><Link to='/'>{t("FooterContact3")}</Link></li>
              <li><Link to='/'>{t("FooterContact4")}</Link></li>
              <li><Link to='/'>{t("FooterContact5")}</Link></li>
            </ul>
          </div>

          <div className="footer-box weart-box">
            <h4>Weart</h4>
            <ul>
              <li><Link to='/about'>{t("FooterWeart1")}</Link></li>
              <li><Link to='/artists'>{t("FooterWeart2")}</Link></li>
              <li><Link to='/primary-market'>{t("FooterWeart3")}</Link></li>
              <li><Link to='/secondary-market'>{t("FooterWeart4")}</Link></li>
              <li><Link to='/location'>{t("FooterWeart5")}</Link></li>
            </ul>
          </div>

          <div className="footer-box other-box">
          <h4>{t("FooterOtherHeading")}</h4>
            <ul>
              <li><Link to='/collaborators'>{t("FooterOther1")}</Link></li>
              <li><Link to='/press'>{t("FooterOther2")}</Link></li>
              <li><Link to='/faqs'>{t("FooterOther3")}</Link></li>
            </ul>
          </div>

          <div className="footer-box">
            <h4>{t("FooterComplianceHeading")}</h4>
            <ul>
              <li><Link to='/terms'>{t("FooterCompliance1")}</Link></li>
              <li><Link to='/terms'>{t("FooterCompliance2")}</Link></li>
              <li><Link to='/privacy-policy'>{t("FooterCompliance3")}</Link></li>
              <li><Link to='/privacy-policy'>{t("FooterCompliance4")}</Link></li>
              <li><Link to='/privacy-policy'>{t("FooterCompliance5")}</Link></li>
            </ul>
          </div>

          <div className="footer-box">
          <h4>{t("FooterPartnerHeading")}</h4>
            <ul>
              <li><Link to='/'>{t("FooterPartner1")}</Link></li>
              <li><Link to='/'>{t("FooterPartner2")}</Link></li>
              <li><Link to='/'>{t("FooterPartner3")}</Link></li>
            </ul>
          </div>

          <div className="footer-box">
            <h4>{t("FooterCollaboraterHeading")}</h4>
            <ul>
              {footerIcon?.map((data, index) => (
                <a target="_blank" href={data?.link} key={index}>
                  <li className="brand-box">
                    <img src={`${base_url + data?.img}`} alt="Footer-Icon" />
                  </li>
                </a>
              ))}
              {/* <li className="brand-box"><img src="/assets/images/collaborator1.png" alt="Footer Icon" /></li>
              <li className="brand-box"><img src="/assets/images/collaborator2.png" alt="Footer Icon" /></li>
              <li className="brand-box"><img src="/assets/images/collaborator3.png" alt="Footer Icon" /></li> */}
            </ul>
          </div>
        </div>

        <div className="row bottom-row">
          <div className="col-md-6">
            <div className="bottom-menu">
              <p>© 2024 <Link to='/'>WE<b>ART</b></Link>.{t("FooterCopyrights")}.</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="social-icons">
              <ul>
                {socialMediaLinks?.map(( data, index ) =>
                  data?.href ? (
                    <li key={index}>
                      <a target="_blank" href={data?.href}>
                        {data?.icon}
                      </a>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Footer;
