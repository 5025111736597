import { useState, useEffect } from "react";
import "./HomeBlogs.css";
import { Button } from "@mui/material";
import { getBlogs } from "../../Services/Api/Api";
import Newsletter from "../Newsletter/Newsletter";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function HomeBlogs() {
  const { t, i18n } = useTranslation();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // Get Blog Data
  const getData = async () => {
    const result = await getBlogs(1, 50);
    const { data, status, error } = result || {};
    if (status === 200) {
      setBlogs(data?.data);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div className="home-blogs">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomeBlogHeading")}</h2>
              <p>{t("HomeBlogPara")}</p>
            </div>

            <div className="swiper-button image-swiper-button-next">
              <KeyboardArrowRightIcon />
            </div>
            <div className="swiper-button image-swiper-button-prev">
              <KeyboardArrowLeftIcon />
            </div>

            <Swiper
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
                disabledClass: "swiper-button-disabled",
              }}
              // navigation={true}
              spaceBetween={15}
              slidesPerView={4}
              breakpoints={{
                1920: { slidesPerView: 4, spaceBetween: 20 },
                1680: { slidesPerView: 4, spaceBetween: 20 },
                1536: { slidesPerView: 4, spaceBetween: 20 },
                1440: { slidesPerView: 4, spaceBetween: 20 },
                1366: { slidesPerView: 4, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                428: { slidesPerView: 1, spaceBetween: 20 },
                390: { slidesPerView: 1, spaceBetween: 20 },
                375: { slidesPerView: 1, spaceBetween: 20 },
                360: { slidesPerView: 1, spaceBetween: 20 },
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {blogs?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="blogs-box">
                    <img
                      src={
                        i18n?.language === "en" ? item?.image : item?.image_fr
                      }
                      alt="image"
                    />
                    <span className="date">
                      {dayjs(item?.date).format("DD MMM")}
                    </span>
                    <h3>
                      {i18n?.language === "en"
                        ? item?.title.substring(0, 40)
                        : item?.title_fr.substring(0, 50)}
                    </h3>
                    <a
                      href={i18n?.language === "en" ? item?.url : item?.url_fr}
                      target="_blank"
                    >
                      <Button>{t("HomeBlogButton")}</Button>
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <Newsletter type="news" />
    </div>
  );
}

export default HomeBlogs;
