import React, { useState, useEffect } from "react";
import "./EditProfile.css";
import { Form, Input, notification } from "antd";
import { scrollTop, validatePassword, validatePhone, onChangePhone, userDetailsData } from "../../../../Services/Utils/Utils";
import PhoneInput from "react-phone-input-2";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { getProfile, postProfile, updateBank, updateEmailPaypal, updatePassword } from "../../../../Services/Api/Api";
import { useDispatch } from "react-redux";
import { setFirstName, setProfileImage } from "../../../../Services/Store/Store";

function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [formPassword] = Form.useForm();
  const [formAccounts] = Form.useForm();
  const [formPaypalEmail] = Form.useForm();
  const [profileData, setProfileData] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phoneNum, setPhoneNum] = useState("971");
  const [countryCode, setCountryCode] = useState("971");
  const [slug,setSlug]= useState("")
  
  useEffect(() => {
    scrollTop();
    getProfileData();
  }, []);

  useEffect(() => {
    if (profileData) {
      const { phone, first_name, last_name, paypal } = profileData || {};
      // Identity:
      form.setFieldsValue({
        phone: phone?.code+""+phone?.number,
        firstname: first_name,
        lastname: last_name,
      });
      // Accounts
      formAccounts.setFieldsValue({
        bank_account_owner: "",
        iban: "",
      });
      // E-Mail Paypal:
      formPaypalEmail.setFieldsValue({
        email: paypal?.email,
      });
      setPhoneNum(phone?.code+""+phone?.number);
      setSlug(phone?.slug);
      setCountryCode(phone?.code);
    }
  }, [profileData]);

  // Get Profile Data
  const getProfileData = async () => {
    const result = await getProfile();
    const { data, status, error } = result || {};
    if (status === 200) {
      setProfileData(data);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  // Handle Submit
  const onFinish = async (values) => {
    // Identity:
    if (values.firstname && values.lastname) {
      const number = phoneNum?.substring(countryCode?.length);
      values.phone = { code: countryCode, number: number, slug: slug };
      const result = await postProfile(values);
      const { data, status, error } = result || {};
      if (status === 200) {
        userDetailsData({ first_name: values.firstname, last_name: values.lastname });
        dispatch(setFirstName(values.firstname));
        notification.success({ message: data?.message?.en });
      } else {
        notification.error({ message: error?.en });
      }
      // Password:
    } else if (
      values.old_password &&
      values.password &&
      values.confirm_password
    ) {
      const result = await updatePassword(values);
      const { data, status, error } = result || {};
      if (status === 200) {
        notification.success({ message: data?.message?.en });
        formPassword.resetFields();
      } else {
        notification.error({ message: error?.en });
      }
      // Accounts:
    } else if (values.bank_account_owner && values.iban) {
      const result = await updateBank(values);
      const { data, status, error } = result || {};
      if (status === 200) {
        notification.success({ message: data?.message?.en });
        formAccounts.resetFields();
      } else {
        notification.error({ message: error?.en });
      }
      // E-Mail Paypal:
    } else {
      const result = await updateEmailPaypal(values);
      const { data, status, error } = result || {};
      if (status === 200) {
        notification.success({ message: data?.message?.en });
      } else {
        notification.error({ message: error?.en });
      }
    }
  };

  const handleCancel = () => {
    navigate("/user/profile");
  };

  return (
    <div className="editProfile">
      <div className="head-box">
        <h2 className="heading">Profile</h2>
      </div>

      <div className="first">
        <h3>Identity</h3>
        <h5>
          My Profile:
        </h5>
        <p>Add or modify your personal information</p>

        <Form
          form={form}
          className="edit-form"
          onFinish={onFinish}
        >
          <div className="d-flex justify-content-flex-start">
            <div className="firstname">
              <label>First Name</label>
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "You cannot leave this field blank",
                  },
                  {
                    max: 100,
                    message: "Can not be more than 100 characters.",
                  },
                ]}
              >
                <Input placeholder="Enter your first name" size="large" />
              </Form.Item>
            </div>

            <div className="lastname">
              <label>Last Name</label>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "You cannot leave this field blank",
                  },
                  {
                    max: 100,
                    message: "Can not be more than 100 characters.",
                  },
                ]}
              >
                <Input placeholder="Enter your last name" size="large" />
              </Form.Item>
            </div>
          </div>

          <div className="d-flex justify-content-flex-start">
            <div className="phone">
              <label>Phone Number</label>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    validator: validatePhone(
                      t,
                      form,
                      phoneNum,
                      countryCode,
                      setPhoneValidation
                    ),
                  },
                ]}
              >
                <PhoneInput
                  defaultCountry="fr"
                  country="fr"
                  countryCodeEditable={false}
                  inputProps={{ placeholder: "629550301" }}
                  value={phoneNum}
                  className={phoneValidation ? "error" : "no-error"}
                  onChange={(value, country) =>
                    onChangePhone(
                      form,
                      value,
                      country,
                      setCountryCode,
                      setPhoneNum,
                      setSlug,
                    )
                  }
                  validateStatus={form.getFieldValue("phone") ? "error" : null}
                  help={form.getFieldValue("phone") ? "error" : null}
                />
              </Form.Item>
            </div>
          </div>

          <div className="btn-box">
            <Button type="submit" className="save-btn">
              Save
            </Button>
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>

      <div className="second">
        <h3>Connection</h3>
        <h5>Email:</h5>
        <p>{profileData?.email}</p>
      </div>

      <div className="third">
        <h3>Password:</h3>
        <Form form={formPassword} className="edit-form" onFinish={onFinish}>
          <div className="old-password">
            <label>Old Password</label>
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input.Password placeholder="********" size="large" />
            </Form.Item>
          </div>

          <div className="password">
            <label>New Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                { min: 8, message: "Should be 8 characters." },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input.Password placeholder="********" size="large" />
            </Form.Item>
          </div>

          <div className="confirm-password">
            <label>Confirm Password</label>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
                // {
                //   validator: validatePassword(form),
                // },
              ]}
            >
              <Input.Password placeholder="********" size="large" />
            </Form.Item>
          </div>

          <div className="btn-box">
            <Button type="submit" className="save-btn">
              Update Password
            </Button>
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>

      <div className="fourth">
        <h3>Accounts:</h3>
        <Form
          form={formAccounts}
          className="edit-form"
          onFinish={onFinish}
          // initialValues={accountsInitialValues}
        >
          <div className="bank-account">
            <label>Bank Account Owner</label>
            <Form.Item
              name="bank_account_owner"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input placeholder="Enter bank account owner name" size="large" />
            </Form.Item>
          </div>

          <div className="iban">
            <label>International bank account identifier - IBAN</label>
            <Form.Item
              name="iban"
              rules={[
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
                {
                  max: 100,
                  message: "Can not be more than 100 characters.",
                },
              ]}
            >
              <Input placeholder="Enter IBAN" size="large" />
            </Form.Item>
          </div>

          <div className="btn-box">
            <Button type="submit" className="save-btn">
              Confirm
            </Button>
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>

      <div className="fifth">
        <Form
          form={formPaypalEmail}
          className="edit-form"
          onFinish={onFinish}
          // initialValues={paypalEmailInitialValues}
        >
          <div className="email">
            <label>E-mail Paypal:</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Invalid email",
                },
                {
                  required: true,
                  message: "You cannot leave this field blank",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Enter paypal email address"
                size="large"
              />
            </Form.Item>
          </div>

          <div className="btn-box">
            <Button type="submit" className="save-btn">
              Confirm
              <img src="/assets/images/auth/arrow.png" alt="image" />
            </Button>
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditProfile;
