import React from "react";
import "./Process.css";
import { useTranslation } from "react-i18next";

function Process({ boxValues, currency }) {
  const { commissions, countries, individuals, registrations, transactions, value, } = boxValues;
  const { t, i18n } = useTranslation();
 
  return (
    <div className="process">
      <div className="container">
        <div className="row">
          <div className="process-wrapper">
            <div className="process-box">
              <h3>{registrations}</h3>
              <h4>{t("HomeProcess1")}</h4>
            </div>

            <div className="process-box">
              <h3>+{transactions}</h3>
              <h4>{t("HomeProcess2")}</h4>
            </div>

            <div className="process-box">
              <h3>
                {currency === "USD" ? value[0]?.value : value[1]?.value}
                {currency === "USD" ? "$" : "€"}
              </h3>
              <h4>{t("HomeProcess3")}</h4>
            </div>

            <div className="process-box">
              <h3>{commissions}</h3>
              <h4>{t("HomeProcess4")}</h4>
            </div>

            <div className="process-box">
              <h3>{individuals}%</h3>
              <h4>{t("HomeProcess5")}</h4>
            </div>

            <div className="process-box">
              <h3>{countries}</h3>
              <h4>{t("HomeProcess6")}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
