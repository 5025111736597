import React from "react";
import "./AboutBanner.css";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function AboutBanner({ primaryPainting, baseURL }) {
  const { t, i18n } = useTranslation();
  const curr = primaryPainting?.price_per_part?.curr === "USD" ? "$" : "€";

  return (
    <div className={ i18n.language != "fr" ? "aboutBanner aboutBanner-en" : "aboutBanner" } >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("AboutBannerHeadText")}</h2>
            </div>

            <div className={primaryPainting?.cover_img ? "bannerBox" : "bannerBox bannerBox-nodata"}>
              {primaryPainting?.cover_img ? (
                <>
                  <div className="imageBox">
                    <img src={baseURL + primaryPainting?.cover_img} alt="" />
                  </div>

                  <div className="info-box">
                    <div className="box1">
                      <h4>{t("AboutBannerBox1")}</h4>
                      <p>
                        {curr + "" + primaryPainting?.price_per_part?.value}
                      </p>
                    </div>

                    <div className="box2">
                      <h4>{t("AboutBannerBox2")}</h4>
                      <p>
                        {primaryPainting?.total_part -
                          primaryPainting?.sold_out_part}
                      </p>
                    </div>
                  </div>

                  <div className="content-box">
                    <div className="box1">
                      <h4>
                        {i18n?.language === "en"
                          ? primaryPainting?.title?.en
                          : primaryPainting?.title?.fr}
                      </h4>
                      <h5>
                        {t("AboutBannerBox1H5")} {primaryPainting?.artist?.name}
                      </h5>
                    </div>

                    <div className="box2">
                      <Link to="/primary-market">
                        <Button>{t("AboutBannerButton")}</Button>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutBanner;
