import React, { useEffect, useState } from "react";
import { scrollTop } from "../../Services/Utils/Utils"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import MarketSlider from "../../Components/MarketSlider/MarketSlider";
import { Button } from "@mui/material";
import "./PrimaryMarket.css";
import { notification, Spin } from "antd";
import { Link } from "react-router-dom";
import { getMarketPaintings, getWorks } from "../../Services/Api/Api";
import LoadMore from "../../Components/LoadMore/LoadMore";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

function PrimaryMarket() {
  const { t, i18n } = useTranslation();
  // const [time, setTime] = useState(0);
  const currency = localStorage.getItem("currency");
  const [curr, setCurr] = useState(currency);
  const [primaryPainting, setPrimaryPainting] = useState([])
  const [baseURL, setBaseURL] = useState("");
  const [isLoading, setLoading] = useState(false);
  // const [limit, setLimit] = useState(9);
  // const [offset, setOffset] = useState(0);
  // const [allPaintingData, setAllPaintingData] = useState([]);
  // const [checkLength, setLength] = useState(0);

  // const handlePaintingsResult = (result) => {
  //   setPrimaryPainting(result?.data?.paintings);
  //   const targetDate = dayjs(result?.data?.paintings[0]?.end_date);
  //   const currentDate = dayjs();
  //   const secondsRemaining = targetDate?.diff(currentDate, 'second');
  //   setTime(secondsRemaining);
  //   setLoading(false);
  // };
  
  const getPrimaryPaintings = async () => {
    setLoading(true);
    const result = await getMarketPaintings(1, 0, 'primary', curr);
    if (result) {
      // handlePaintingsResult(result);
      setPrimaryPainting(result?.data?.paintings);
      setBaseURL(result?.data?.base_url);
      setLoading(false);
    } else {
      setLoading(false);
      console.log(("serverError"));
    }
  };

  // const getAllPaintingData = async () => {
  //   // const result = await getWorks(limit, offset, "", curr, i18n?.language);
  //   const result = await getMarketPaintings(limit, offset, 'all', curr);
  //   const { data, status, error } = result || {};
  //   if (status == 200) {
  //     setAllPaintingData((prev) => [...prev, ...data?.paintings]);
  //     setLength(data?.paintings?.length);
  //     setBaseURL(data?.base_url);
  //   } else {
  //     notification.error({ message: "Internal server error" });
  //   }
  // };

  // useEffect(() => {
  //   getAllPaintingData();
  // }, [limit, offset]);

  useEffect(() => {
    scrollTop();
    getPrimaryPaintings();
  }, [])

  useEffect(() => {
    // if (curr && allPaintingData?.length > 0) {
    //   setAllPaintingData([])
    //   getAllPaintingData();
    // }
    if (curr && primaryPainting?.length > 0) {
      setPrimaryPainting([])
      getPrimaryPaintings();
    }
  }, [curr]);

  // useEffect(() => {
  //   const timerInterval = setInterval(() => {
  //     if (time > 0) {
  //       setTime(time - 1);
  //     } else {
  //       clearInterval(timerInterval);
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(timerInterval);
  //   };
  // }, [time]);

  // Upcoming Painting Timer
  // const formatTime = (timeInterval) => {
  //   if (timeInterval < 0) {
  //     return '00:00:00';
  //   }
  //   const hours = Math.floor(timeInterval / 3600);
  //   const minutes = Math.floor((timeInterval % 3600) / 60);
  //   const seconds = timeInterval % 60;
  //   return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  // };
  
  return (
    <div className="primary">
      <Header setCurr={setCurr} />
      <div className="container">
        <div className="row">
          <div className="heading-box">
            <h1>{t("PrimaryMarketHead")}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {isLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              primaryPainting?.length === 0 ?
                <div className="head-tbox-no-data">
                  <h4>{t("PrimaryMarketNoData")}</h4>
                </div>
                :
                <div>
                  <MarketSlider
                    primaryPainting={primaryPainting[0]} 
                    baseURL={baseURL} 
                  />
                  {/* <div className="head-tbox">
                    <h4>Next upcoming in</h4>
                    <h2>{formatTime(time)}</h2>
                  </div> */}
                </div>
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12"> */}
            {/* <div className="head-tbox">
              <h4>Next upcoming in</h4>
              <h2>{formatTime(time)}</h2>
            </div> */}
            {/* <div className="upcoming-wrapper">
              {allPaintingData?.map((item, index) => (
                <div className="upcoming-box" key={index}>
                  <img src={baseURL + item?.cover_img} alt="image" />
                  <div className="info-box">
                    <h3> {i18n?.language === "en"
                      ? item?.title?.en
                      : item?.title?.fr} ({dayjs(item?.year).format("YYYY")})</h3>
                    <p> {item?.artist?.name} &nbsp; {dayjs(item?.artist?.life_dates?.date_of_birth).format("YYYY")}
                      {item?.artist?.life_dates?.date_of_death !== "" ? " - " +
                        dayjs(item?.artist?.life_dates?.date_of_death).format("YYYY") : ""}
                    </p>
                    <h5>
                      {item?.price_per_part?.value}
                      <span>{item?.price_per_part?.curr === "USD" ? "$" : "€"}</span>
                      <span className="last">/Part</span>
                    </h5>
                  </div>
                  <Link to={`/works/details/${item?.artist?.id}/${item?._id}`} state={{ path: "works", details: item }}>
                    <Button>View Details</Button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div> 
        <LoadMore
          setLimit={setLimit}
          setOffset={setOffset}
          data={allPaintingData}
          checkLength={checkLength}
        /> */}
      </div>
      <Footer />
    </div>
  );
}

export default PrimaryMarket;
