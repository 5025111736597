import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEN from "./en/en.json";
import translationsFR from "./fr/fr.json";

const resources = {
  en: {
    translation: translationsEN,
  },
  fr: {
    translation: translationsFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en", // Set default language to "en"
    fallbackLng: "fr", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    // load: "languageOnly",
    supportedLngs: ["en", "fr"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
  // let currentLanguage = i18n.language;
// Reload page on language change
// i18n.on("languageChanged", (newLanguage) => {
  // if (newLanguage !== currentLanguage) {
  //   window.location.reload();
  // }
// });
export default i18n;
