import React, { useEffect, useState } from "react";
import "./DropDowns.css";
import { Dropdown, notification } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import { userData, changeLanguage, currencyOnChange, setCurrentCurr, getSavedLanguage, logOut, useScreenWidth, authRoutes, clearLocalStorage, } from "../../Services/Utils/Utils";
import { clearToken } from "../../Services/Store/Store";
import { useDispatch, useSelector } from "react-redux";


// DrownDown For Members/Users
export const MemberDropDown = ({auth}) => {
  const { token, base_url, iev,first_name,img } = userData();
  const reduxFirstName = useSelector((state) => state.firstName);
  const reduxImg = useSelector((state) => state.profileImage);
  
  // Local state
  const [firstName, setFirstName] = useState(first_name);
  const [profileImg, setProfileImg] = useState(img);
  const { t, i18n } = useTranslation();

  // Effect to update local state from Redux if available
  useEffect(() => {
    if (reduxFirstName !== "") {
      setFirstName(reduxFirstName);
    }
    if (reduxImg !== "") {
      setProfileImg(reduxImg);
    }
  }, [reduxFirstName, reduxImg]);
  return (
    <>
      {token && iev == true ? (
        <Dropdown
          menu={[]}
          placement="bottomLeft"
          trigger={["hover"]}
          arrow={false}
          className="custom-dropdown"
          dropdownRender={(menu) => (
            <div className="dash-menu">
              <Link to="/user/portfolio">
                <img src="/assets/images/portfolio-icon.png" alt="image" />
                {t("Portfolio")}
              </Link>
              <Link to="/user/market">
                <img src="/assets/images/market-icon.png" alt="image" />
                {t("Market")}
              </Link>
              <Link to="/user/parts">
                <img src="/assets/images/parts-icon.png" alt="image" />
                {t("MyParts")}
              </Link>
              <Link to="/user/withdraw">
                <img src="/assets/images/withdrawal-icon.png" alt="image" />
                {t("Withdraw")}
              </Link>
              <Link to="/user/history">
                <img src="/assets/images/history-icon.png" alt="image" />
                {t("History")}
              </Link>
            </div>
          )}
        >
          <div className="account-dropdown">
            <img
              src={profileImg ? base_url + img : "/assets/images/user-icon.png"}
              alt="image"
              className="user-profileImg"
            />
            <h5>{firstName ? firstName : null}</h5>
            <img
              src="/assets/images/submenu-icon.png"
              alt="image"
              className="drop-arrow-s"
              // style={{ height: 7 }}
            />
          </div>
        </Dropdown>
      ) : !auth ? (
        // <div className="account-dropdown">
        //   <Link to="/login">
        //     <h5>Login</h5>
        //     <img src="/assets/images/user-icon.png" alt="image" />
        //   </Link>
        // </div>
        <div className="account-dropdown">

        <div className="head-account">
          <ul>
            <li>
              <Link to="/login">
                <Button className="head-signin">{t("headerLogin")}</Button>
              </Link>
            </li>

            <li>
              <Link to="/register">
                <Button className="head-signup">{t("headerSignup")}</Button>
              </Link>
            </li>
            </ul>
          </div>

      {/* <Link to="/login">
        <h5>Login</h5>
        <img src="/assets/images/user-icon.png" alt="image" />
      </Link> */}
    </div>
      ) : null}
    </>
  );
};


// DrownDown For Settings
export const SettingsDropDown = ({auth}) => {
  const { t, i18n } = useTranslation();
  const { token, iev } = userData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      {token ? (
        <Dropdown
          menu={[]}
          placement="bottomLeft"
          trigger={["hover"]}
          arrow={false}
          className="custom-dropdown"
          dropdownRender={(menu) => (
            <div className={i18n.language != "fr" ? "profile-menu" : "profile-menu profile-menu-fr"} >
              {iev == true ? (
                <>
                  <Link to="/user/profile">
                    <img src="/assets/images/user-icon.png" alt="image" />
                    {t("Profile")}
                  </Link>
                  <Link to="#" onClick={() => { logOut(navigate, notification, dispatch(clearToken())); }}>
                    <img src="/assets/images/logout-icon.png" alt="image" />
                    {t("Logout")}
                  </Link>
                </>
              ) : (
                <Link to="#" onClick={() => { clearLocalStorage(navigate); }}>
                  <img src="/assets/images/logout-icon.png" alt="image" />
                  {t("Logout")}{" "}
                </Link>
              )}
            </div>
          )}
        >
          <img src="/assets/images/settings-icon.png" alt="image" />
        </Dropdown>
      ) 
      // : !auth ? (
      //   <Link to="/login">
      //     <img src="/assets/images/settings-icon.png" alt="image" />
      //   </Link>
      // ) 
      : null}
    </>
  );
};


// DrownDown For Curr and Lang Change
export const LangCurrDropDown = ({currentLanguage, changeLanguage, currentCurrency, setCurrentCurrency}) => {
  const { token } = userData();
  const { i18n } = useTranslation();
  
  // Radio Button Styling
  const radioStyles = {
    color: "#C1BDBD",
    "&.Mui-checked": {
      color: "#ED1C24",
    },
  }

  return (
    <>
      <Dropdown
        menu={[]}
        placement="bottomLeft"
        trigger={["hover"]}
        arrow={false}
        className="custom-dropdown"
        dropdownRender={(menu) => (
          <div className="currency-change">
            <div className="top">
              <h4>Language:</h4>
              <RadioGroup
                name="language-radio-group"
                value={currentLanguage}
                onChange={(e) => changeLanguage(e.target.value, i18n, token)}
              >
                <FormControlLabel
                  labelPlacement="start"
                  label={
                    <div className="d-flex align-items-center name-s">
                      {/* <img src="/assets/images/country-icon.png" alt="country-image" /> */}
                      <p>English</p>
                    </div>
                  }
                  control={
                    <Radio value="en" color="primary" sx={radioStyles} className="radio-s" />
                  }
                />
                <FormControlLabel
                  labelPlacement="start"
                  label={
                    <div className="d-flex align-items-center name-s">
                      {/* <img src="/assets/images/france-icon.png" alt="country-image" /> */}
                      <p>Français</p>
                    </div>
                  }
                  control={
                    <Radio value="fr" color="primary" sx={radioStyles} className="radio-s" />
                  }
                />
              </RadioGroup>
            </div>

            {token ? null : (
              <div className="bottom">
                <h4>Currency:</h4>
                <RadioGroup
                  name="currency-radio-group"
                  value={currentCurrency}
                  onChange={(e) =>
                    currencyOnChange(e.target.value, setCurrentCurrency)
                  }
                >
                  <FormControlLabel
                    labelPlacement="start"
                    label={
                      <div className="d-flex align-items-center name-s">
                        <p>USD</p>
                      </div>
                    }
                    control={
                      <Radio value="USD" color="primary" sx={radioStyles} className="radio-s" />
                    }
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    label={
                      <div className="d-flex align-items-center name-s">
                        <p>EUR</p>
                      </div>
                    }
                    control={
                      <Radio value="EURO" color="primary" sx={radioStyles} className="radio-s" />
                    }
                  />
                </RadioGroup>
              </div>
            )}
          </div>
        )}
      >
        <div className="lang-change">
          {currentLanguage === "fr" ? (
            <>
              {/* <img src="/assets/images/france-icon.png" alt="image" /> */}
              <h5>Fr&nbsp;/{currentCurrency}</h5>
            </>
          ) : (
            <>
              {/* <img src="/assets/images/country-icon.png" alt="image" /> */}
              <h5>En&nbsp;/{currentCurrency}</h5>
            </>
          )}
          <img src="/assets/images/submenu-icon.png" alt="image" className="lang-dropIcon" />
        </div>
      </Dropdown>
    </>
  );
};
