import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useTranslation } from "react-i18next";
import "./GalleryLightBox.css";
import { IconButton } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

function GalleryLightBox({
  images,
  selectedImage,
  onClose,
  baseURL,
  limit,
  setOffset,
  checkLength,
}) {
  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState(0);
  const [loadingThumbnail, setLoadingThumbnail] = useState(false);

  useEffect(() => {
    if (selectedImage) {
      const selectedIndex = images.findIndex((item) => baseURL + item?.img === selectedImage);
      setThumbnail(selectedIndex >= 0 ? selectedIndex : 0);
    }
  }, [selectedImage]);

  const handleSlideChange = (index) => {
    setThumbnail(index);
    if (index === images?.length - 1) {
      if (checkLength == 0 || checkLength < limit) return;
      setLoadingThumbnail(true);
      addMore();
    }
  };

  const addMore = () => {
    setOffset((prev) => prev + limit);
    // Simulate loading for demonstration purposes (replace with actual loading)
    setTimeout(() => {
      setLoadingThumbnail(false);
    }, 1000);
  };

  const galleryImages = images?.map((item) => ({
    original: baseURL + item?.img,
    thumbnail: baseURL + item?.img,
    description: i18n?.language === "en" ? item?.title?.en : item?.title?.fr,
  }));

  return (
    <Modal
      open={!!selectedImage}
      onCancel={onClose}
      footer={null}
      wrapClassName="gallery-carousel"
      mask={true}
      centered
      width={"100%"}
    >
      <ImageGallery
        items={galleryImages}
        showThumbnails={true}
        showNav={true}
        showFullscreenButton={false}
        showPlayButton={false}
        thumbnailPosition="bottom"
        itemsPerRow={10}
        startIndex={thumbnail}
        onSlide={handleSlideChange}
        renderLeftNav={(onClick, disabled) => (
          <CustomLeftArrow onClick={onClick} disabled={disabled || loadingThumbnail} />
        )}
        renderRightNav={(onClick, disabled) => (
          <CustomRightArrow onClick={onClick} disabled={disabled || loadingThumbnail} />
        )}
      />
    </Modal>
  );
}

const CustomRightArrow = ({ onClick, disabled }) => (
  <IconButton
    className="custom-arrow image-gallery-custom-arrow-right"
    onClick={onClick}
    disabled={disabled}
    aria-label="Next Slide"
  >
    <KeyboardArrowRight />
  </IconButton>
);

const CustomLeftArrow = ({ onClick, disabled }) => (
  <IconButton
    className="custom-arrow image-gallery-custom-arrow-left"
    onClick={onClick}
    disabled={disabled}
    aria-label="Previous Slide"
  >
    <KeyboardArrowLeft />
  </IconButton>
);

export default GalleryLightBox;
