import React, { useEffect, useState } from "react";
import "./BuyDrawer.css";
import { Drawer, notification } from "antd";
import {
  userData,
  handleOtpConfirm,
  partsItemsConvert,
  renderPerPartsBoxes,
} from "../../Services/Utils/Utils";
import {
  Button,
  LinearProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import OtpModal from "../Modals/BuyDrawer/OtpModal/OtpModal";
import SuccessModal from "../Modals/BuyDrawer/SuccessModal/SuccessModal";
import {
  getProfile,
  purchasePaintings,
  userPhoneResend,
  userPhoneVerify,
  validatePaintings,
} from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PaypalModal from "../../Services/PaymentGateway/Paypal/PaypalModal/PaypalModal";
import DebitCardModal from "../../Services/PaymentGateway/Stripe/DebitCardModal/DebitCardModal";

function BuyDrawer({ onClose, open, path, handleSellConfirm, primaryPainting, ownParts, baseURL, }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { token } = userData();
  const [paymentType, setPaymentType] = useState(""); //"card"
  const [perParts, setPerParts] = useState();
  const [perCm2, setPerCm2] = useState();
  const [perPartsItems, setPerPartsItems] = useState(0);
  const [perCm2Items, setPerCm2Items] = useState(0);
  const [fraction, setFraction] = useState("perparts");
  const [keys, setKeys] = useState("");
  const curr = primaryPainting?.price_per_part?.curr === "USD" ? "$" : "€";

  useEffect(() => {
    setPerParts(primaryPainting?.price_per_part?.value);
    setPerCm2(primaryPainting?.price_per_part?.value);
  }, [primaryPainting]);

  const handleFractionChange = (event) => {
    setFraction(event.target.value);
  };

  const [loadingOtp, setLoadingOTP] = useState(false);
  const progressPercentage = Math.round(
    (primaryPainting?.sold_out_part / primaryPainting?.total_part) * 100
  );

  // Per Parts Cal Functions
  const incrementPerPartsCart = () => {
    setPerPartsItems((prevItems) => prevItems + 1);
  };

  const decrementPerPartsCart = () => {
    if (perPartsItems > 0) {
      setPerPartsItems((prevItems) => prevItems - 1);
    }
  };

  const calculatePerPartsTotal = () => {
    return curr + " " + (perParts * perPartsItems).toFixed(2);
  }; //

  // Per CM2 Cal Functions
  const incrementPerCm2Cart = () => {
    setPerCm2Items((prevItems) => prevItems + 1);
  };

  const decrementPerCm2Cart = () => {
    if (perCm2Items > 0) {
      setPerCm2Items((prevItems) => prevItems - 1);
    }
  };

  const calculatePerCm2Total = () => {
    return curr + " " + (perCm2 * perCm2Items * 9).toFixed(2);
  }; //

  // Handle Modals
  const [otpModal, setOtpModal] = useState(false);
  const [smartAccountModal, setSmartAccountModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpValidation, setOtpValidation] = useState("");
  const [profileData, setProfileData] = useState();
  const [paypalModal, setPaypalModal] = useState(false);
  const [debitCardModal, setDebitCardModal] = useState(false);
  const [refId, setRefID] = useState("");

  // For Smart Account Purchase
  const handleConfirmClick = async () => {
    const parts = fraction === "perparts" ? perPartsItems : perCm2Items * 9;
    const calculatedAmount = parseFloat(parts.toFixed(2));
    if (handleOtpConfirm(otp, setOtpValidation)) {
      setLoadingOTP(true);
      const result = await userPhoneVerify(otp, profileData);
      const { data, status, error } = result || {};
      if (status === 200) {
        setLoadingOTP(false);
        setOtpModal(false);
        setOtp("");
        notification.success({ message: data?.message?.en });
        const purchaseResult = await purchasePaintings( paymentType, calculatedAmount, primaryPainting?._id );
        const { data: purchaseData, status: purchaseStatus, error: purchaseError, } = purchaseResult || {};
        if (purchaseStatus === 200) {
          setTimeout(() => {
            setSmartAccountModal(true);
            setTimeout(() => {
              navigate("/");
            }, 4000);
          }, 2000);
        } else {
          notification.error({
            message: purchaseError?.en ? purchaseError?.en : purchaseError,
          });
        }
      } else {
        setLoadingOTP(false);
        setOtpModal(false);
        notification.error({ message: error?.en });
      }
    }
  };

  // Handle Otp Resend
  const handlePhoneOTPResend = async () => {
    const result = await userPhoneResend(otp, profileData);
    const { data, status, error } = result || {};
    if (status === 200) {
      notification.success({ message: data?.message?.en });
    } else {
      notification.error({ message: error?.en });
    }
  };

  // Handle Sell Painting Parts
  const handleSell = () => {
    let perParts = fraction === "perparts" ? perPartsItems : perCm2Items * 9;
    handleSellConfirm(perParts, primaryPainting?._id);
  };

  // Handle Payment Type i.e Card, Paypal and  Smart Account
  const handlePaymentType = async () => {
    try {
      setOtpModal(false);
      setOtpValidation("");
      let perParts = fraction === "perparts" ? perPartsItems : perCm2Items * 9;
      const validateResult = await validatePaintings( paymentType, perParts, primaryPainting?._id );
      const { data: validateData, status: validateStatus, error: validateError, } = validateResult || {};
      if (validateStatus === 200) {
        if (paymentType === "card") {
          setDebitCardModal(true);
          setRefID(validateData?.reference_id);
          setKeys(validateData);
        } else if (paymentType === "paypal") {
          setPaypalModal(true);
          setRefID(validateData?.reference_id);
        } else {
          notification.success({ message: validateData?.message?.en });
          setTimeout(() => {
            setOtpModal(true);
            onClose();
          }, [2000]);
        }
      } else {
        notification.error({ message: validateError?.en ? validateError?.en : validateError, });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Get Profile Data
  const getProfileData = async () => {
    const result = await getProfile();
    const { data, status, error } = result || {};
    if (status === 200) {
      setProfileData(data);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  useEffect(() => {
    if (token) {
      getProfileData();
    }
  }, []);

  //  Per Parts Items Converted To cm2 and in Parts
  const perPartsConverted = partsItemsConvert(perPartsItems, "parts");
  //  Per cm2 Items Converted To cm2
  const perCm2ItemsConverted = partsItemsConvert(perCm2Items * 9, "cm");

  // Radio Button Styling
  const radioStyles = {
    color: "#C1BDBD",
    "&.Mui-checked": { color: "#333333" },
  };

  return (
    <div className="buy-drawer">
      <Drawer
        title=""
        width={1027}
        onClose={onClose}
        open={open}
        closable={false}
        className="side-drawer-s"
      >
        <div className="head-tbox">
          <h2>{path == "parts" ? "Sell Parts" : `Purchase of ${primaryPainting?.market==2 ? "PM" : "SM" } shares`}</h2>
          <img
            onClick={onClose}
            src="/assets/images/close-icon.png"
            alt="image"
            className="close"
          />
        </div>

        <div className="info-wrap">
          <div className="image-box">
            <img src={baseURL + primaryPainting?.cover_img} alt="Artwork" />
          </div>
          <div className="info-box">
            <h3>
              {i18n?.language === "en"
                ? primaryPainting?.title?.en
                : primaryPainting?.title?.fr}{" "}
              <span>({`${primaryPainting?.year}`})</span>
            </h3>
            <h4>Artist: {primaryPainting?.artist?.name}</h4>

            {path == "parts" ? (
              <div className="you-own-parts">
                <div>
                  <img src="/assets/images/own-parts.png" alt="parts" />
                </div>
                <div className="d-flex">
                  <h5>You own </h5>
                  <h4>{ownParts?.quantity ? ownParts?.quantity : 0}</h4>
                  <h5> parts</h5>
                </div>
              </div>
            ) : (
              <div className="you-own-parts mt-4 mb-4" />
            )}

            <div className="price-box">
              <h5> {path == "parts" ? "Sell Price/Part" : path == "market" ? "Part Price" : "Share Price"}{" "} </h5>
              <div className="d-flex align-items-center">
                <h6>
                  {primaryPainting?.price_per_part?.value}
                  {primaryPainting?.price_per_part?.curr === "USD" ? "$" : "€"}
                </h6>
                <h4>{path == "parts" ? "/Part" : null}</h4>
              </div>
            </div>

            <div className="progress-box">
              <h4>
                {" "}
                {primaryPainting?.sold_out_part} /{primaryPainting?.total_part}{" "}
                Parts ({progressPercentage}%)
              </h4>
              <LinearProgress
                variant="determinate"
                value={progressPercentage}
                sx={{
                  height: "17px",
                  width: "100%",
                  color: "#57B251",
                  backgroundColor: "#CCCCCC",
                }}
              />
            </div>
          </div>
        </div>

        <div className="calculation-box">
          <h2>Requested Fractions</h2>
          <FormControl component="fieldset" className="cal-form-s">
            <RadioGroup
              name="fraction"
              value={fraction}
              onChange={handleFractionChange}
            >
              <div
                className={`d-flex align-items-center calc-box ${
                  fraction === "perparts" ? "selected" : null
                }`}
              >
                <div className="d-flex align-items-center">
                  <FormControlLabel
                    value="perparts"
                    control={<Radio sx={radioStyles} />}
                    label="Per Parts:"
                    className="radio-s"
                  />
                </div>

                <div className="d-flex align-items-center">
                  <div div className="price-box">
                    {perParts}
                    <span>{curr}</span>
                  </div>
                  <div className="increment-box">
                    {/* onChange={(e)=>setPerPartsItems(e.target.value)}  */}
                    <button onClick={decrementPerPartsCart}>-</button>
                    <input value={perPartsItems} readOnly disabled />
                    <button onClick={incrementPerPartsCart}>+</button>
                  </div>

                  <div className="equal-box">
                    <span>=</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="total-box">
                    <input value={calculatePerPartsTotal()} readOnly disabled />
                  </div>

                  <div className="perParts-image">
                    <div className="d-flex perParts-image-container">
                      <div className="perParts-image-cm">
                        1&nbsp;{perPartsItems >= 9 ? "cm" : "mm"}
                        <sup>2</sup>
                      </div>

                      <div className="d-flex justify-content-end flex-column">
                        <div className="perParts-image-cm-absolute">
                          1&nbsp;{perPartsItems >= 9 ? "cm" : "mm"}
                          <sup>2</sup>
                        </div>

                        <div className="perParts-image-box">
                          {renderPerPartsBoxes(perPartsItems)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="parts-total">
                    <span>
                      {/* {perPartsItems} Parts*/}
                      {perPartsConverted?.displayText}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`d-flex align-items-center calc-box ${
                  fraction === "percm2" ? "selected" : null
                }`}
              >
                <div className="d-flex align-items-center">
                  <FormControlLabel
                    value="percm2"
                    control={<Radio sx={radioStyles} />}
                    label={
                      <span>
                        Per cm<sup>2</sup>:
                      </span>
                    }
                    className="radio-s"
                  />
                </div>

                <div className="d-flex align-items-center">
                  <div className="price-box">
                    {perCm2}
                    <span>{curr}</span>
                  </div>
                  <div className="increment-box">
                    {/* onChange={(e)=>setPerCm2Items(e.target.value)} */}
                    <button onClick={decrementPerCm2Cart}>-</button>
                    <input value={perCm2Items} readOnly disabled />
                    <button onClick={incrementPerCm2Cart}>+</button>
                  </div>

                  <div className="equal-box">
                    <span>=</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="total-box">
                    <input value={calculatePerCm2Total()} readOnly disabled />
                  </div>

                  <div className="perParts-image">
                    {perCm2Items != 0 ? (
                      <img
                        src="/assets/images/per-parts-image2.png"
                        alt="image"
                      />
                    ) : (
                      <img
                        src="/assets/images/per-parts-image.png"
                        alt="image"
                      />
                    )}
                  </div>

                  <div className="parts-total">
                    <span>
                      {/* {perCm2Items * 9} Parts */}
                      {perCm2ItemsConverted?.displayText}
                    </span>
                  </div>
                </div>
              </div>
            </RadioGroup>
          </FormControl>
          <div className="dividends_message">
            {/* {path == "parts" || path == "market" ?   */}
            <p>
              * Each square centimeter acquired entitles you to annual dividends
            </p>
            {/* :null
            } */}
          </div>
        </div>

        {path !== "parts" ? (
          <div className="payment">
            <h2>Method of Payment</h2>
            <div className="payment-box">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="card"
                name="radio-buttons-group"
                className="radio-group-s"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
              >
                <FormControlLabel
                  control={<Radio sx={radioStyles} />}
                  label="Debit/Credit Card"
                  value="card"
                />
                <FormControlLabel
                  control={<Radio sx={radioStyles} value="paypal" />}
                  label="Paypal"
                />
                <FormControlLabel
                  control={<Radio sx={radioStyles} value="smart_amount" />}
                  label="Smart Account"
                />
              </RadioGroup>

              <div className="btn-box">
                <Button onClick={() => handlePaymentType()}>Payment</Button>
                <Button onClick={onClose} className="close">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        ) : (
          // Handle Sell Parts on Members Dashboard
          <div className="payment myparts-payment">
            <div className="payment-box">
              <div className="btn-box">
                <Button
                  disabled={primaryPainting?.market === 3 ? false : true}
                  onClick={() => handleSell()}
                >
                  Sell
                </Button>
              </div>
            </div>
          </div>
        )}
      </Drawer>

      {token ? (
        <>
          {/* Drawer -> SmartAccount -> Modals */}
          <OtpModal
            visible={otpModal}
            onClose={setOtpModal}
            handleConfirmRequest={handleConfirmClick}
            handlePhoneOTPResend={handlePhoneOTPResend}
            otp={otp}
            setOtp={setOtp}
            otpValidation={otpValidation}
            profileData={profileData}
            loadingOtp={loadingOtp}
          />

          {/* Drawer -> Paypal -> Modals */}
          <PaypalModal
            visible={paypalModal}
            onClose={setPaypalModal}
            setPaymentType={setPaymentType}
            perPartsItems={
              fraction === "perparts" ? perPartsItems : perCm2Items * 9
            }
            perParts={perParts}
            paintingID={primaryPainting?._id}
            refId={refId}
            onCloseDrawer={onClose}
          />

          {/* Drawer -> DebitCard -> Modals */}
          <DebitCardModal
            visible={debitCardModal}
            onClose={setDebitCardModal}
            setPaymentType={setPaymentType}
            perPartsItems={
              fraction === "perparts" ? perPartsItems : perCm2Items * 9
            }
            perParts={perParts}
            paintingID={primaryPainting?._id}
            refId={refId}
            onCloseDrawer={onClose}
            keys={keys}
            curr={curr}
          />

          {/* Drawer -> SmartAccount / Paypal / DebitCard -> Succesfully Purchased Modals */}
          <SuccessModal
            visible={smartAccountModal}
            onClose={setSmartAccountModal}
          />
        </>
      ) : null}
    </div>
  );
}

export default BuyDrawer;
