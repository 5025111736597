import React, { useEffect, useState } from "react";
import { scrollTop } from "../../Services/Utils/Utils";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import MainBanner from "../../Components/MainBanner/MainBanner";
import StrategicInvestment from "../../Components/StrategicInvestment/StrategicInvestment";
import Process from "../../Components/Process/Process";
import HomeExplore from "../../Components/HomeExplore/HomeExplore";
import HomePrimary from "../../Components/HomePrimary/HomePrimary";
import { artStrategic, getContent, getMarketPaintings, } from "../../Services/Api/Api";
import Newsletter from "../../Components/Newsletter/Newsletter";
import FinancialJourney from "../../Components/FinancialJourney/FinancialJourney";
import HomeSecondary from "../../Components/HomeSecondary/HomeSecondary";
import HomeWorks from "../../Components/HomeWorks/HomeWorks";
import HomeInvest from "../../Components/HomeInvest/HomeInvest";
import HomeMarket from "../../Components/HomeMarket/HomeMarket";
import HomeFaq from "../../Components/HomeFaq/HomeFaq";
import HomeBlogs from "../../Components/HomeBlogs/HomeBlogs";
// import HomeTestimonials from "../../Components/HomeTestimonials/HomeTestimonials";

function Home() {
  const currency = localStorage.getItem("currency");
  const [testimonial, setTestimonials] = useState([]);
  const [home, setHomeheading] = useState({});
  const [primaryPainting, setPrimaryPainting] = useState({});
  const [secondaryPainting, setSecPainting] = useState([]);
  const [baseURL, setBaseURL] = useState(null);
  const [curr, setCurr] = useState(currency);
  const [isLoading, setLoading] = useState(false);
  const [boxValues, setBoxValues] = useState({
    boxone: 0,
    boxtwo: 0,
    boxthree: [{ value: 0 }, { value: 0 }],
    boxfour: [{ value: 0 }, { value: 0 }],
    boxfive: 0,
    boxsix: 0,
    boxseven: 0,
    boxeight: 0,
    commissions: 0,
    countries: 0,
    individuals: 0,
    registrations: 0,
    transactions: 0,
    value: [{ value: 0 }, { value: 0 }],
  });

  const getArtStrategicContent = async () => {
    const result = await artStrategic();
    if (result) {
      const { home_boxes, home_section, base_url } = result?.data || {};
      const { box1, box2, box3, box4, box5, box6, box7, box8 } = home_boxes;
      const { commissions, countries, individuals, registrations, transactions, value, } = home_boxes;
      setBaseURL(base_url);
      setHomeheading(home_section);
      setBoxValues({
        boxone: box1,
        boxtwo: box2,
        boxthree: box3,
        boxfour: box4,
        boxfive: box5,
        boxsix: box6,
        boxseven: box7,
        boxeight: box8,
        commissions: commissions,
        countries: countries,
        individuals: individuals,
        registrations: registrations,
        transactions: transactions,
        value: [{ value: value[0]?.value }, { value: value[1]?.value }],
      });
    } else {
      console.log("Internal server error");
    }
  };

  useEffect(() => {
    scrollTop();
    getDataPrimary();
    getDataSecondary();
    getArtStrategicContent();
    getTestimonialsContent();
  }, []);

  useEffect(() => {
    if (curr && primaryPainting?._id) {
      getDataPrimary();
    }
    if (curr && secondaryPainting?.length > 0) {
      getDataSecondary();
    }
  }, [curr]);

  // Get TestimonialsData
  const getTestimonialsContent = async () => {
    const result = await getContent("testimonials");
    const { data, status, error } = result || {};
    if (status === 200) {
      setTestimonials(data?.content);
    } else {
      console.log("Internal server error");
    }
  };

  // Get Primary-Painting Data
  const getDataPrimary = async () => {
    setLoading(true);
    const result = await getMarketPaintings(1, 0, "primary", curr);
    const { data, status, error } = result || {};
    if (status === 200) {
      setPrimaryPainting(data?.paintings[0]);
      setLoading(false);
    } else {
      setLoading(false);
      console.log("Internal server error");
    }
  };

  // Get Secondary-Painting Data
  const getDataSecondary = async () => {
    const result = await getMarketPaintings(50, 0, "secondary", curr);
    const { data, status, error } = result || {};
    if (status === 200) {
      setSecPainting(data?.paintings);
    } else {
      console.log("Internal server error");
    }
  };

  return (
    <div>
      <Header setCurr={setCurr} />
      <MainBanner />
      <Process 
        boxValues={boxValues} 
        currency={currency} 
      />
      <HomeExplore 
        home={home} 
        baseURL={baseURL} 
      />
      <Newsletter type="general"/>
      <HomePrimary
        primaryPainting={primaryPainting}
        baseURL={baseURL}
        testimonial={testimonial}
        isLoading={isLoading}
      />
      <FinancialJourney />
      <HomeSecondary
        secondaryPainting={secondaryPainting}
        baseURL={baseURL}
        currency={currency}
      />
      <HomeWorks />
      <HomeInvest />
      <StrategicInvestment 
        boxValues={boxValues} 
        currency={currency} 
      />
      <HomeMarket />
      <HomeBlogs />
      <HomeFaq />
      {/* <HomeTestimonials 
        testimonial={testimonial} 
      /> */}
      <Footer />
    </div>
  );
}

export default Home;
