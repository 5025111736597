import React, { useState, useEffect } from "react";
import "./Teams.css";
import { scrollTop } from "../../Services/Utils/Utils"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Collapse } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

const teamData = [
  {
    name: "Jonathan Trott",
    role: "Director of the art magazine",
    image: "/assets/images/Team1.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Steve Thomson",
    role: "Director of the art magazine",
    image: "/assets/images/Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Symantha Jeff",
    role: "Director of the art magazine",
    image: "/assets/images/Team3.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Steve Thomson",
    role: "Director of the art magazine",
    image: "/assets/images/Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Jonathan Trott",
    role: "Director of the art magazine",
    image: "/assets/images/Team1.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Steve Thomson",
    role: "Director of the art magazine",
    image: "/assets/images/Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Symantha Jeff",
    role: "Director of the art magazine",
    image: "/assets/images/Team3.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Steve Thomson",
    role: "Director of the art magazine",
    image: "/assets/images/Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Jonathan Trott",
    role: "Director of the art magazine",
    image: "/assets/images/Team1.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Steve Thomson",
    role: "Director of the art magazine",
    image: "/assets/images/Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Galerie Perrotin",
    role: "Director of the art magazine",
    image: "/assets/images/Team4.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Steve Thomson",
    role: "Director of the art magazine",
    image: "/assets/images/Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Galerie Du Marais",
    role: "Director of the art magazine",
    image: "/assets/images/Team5.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Galerie Jocelyn",
    role: "Director of the art magazine",
    image: "/assets/images/Team6.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "1831 Art Gallery",
    role: "Director of the art magazine",
    image: "/assets/images/Team7.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Galerie Jocelyn",
    role: "Director of the art magazine",
    image: "/assets/images/Team8.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
];

function Teams() {
  const [isOpen, setIsOpen] = useState(Array(teamData.length).fill(false));

  useEffect(() => {
    scrollTop();
  }, []);

  const toggleCollapse = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  return (
    <div>
      <Header />

      <div className="teams">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="head-box">
                <div>
                  <h2>Team / Collaborators</h2>
                </div>
                <div className="search">
                  <Input
                    placeholder="Find a table"
                    suffix={
                      <SearchOutlined
                        style={{
                          fontSize: "30px",
                          color: "#333333",
                          width: 26,
                          height: 26,
                        }}
                      />
                    }
                  />
                </div>
              </div>

              <div className="teams-wrapper">
                {teamData.map((member, index) => (
                  <div className="team-box" key={index}>
                    <img src={member.image} alt="" />
                    <div className="box">
                      <div className="info-box">
                        <h3>{member.name}</h3>
                        <p>{member.role}</p>
                      </div>
                      {/* Toggle "more" and "less" images based on collapse state */}
                      <img
                        onClick={() => toggleCollapse(index)}
                        src={isOpen[index] ? "/assets/images/less-icon.png" : "/assets/images/more-icon.png"}
                        alt=""
                      />
                    </div>
                    <div className="more-box">
                      <Collapse in={isOpen[index]}>
                        <p>{member.moreInfo}</p>
                      </Collapse>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Teams;
