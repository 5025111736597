import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Navigate, Route,} from "react-router-dom";
import { userData, DefaultLangCurr } from "../Services/Utils/Utils"
// Public Routes - For Every Visitor
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import VerifyEmail from "../Pages/Auth/VerifyEmail/VerifyEmail";
import Home from "../Pages/Home/Home";
import Artists from "../Pages/Artists/Artists";
import SecondaryMarket from "../Pages/SecondaryMarket/SecondaryMarket";
import SecondaryMarketDetails from "../Pages/SecondaryMarket/SecondaryMarketDetails/SecondaryMarketDetails";
import Details from "../Pages/Details/Details";
import Press from "../Pages/Press/Press";
import Faqs from "../Pages/Faqs/Faqs";
import Partners from "../Pages/Partners/Partners";
import About from "../Pages/About/About";
import PrimaryMarket from "../Pages/PrimaryMarket/PrimaryMarket";
import Presentation from "../Pages/Presentation/Presentation";
import Teams from "../Pages/Teams/Teams";
import Terms from "../Pages/Terms/Terms";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword";
import ChangePassword from "../Pages/Auth/ChangePassword/ChangePassword";
import Gallery from "../Pages/Gallery/Gallery";
// import Works from "../Pages/Works/Works";
// import Location from "../Pages/Location/Location";
// import PressDetails from "../Pages/PressDetails/PressDetails";

// Routes
import { ProtectedRoutes } from "./ProtectedRoutes";
import { PublicRoutes } from "./PublicRoutes";

// Set Default Lang and Curr
DefaultLangCurr()

// Authentication (If Login or Not)
const Auth = () => {
  const { token } = userData();
  if (token) {
    return true;
  } else {
    return false;
  }
};

function AppRoutes() {
  return (
    <Router>
      <Routes>
        {/*  Private Routes  */}
        <Route element={<PublicRoutes Auth={Auth} />}>
          <Route path="/*" element={<AuthRoutes />} />
        </Route>

        {/*  Protected Routes */}
        <Route element={<ProtectedRoutes Auth={Auth} />}>
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/user/*" element={<AdminRoutes />} />
        </Route>

        {/* Public Routes - For Every Visitor */}
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Navigate replace to="/" />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/primary-market" element={<PrimaryMarket />} />
        <Route path="/secondary-market" element={<SecondaryMarket />} />
        <Route path="/artists/details/:artist_id" element={<Details />} />
        <Route path="/secondary-market/details/:painting_id" element={<SecondaryMarketDetails />} />
        <Route path="/press" element={<Press />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/collaborators" element={<Teams />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/works" element={<Works />} />
        <Route path="/works/details/:artist_id/:painting_id" element={<Details />} />
        <Route path="/location" element={<Location />} />
        <Route path="/press-details" element={<PressDetails />} /> */}
      </Routes>
    </Router>
  );
}

export default AppRoutes;
