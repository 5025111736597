import React from "react";
import { Modal } from "antd";
import "./SuccessModal.css";

function SuccessModal({ visible, onClose }) {
  return (
    <Modal
      className="success-modal"
      open={visible}
      onCancel={() => onClose()}
      centered={true}
      closable={true}
      width={620}
      destroyOnClose={true}
      footer={[]}
    >
      <div className="success">
        <div className="container mt-4">
          <div className="row">
            <div className="head-tbox">
              <h2>Purchase of parts</h2>
            </div>
            <div className="success-box">
              <img src="/assets/images/success-image.png" alt="image" />
              <h3>Payment successful</h3>
              <p>Your payment has been successfully completed.</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SuccessModal;
