import React from "react";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { Button, CircularProgress } from "@mui/material";
import "./SmartAccOtpModal.css";

function SmartAccOtpModal({
  visible,
  onClose,
  handleConfirmClick,
  otp,
  setOtp,
  otpValidation,
  profileData,
  loadingOtp,
  handlePhoneOTPResend
}) {
  return (
    <Modal
      className="smart-verification"
      title=""
      open={visible}
      onCancel={() => onClose(false)}
      centered={true}
      closable={true}
      width={620}
      destroyOnClose={true}
      footer={[]}
    >
      <div>
        <div className="head-tbox">
          <h2>Smart Account Withdrawal Request</h2>
        </div>
        
        <div className="first">
        <p>A verification code has been sent to the phone number ******** {(profileData?.phone?.number)?.toString()?.slice(-5)}</p>
        </div>

        <div className="otp-input">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
          />
          <p className="error">{otpValidation}</p>
          <h5>
            Code not received yet?{" "}
            <Button onClick={() =>handlePhoneOTPResend()}>Send again</Button>
          </h5>
        </div>

        <div className="btn-box">
          <Button
            onClick={() => handleConfirmClick()}
            color="primary"
            varient="text"
            className="confirm"
            disabled={loadingOtp}
          >
          {loadingOtp ?
            <CircularProgress
              disableShrink
              size={24}
              sx={{ color: "white" }}
            />
            :
            <p>Confirm Request</p>
          }
          </Button>
          <Button
            onClick={() => onClose(false)}
            color="primary"
            varient="text"
            className="cancel"
          >
            <p>cancel </p>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default SmartAccOtpModal;
