import React, { useState, useEffect } from "react";
import { Modal, Input, Form } from "antd";
import { Button } from "@mui/material";
import './ConfirmModal.css'

function ConfirmModal({ visible, onClose, handleSellSuccess }) {
  return (
    <Modal
      className="confirm-modal" 
      open={visible}
      onCancel={()=>onClose()}
      centered={true}
      closable={true}
      width={620}
      footer={[]}
    >
    <div className="confirm">
      <div className="container mt-4">
        <div className="row">

          <div className="head-tbox">
            <h2>Are you sure you want to sell the parts?</h2>
          </div>

          <div className="btn-box">
          <Button onClick={()=>handleSellSuccess()} color="primary" varient="text" className="confirm-btn">
            <p>Yes</p>
          </Button>

          <Button onClick={()=>onClose(false)} color="primary" varient="text" className="cancel-btn">
            <p>No </p>
          </Button>
        </div>
        </div>
      </div>
    </div>
  </Modal>
  )
}

export default ConfirmModal