import React, { useEffect } from "react";
import MarketSlider from "../../../../Components/MarketSlider/MarketSlider";
import "./PrimaryMarket.css";
import { useLocation, useNavigate } from "react-router-dom";

function PrimaryMarket() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state?.record
  const baseURL = location?.state?.baseURL

  useEffect(() => {
    if (!state) {
      navigate("/user/market");
    }
  }, []);

  return (
    <div className="primary-market">
      <h1 className="heading">{state?.market==2 ? "Primary" : "Secondary"} Market</h1>
      <MarketSlider 
        primaryPainting={state} 
        baseURL={baseURL}
        path="market"
      />
    </div>
  );
}

export default PrimaryMarket;
