import React, { useState, useEffect } from "react";
import { Modal, Input, Form } from "antd";
// import './SellSuccessModal.css'

function SuccessModal({ visible, onClose }) {
  return (
    <Modal
    className="success-modal" 
    title=""
    open={visible}
    onCancel={()=>onClose()}
    centered={true}
    closable={true}
    width={620}
    footer={[]}
  >
    <div className="success">
      <div className="container mt-4">
        <div className="row">
          <div className="head-tbox">
            <h2>Parts Listed</h2>
          </div>
          <div className="success-box">
              <img src="/assets/images/success-image.png" alt="" />
              <p className="mt-4">Your parts have been listed on the<br/> secondary market for sale.</p>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  )
}

export default SuccessModal