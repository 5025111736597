import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../Pages/Auth/Login/Login";
import Register from "../Pages/Auth/Register/Register";
import Forgot from "../Pages/Auth/Forgot/Forgot";
import EmailConfirm from "../Pages/Auth/EmailConfirm/EmailConfirm";
// import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword";
// import ChangePassword from "../Pages/Auth/ChangePassword/ChangePassword";

function AuthRoutes() {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/email-confirm" element={<EmailConfirm />} />
      {/* <Route path="/reset-password/:token" element={<ResetPassword />} /> */}
      {/* <Route path="/change-password" element={<ChangePassword />} /> */}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default AuthRoutes;
