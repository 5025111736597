import React, { useState, useEffect } from "react";
import { Tabs, notification } from "antd";
import { useLocation } from "react-router-dom";
import "./History.css";
import {
  getAdminHistory,
  getAdminHistoryDownload,
} from "../../../Services/Api/Api";
import Purchase from "./Purchase/Purchase";
import Sale from "./Sale/Sale";
import Dividend from "./Dividend/Dividend";
import Artwork from "./Artwork/Artwork";
import { useTranslation } from "react-i18next";

function History() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { key } = location?.state || {};
  const [activeKey, setActiveKey] = useState(key || "PURCHASE");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [isLoading, setLoading] = useState(true);
  const [baseURL, setBaseURL] = useState("");
  const [artWorkOffset, setArtworkOffset] = useState(0);
  const [artWorkLength, setArtworkLength] = useState(0);

  useEffect(() => {
    if (activeKey !== "ARTWORK") {
      getData(activeKey);
    }
  }, [activeKey, page, limit, artWorkOffset]);

  useEffect(() => {
    if (activeKey === "ARTWORK") {
      getData(activeKey);
    }
  }, [activeKey, artWorkOffset]);

  // Switching Tabs
  const renderSwitch = (data) => {
    switch (activeKey) {
      case "SALE":
        return data?.saleHistory;
      case "DIVIDEND":
        return data?.dividendHistory;
      case "ARTWORK":
        return data?.artworkHistory;
      default:
        return data?.purchaseHistory;
    }
  };

  // Get Data for Purchase, Sale, Dividend and Artwork
  const getData = async (statusType) => {
    // debugger\
    // setLoading(true);
    let offset = 0;
    if (activeKey === "ARTWORK") {
      offset = artWorkOffset;
    } else {
      offset = (page - 1) * limit;
    }
    const result = await getAdminHistory(limit, offset, statusType);
    const { data, error, status } = result || {};
    if (status === 200) {
      if (activeKey === "ARTWORK") {
        setTableData((prev) => [...prev, ...renderSwitch(data)]);
        setArtworkLength(renderSwitch(data)?.length);
      } else {
        setTableData(renderSwitch(data));
      }
      setBaseURL(data?.base_url);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Get Data for Purchase, Sale, Dividend and Artwork
  const handleDownloadData = async () => {
    const result = await getAdminHistoryDownload(activeKey);
    const { data, error, status } = result || {};
    if (status === 200) {
      window.open(data?.base_url + data?.download_url);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  // On Tabs Click
  const onTabClick = (key) => {
    setLoading(true);
    setActiveKey(key);
    setPage(1);
  };

  // Passing States and Data in TabItems
  const data = {
    tableData,
    isLoading,
    page,
    setPage,
    baseURL,
    i18n,
    t,
    handleDownloadData,
    limit,
    artWorkLength,
    setArtworkOffset,
    setTableData,
    activeKey,
  };

  const tabItems = [
    {
      key: "PURCHASE",
      tab: "Purchase",
      label: t("Purchase"),
      children: <Purchase data={data} />,
    },
    {
      key: "SALE",
      tab: "Sale",
      label: t("Sale"),
      children: <Sale data={data} />,
    },
    {
      key: "DIVIDEND",
      tab: "Dividend",
      label: t("Dividend"),
      children: <Dividend data={data} />,
    },
    {
      key: "ARTWORK",
      tab: "Artwork",
      label: t("Artwork"),
      children: <Artwork data={data} />,
    },
  ];

  return (
    <div className={ i18n.language != "fr" ? "history" : "history history-fr" }>
      <h1 className="heading">{t("History")}</h1>
      <Tabs
        activeKey={activeKey}
        onTabClick={(key) => onTabClick(key)}
        tabBarGutter={16}
        items={tabItems}
      />
    </div>
  );
}

export default History;
