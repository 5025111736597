import React from "react";
import "./MainBanner.css";
import { useTranslation } from "react-i18next";

function MainBanner() {
  const { t, i18n } = useTranslation();

  return (
    <div className="mainBanner">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="banner-background">
              <img src="/assets/images/Main-Banner.png" alt="Banner Image" />
              <div className="content-box">
                <h1>{t("BannerHeading")}</h1>
                <p> {t("BannerParagraph")} </p>
                <span>WE<b>ART</b> {t("BannerPoweredBy")} <b>WEEX</b></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;
