import React, { useEffect, useState } from "react";
import "./SecondaryMarketDetails.css";
import { notification, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { scrollTop } from "../../../Services/Utils/Utils";
import { getPaintingDetails } from "../../../Services/Api/Api";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import MarketSlider from "../../../Components/MarketSlider/MarketSlider";

function SecondaryMarketDetails() {
  const navigate = useNavigate();
  let { painting_id } = useParams();
  const currency = localStorage.getItem("currency");
  const [curr, setCurr] = useState(currency);
  const [secPainting, setSecPaintDet] = useState();
  const [baseURL, setBaseURL] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!painting_id) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    scrollTop();
    if (painting_id) getPaintingData(painting_id);
  }, []);

  useEffect(() => {
    if (curr && secPainting && painting_id) {
      setSecPaintDet();
      getPaintingData(painting_id, curr);
    }
  }, [curr]);

  const getPaintingData = async () => {
    setLoading(true);
    const result = await getPaintingDetails(painting_id, curr);
    const { data, status, error } = result;
    if (status == 200) {
      setSecPaintDet(data?.paintings);
      setBaseURL(data.base_url);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  return (
    <div className="secondary-market-details">
      <Header setCurr={setCurr} />
      <div className="container secondary-market-details-container">
        <div className="row">
          <div className="col-md-12">
            {isLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <MarketSlider 
                primaryPainting={secPainting} 
                baseURL={baseURL} 
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SecondaryMarketDetails;
