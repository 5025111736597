import React from "react";
import "./HomeMarket.css";
import { useTranslation } from "react-i18next";
// import { Language } from "@mui/icons-material";

function HomeMarket() {
  const { t, i18n } = useTranslation();

  return (
    <div className="home-market">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomeMarketHeading")}</h2>
              <p>{t("HomeMarketPara")}</p>
            </div>
            {i18n.language != "fr" ? (
              <img src="/assets/images/home-market-image.png" alt="" />
            ) : (
              <img src="/assets/images/home-market-image.png" alt="" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeMarket;
