import { useState, useEffect } from "react";
import "./HomeFaq.css";
import Accordion from "react-bootstrap/Accordion";
import { getContent } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

function HomeFaq() {
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);
  const [faqDetails, setFaqDetails] = useState();

  useEffect(() => {
    getData();
  }, []);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  // Get FAQ's Data
  const getData = async () => {
    const result = await getContent("faqs");
    const { data, status, error } = result || {};
    if (status == 200) {
      setFaqDetails(data?.content);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  return (
    <div className="home-faq">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>Faq</h2>
            </div>

            <div className="faq-accordians">
              <Accordion activeKey={activeKey} onSelect={handleSelect}>
                {faqDetails?.map((item, index) => (
                  <Accordion.Item
                    key={index}
                    className={
                      activeKey === index ? " active p-1 mb-3 " : " p-1 mb-3"
                    }
                    eventKey={index}
                  >
                    <Accordion.Header>
                      {i18n?.language === "en"
                        ? item?.title?.en
                        : item?.title?.fr}
                      <span
                        className="float-right success-button"
                        variant={activeKey === index ? "danger" : "success"}
                        onClick={() =>
                          handleSelect(activeKey === index ? null : index)
                        }
                      >
                        {activeKey === index ? "-" : "+"}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            i18n?.language === "en"
                              ? item?.description?.en
                              : item?.description?.fr,
                        }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeFaq;
