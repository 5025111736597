import React from 'react'
import './StrategicInvestment.css'
import { useTranslation } from "react-i18next";

function StrategicInvestment({boxValues,currency}) {
const {boxone, boxtwo, boxthree, boxfour, boxfive, boxsix, boxseven, boxeight} = boxValues
const { t, i18n } = useTranslation();

  return (
    <div className='strategic-investment'>
      <div className='container'>
        <div className='row'>
            <div className='col-md-12'>
              <div className='head-tbox'>
                <h2>{t("StrategyHeading")}</h2>
              </div>
                <div className='box-wrapper'>
                    <div className='investment-box'>
                      <h3><span>+</span>{boxone}%</h3>
                      <p>
                        {t("InvestBox1")} 
                        {t("InvestBox1Sub2") === "" ? null : ` "${t("InvestBox1Sub2")}" `}  
                        {t("InvestBox1Sub3")
                      }</p>
                    </div>

                    <div className='investment-box'>
                      <h3><span>+</span>{boxtwo}%</h3>
                      <p>{t("InvestBox2")}</p>
                    </div>

                    <div className='investment-box'>
                      <h3><span>{currency === "USD" ? "$" : "€"}</span>{currency === "USD" ? boxthree[0]?.value : boxthree[1]?.value}</h3>
                      <p>{t("InvestBox3")}</p>
                    </div>

                    <div className='investment-box'>
                      <h3><span>{currency === "USD" ? "$" : "€"}</span>{currency === "USD" ? boxfour[0]?.value : boxfour[1]?.value}</h3>
                      <p>{t("InvestBox4")}</p>
                    </div>

                    <div className='investment-box investment-imageBox'>
                      <img src='/assets/images/artistic-image.png' alt='image' />
                    </div>

                    <div className='investment-box'>
                      <h3>{boxfive}<span>%</span></h3>
                      <p>{t("InvestBox5")}</p>
                    </div>

                    <div className='investment-box'>
                      <h3>{boxsix}<span>%</span></h3>
                      <p>{t("InvestBox6")}</p>
                    </div>

                    <div className='investment-box'>
                      <h3>{boxseven}<span></span></h3>
                      <p>{t("InvestBox7")}</p>
                    </div>

                    <div className='investment-box'>
                      <h3>{boxeight}<span>%</span></h3>
                      <p>{t("InvestBox8")}</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default StrategicInvestment