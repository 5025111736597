import React, { useState } from "react";
import "./HomePrimary.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { Button } from "@mui/material";

function HomePrimary({ primaryPainting, baseURL, testimonial, isLoading }) {
  const { t, i18n } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(8);

  // Mouse Enter 
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  // Mouse Leave 
  const handleMouseLeave = () => {
    setHoveredIndex(8);
  };

  return (
    <div className="primarymarket">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("HomePrimaryHeading")}</h2>
            </div>
          </div>

          {isLoading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : !primaryPainting ? (
            <div className="head-tbox-no-data">
              <h4>{t("HomePrimaryNoData")}</h4>
            </div>
          ) : (
            <div className="primaryMarket-wrapper">
              <div className="primaryMarket-left">
                <div className="testimonial-head">
                  <h3>{t("HomeTestimonialHeading")}</h3>
                </div>

                {/* Testimonials */}
                <div className="testimonial-wrapper">
                  {testimonial?.map((data, index) => {
                    return (
                      <div className="test-box" key={index}>
                        <img src={`${baseURL + data?.img}`} alt="image" />
                        <div className="ct-box">
                          <h4> {i18n?.language === "en" ? data?.title?.en : data?.title?.fr} </h4>
                          <p> {i18n?.language === "en" ? data?.description?.en : data?.description?.fr} </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

                {/* Primary Image */}
              <div className="image-col-s">
                <div className="primary-image">
                  <img
                    src={`${baseURL + primaryPainting?.cover_img}`}
                    alt="image"
                    className="main-primary"
                  />
                </div>

                <h5 className="painting-info">{i18n?.language === "en" ? primaryPainting?.title?.en : primaryPainting?.title?.fr}, <span>{primaryPainting?.artist?.name}</span> </h5>
              </div>

               {/* Primary Image 9 Boxes */}
              <div className="primaryMarket-right">
                <div
                  className="right-image"
                  style={{ backgroundImage: `url(${ baseURL + primaryPainting?.cover_img })`, }}
                >
                  {[...Array(9)].map((_, index) => (
                    <div
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      className={`item ${ hoveredIndex === index ? "with-after" : "" }`}
                    ></div>
                  ))}
                </div>
                
                {/* Invest Button */}
                <Link to="/primary-market">
                  <Button>{t("HomePrimaryInvestButton")}</Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePrimary;
