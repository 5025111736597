import "./Register.css";
import React, { useState, useEffect } from "react";
import { Form, Input, Checkbox as AntCheckbox, notification, Select } from "antd";
import { Button, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin, FacebookLogin } from "../../../Services/Firebase/FirebaseAuth";
import { scrollTop,  validatePassword, validateCheckbox, validatePhone, onChangePhone, valLangChange, errMess, succMess, userAuthData } from "../../../Services/Utils/Utils";
import { registerUser } from "../../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import Carousal from "../../../Components/Carousal/Carousal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { setRefreshToken, setToken } from "../../../Services/Store/Store";
import { useDispatch } from "react-redux";

const { Option } = Select;

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phoneNum, setPhoneNum] = useState("+33");
  const [countryCode, setCountryCode] = useState("+33");
  const [slug, setSlug] = useState("");
  const [remMe, setRemMe] = useState(false);
  const lang = i18n?.language === "en-US" || i18n?.language === "en" ? "en" : "fr";

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    valLangChange(form)
  }, [i18n.language]);

  const onFinish = async (values) => {
    setLoading(true);
    const number = phoneNum?.substring(countryCode?.length);
    values.phone = { code: countryCode, number: number, slug: slug};
    values.global = { curr: values.curr, lang: lang };
    const result = await registerUser(values);
    const { access_token, refresh_token } = result?.data || {}
    if (result?.status) {
      dispatch(setToken(access_token));
      dispatch(setRefreshToken(refresh_token));
      userAuthData(result, values, i18n, setLoading, navigate, remMe, setLoading);
    } else {
      setLoading(false);
      notification.error({ message: errMess(result) });
    }
  };

  // Initial Values
  const initialValues = {
    phone: phoneNum,
    checkbox: false,
  };

  return (
    <div className={i18n.language != "fr" ? "register" : "register register-fr"}>
      <Header />
      <div className="container register-container">
        <div className="register-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>

          <div className="col-md-6">
            <div className="register-section">
              <h1 className="heading">{t("RegHead")}</h1>
              <p className="description">{t("RegSubHead")}</p>

              <div>
                <Form
                  className="register-form"
                  form={form}
                  onFinish={onFinish}
                  initialValues={initialValues}
                >
                  <div className="res-wrap d-flex justify-content-between">
                    <div className="firstname">
                      <label>{t("RegFirstNameLabel")}</label>
                      <Form.Item
                        name="firstname"
                        rules={[
                          {
                            required: true,
                            message: t("RegValBlank"),
                          },
                          {
                            max: 100,
                            message: t("RegValMinHundred"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("RegFirstNamePH")}
                          size="large"
                        />
                      </Form.Item>
                    </div>

                    <div className="lastname">
                      <label>{t("RegLastNameLabel")}</label>
                      <Form.Item
                        name="lastname"
                        rules={[
                          {
                            required: true,
                            message: t("RegValBlank"),
                          },
                          {
                            max: 100,
                            message: t("RegValMinHundred"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("RegLastNamePH")}
                          size="large"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="res-wrap d-flex justify-content-between">
                    <div className="email">
                      <label>{t("RegEmailLabel")}</label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: t("RegInvalidEmail"),
                          },
                          {
                            required: true,
                            message: t("RegValBlank"),
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder={t("RegEmailPH")}
                          size="large"
                        />
                      </Form.Item>
                    </div>

                    <div className="phone">
                      <label>{t("RegPhoneNumLabel")}</label>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            validator: validatePhone(
                              t,
                              form,
                              phoneNum,
                              countryCode,
                              setPhoneValidation,
                              "reg"
                            ),
                          },
                        ]}
                      >
                        <PhoneInput
                          defaultCountry="fr"
                          country="fr"
                          countryCodeEditable={false}
                          inputProps={{ placeholder: "629550301" }}
                          value={phoneNum}
                          className={phoneValidation ? "error" : "no-error"}
                          onChange={(value, country) => {
                            onChangePhone(
                              form,
                              value,
                              country,
                              setCountryCode,
                              setPhoneNum,
                              setSlug,
                            );
                          }}
                          validateStatus={form.getFieldValue("phone") ? "error" : null }
                          help={form.getFieldValue("phone") ? "error" : null}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="res-wrap d-flex justify-content-between">
                    <div className="password">
                      <label>{t("RegPassLabel")}</label>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t("RegValBlank"),
                          },
                          { min: 8, message: t("RegValMinEight") },
                          {
                            max: 100,
                            message: t("RegValMinHundred"),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={t("RegPassPH")}
                          size="large"
                        />
                      </Form.Item>
                    </div>

                    <div className="confirm-password">
                      <label>{t("RegConfirmPassLabel")}</label>
                      <Form.Item
                        name="confirm_password"
                        rules={[
                          {
                            required: true,
                            message: t("RegValBlank"),
                          },
                          {
                            max: 100,
                            message: t("RegValMinHundred"),
                          },
                          {
                            validator: validatePassword(form, t),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={t("RegConfirmPassPH")}
                          size="large"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  
                  <div className="res-wrap">
                    <div className="currency">
                      <label>{t("RegSelectCurrLabel")}</label>
                      <Form.Item
                        name="curr"
                        rules={[
                          {
                            required: true,
                            message: t("RegValBlank"),
                          },
                        ]}
                      >
                        <Select placeholder={t("RegSelectCurrLabel")}>
                          <Option value="USD">USD</Option>
                          <Option value="EURO">EURO</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="register-checkbox d-flex align-items-center justify-content-between">
                    <Form.Item
                      name="checkbox"
                      valuePropName="checked"
                      rules={[{ validator: validateCheckbox(form, t) }]}
                    >
                      <AntCheckbox>
                        {t("RegCheck1")}{" "}
                        <Link to="/terms">{t("RegCheck2")}</Link> {t("RegCheck3")}{" "}
                        <Link to="/privacy-policy">{t("RegCheck4")}</Link>
                      </AntCheckbox>
                    </Form.Item>
                  </div>

                  <div className="register-button">
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? (
                        <CircularProgress
                          disableShrink
                          size={24}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <>
                          <p>{t("RegButton")}</p>
                          <img src="assets/images/auth/arrow.png" alt="image" />
                        </>
                      )}
                    </Button>
                  </div>

                </Form>

                <div className="continue-with d-flex align-items-center justify-content-center">
                  <span />
                  <p>{t("RegOrConWith")}</p>
                  <span />
                </div>

                <div className="google-facebook d-flex align-items-center justify-content-center">
                  <Button variant="text" color="primary" onClick={() => {GoogleLogin(navigate, i18n)} }>
                    <img src="assets/images/auth/google.png" alt="image" />
                  </Button>
                  {/* <Button variant="text" color="primary" onClick={() => {FacebookLogin(navigate, i18n)} }>
                    <img
                      src="assets/images/auth/facebook.png"
                      alt="image"
                      className="fb-icon"
                    />
                  </Button> */}
                </div>

                <div className="register-button d-flex align-items-center justify-content-between">
                  <p>{t("RegAlreadyAccount")}</p>
                  <Link to="/login">
                    <Button color="primary" varient="text">
                      {t("RegSubmitButton")}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
