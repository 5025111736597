import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop,
} from "recharts";

const data = [
  { year: "", value: 1 },
  { year: "1999", value: 3 },
  { year: "2000", value: 4 },
  { year: "2001", value: 2 },
  { year: "2002", value: 4 },
  { year: "2003", value: 5 },
  { year: "2004", value: 2 },
  { year: "", value: 0.5 },
];

const LineCharts = ({ details, evolution_list, Chartdata }) => {

  let newData;
  switch (details) {
    case "table":
      newData = evolution_list;
      break;
    case "buy":
      newData = Chartdata;
      break;
    case "details":
      newData = evolution_list;
      break;
    case "works":
      newData = evolution_list;
      break;
    case "homeSec":
      newData = evolution_list;
      break;
    default:
      newData = data;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={newData}
        margin={{
          right: details == "details" ? 30 : details == "table"? 20: details == "buy"? 20 : 20,
          left: details == "details" ? 30 : details == "table"? 20: details == "buy"? 20 : 20,
          bottom: details == "table" ? -10 : 0,
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(51, 51, 51, 0.3)" />
            <stop offset="100%" stopColor="rgba(51, 51, 51, 0.01)" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={details?"_id":"year"}
          interval={0}
          axisLine={details == "details" ? true : false}
          tickLine={false}
          tickCount={8}
        />
        <YAxis hide tickCount={8} />
        {details == "buy" ? (
          <CartesianGrid strokeDasharray="2 10" horizontal={false} />
        ) : null}
        {/* <Tooltip content={<div />} /> */}
        <Area
          type="monotone"
          dataKey={details?"totalValue":"value"}
          stroke="#333333" // Use the gradient fill
          strokeWidth={2}
          fill="url(#gradient)" // Use the gradient fill
          connectNulls
          dot={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineCharts;
