import React, { useState, useEffect } from 'react'
import './AdminHeader.css'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from 'antd';
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { userData, changeLanguage, currencyOnChange, setCurrentCurr, getSavedLanguage, logOut, authRoutes } from "../../Services/Utils/Utils"
import { MemberDropDown, SettingsDropDown, LangCurrDropDown } from "../../Components/DropDowns/DropDowns"
import { useSelector } from 'react-redux';

function AdminHeader() {
  const { token, iev } = userData();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;
  const auth = authRoutes(path);
  const { i18n } = useTranslation();
  const currentLanguage = getSavedLanguage();
  const [currentCurrency, setCurrentCurrency] = useState("");

  useEffect(() => {
    setCurrentCurr(setCurrentCurrency)
  }, []);

  useEffect(() => {
    // Ensure token and iev are defined before using them
    if (token && iev === undefined && !iev) {
      navigate("/verify-email");
    }
  }, []);

  return (
    <div className='admin-header'>
      <div className="container-fluid">
        <div className="row">

          <div className="col-md-2 col-sm-1">
            <div className="back-logo">
              <Link to={path == "/user/market/primary-market" ? '/user/market' : path == "/user/edit" ? "/user/profile" : "/"}><img src="/assets/images/back-icon.png" alt="back" /></Link>
            </div>
          </div>

          <div className="col-md-4 col-sm-0"></div>

          <div className="col-md-6 col-sm-11">
            <div className="head-account">
              <ul>
                <li>
                  <MemberDropDown auth={auth} />
                </li>
                <li>
                  <SettingsDropDown auth={auth} />
                </li>
                <li>
                  <LangCurrDropDown 
                    currentLanguage={currentLanguage} 
                    changeLanguage={changeLanguage} 
                    currentCurrency={currentCurrency} 
                    setCurrentCurrency={setCurrentCurrency} 
                  />
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AdminHeader