import React, { useState, useEffect } from "react";
import { getAdminMarket } from "../../../Services/Api/Api";
import { Table, Spin, notification } from "antd";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LineCharts from "../../../Components/Charts/LineCharts";
import CustomPagination from "../../../Components/Pagination/CustomPagination";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./Market.css";

function Market() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tablePrimaryData, setPrimaryTableData] = useState([]);
  const [tableSecondaryData, setSecondaryTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [baseURL, setBaseURL] = useState("");

  useEffect(() => {
    getDataPrimary();
  }, []);

  useEffect(() => {
    getDataSecondary();
  }, [page, limit]);

  // Get Primary Markey Data
  const getDataPrimary = async () => {
    const result = await getAdminMarket(1, 0, "primary");
    const { data, error, status } = result || {};
    if (status === 200) {
      setPrimaryTableData(data?.paintings);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Get Secondary Markey Data
  const getDataSecondary = async () => {
    const offset = (page - 1) * limit;
    const result = await getAdminMarket(limit, offset, "secondary");
    const { data, error, status } = result || {};
    if (status === 200) {
      setSecondaryTableData(data?.paintings);
      setBaseURL(data?.base_url);
      setLoading2(false);
    } else {
      setLoading2(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Pagination
  const handleChangePage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // View Details
  const handleDetails = (record) => {
    navigate("/user/market/primary-market", { state: {record, baseURL} });
  };

  const columns = [
    {
      title: t("MarketWorks"),
      dataIndex: "artist",
      key: "artist",
      align: "center",
      render: (artist, record) => (
        <div className="image-details">
          <div>
            <img src={baseURL + record?.cover_img} alt="image" />
          </div>
          <div>
            <p className="title">
              {i18n?.language === "en" ? record?.title?.en : record?.title?.fr}
            </p>
            <p className="sub-title">
              {artist?.name} -{" "}
              {dayjs(record?.year).format("YYYY")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: t("MarketRatingIndex"),
      dataIndex: "evolution_list",
      key: "evolution_list",
      align: "center",
      render: (evolution_list) => (
        <div className="table-charts">
          <LineCharts details="table" evolution_list={evolution_list} />
        </div>
      ),
    },
    {
      title: t("MarketStartingPrice"),
      dataIndex: "price_per_part",
      key: "price_per_part",
      align: "center",
      width: "200px",
      render: (price_per_part, record) => (
        <div   className="d-flex flex-column align-items-center" style={{ width: 100 }} >
          <div>{price_per_part?.value} {price_per_part?.curr}</div>
          {record?.percentage !=0 ? (
            record?.percentage >= 0 ? 
            <p className="increament">+{record?.percentage} %</p>
            : 
            <p className="decreament">{record?.percentage} %</p>
            ) : null
          }
        </div>
      ),
    },
    {
      title: t("MarketPartsOwned"),
      dataIndex: "part_quantity",
      key: "part_quantity",
      align: "center",
      render: (part_quantity) => (
        <div style={{ width: 100 }}>{part_quantity ? part_quantity : 0}</div>
      ),
    },
    {
      title: t("MarketPartsAvailable"),
      dataIndex: "total_part",
      key: "total_part",
      align: "center",
      render: (total_part, record) => (
        <div style={{ width: 120 }}>{total_part - record?.sold_out_part}</div>
      ),
    },
    {
      title: t("MarketDetailsButton"),
      dataIndex: "painting_price",
      key: "painting_price",
      align: "center",
      render: (painting_price) => <div style={{ width: 150 }}>{painting_price?.value} {painting_price?.curr}</div>,
      
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (_id, record) => (
        <div className="details-button details-button-market">
          <Button onClick={() => handleDetails(record)}>
            {t("MarketDetailsButton")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="market">
      <h1 className="heading">{t("Market")}</h1>

      <div className="primary">
        <h1 className="sub-heading">{t("MarketPrimary")}</h1>
        <div className="table">
          {isLoading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <div>
              <Table
                pagination={false}
                dataSource={tablePrimaryData}
                columns={columns}
                rowKey="_id"
              />
            </div>
          )}
        </div>
      </div>

      <div className="secondary">
        <h1 className="sub-heading">{t("MarketSecondary")}</h1>
        <div className="table">
          {isLoading2 ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <div>
              <Table
                pagination={false}
                dataSource={tableSecondaryData}
                columns={columns}
                rowKey="_id"
              />
              <CustomPagination
                tableLength={tableSecondaryData?.length < 6}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Market;
