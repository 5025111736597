import React from "react";
import { Button } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './CustomPagination.css';

function CustomPagination({tableLength, page, setPage }) {

  // Navigate to the previous page
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Navigate to the next page
  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };


  return (
    <div className="pagination-buttons">
      <Button onClick={handlePreviousPage} disabled={page <= 1}>
        <ChevronLeftIcon />
      </Button>
      <Button onClick={handleNextPage} disabled={tableLength}>
        <ChevronRightIcon />
      </Button>
    </div>
  );
}

export default CustomPagination;
