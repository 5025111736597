import React, { useEffect } from "react";
import "./EmailConfirm.css";
import { Button } from "@mui/material";
import { notification } from "antd";
import { forgotPassword } from "../../../Services/Api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import Carousal from "../../../Components/Carousal/Carousal";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

function EmailConfirm() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location?.state;

  useEffect(() => {
    if (!values) {
      navigate("/login");
      window.history.replaceState(null, null, "/login");
    }
  }, []);

  const handleClick = async () => {
    const result = await forgotPassword(values);
    const { data, error, status } = result;
    if (status === 200) {
      notification.success({ message: data?.message?.en });
    } else {
      notification.error({ message: error?.en });
    }
  };

  return (
    <div className="email-confirm">
      <Header />
      <div className="container">
        <div className="email-confirm-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="email-confirm-section">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="main-image"
                  src="assets/images/auth/envelop.png"
                  alt="image"
                />
                <h1 className="heading">Verify Your Email</h1>
                <p className="description">
                  An account verification link has been sent to the email you
                  provided. Please click on the link to reset your password.
                </p>
              </div>
              <div className="send-again-section d-flex align-items-center justify-content-center">
                <p>Did not receive email?</p>
                <Button onClick={handleClick} disableRipple={true}>
                  Send again{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EmailConfirm;
