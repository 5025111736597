import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Faqs.css";
import { getContent } from "../../Services/Api/Api";
import { scrollTop } from "../../Services/Utils/Utils"
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const Faqs = () => {
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);
  const [faqDetails, setFaqDetails] = useState();

  useEffect(() => {
    scrollTop();
    getData();
  }, []);
  
  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  // Get FAQ's Data
  const getData = async () => {
    const result = await getContent("faqs");
    const { data, status, error } = result  || {};
    if (status == 200) {
      setFaqDetails(data?.content);
    } else {
      notification.error({message : "Internal server error"});  
    }
  };

  return (
    <div className="faqs">
      <Header />
      <div className="container faqs-container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="faq-title">Frequently Asked Questions</h1>
            <p className="faq-description">Here you will find frequently asked questions, you can search directly for the question that interests you.</p>
            <div className="faq-accordians">
              <Accordion activeKey={activeKey} onSelect={handleSelect}>
                {faqDetails?.map((item, index) => (
                  <Accordion.Item
                    key={index}
                    className={ activeKey === index  ? " active p-1 mb-3 " : " p-1 mb-3"   }
                    eventKey={index}
                    >
                    <Accordion.Header>
                      { i18n?.language === "en" ? item?.title?.en : item?.title?.fr}
                      <span
                        className="float-right success-button"
                        variant={activeKey === index ? "danger" : "success"}
                        onClick={() =>
                          handleSelect(activeKey === index ? null : index)
                        }
                      >
                        {activeKey === index ? "-" : "+"}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: i18n?.language === "en"? item?.description?.en : item?.description?.fr }} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faqs;
