import React, { useEffect } from "react";
import "./Partners.css";
import { scrollTop } from "../../Services/Utils/Utils";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function Partners() {
  useEffect(() => {
    scrollTop();
  }, []);

  const mapData = [
    {
      title: "Jam Art Factory",
      backgroundImage: "assets/images/partners/bg1.png",
      imageSrc: "assets/images/partners/partner1.png",
      description: `Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
      Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
      Cicero, written in 45 BC. "Lorem ipsum dolor sit amet..",
      comes from a line in section 1.10.32. The standard chunk of
      Lorem Ipsum used since the 1500s is reproduced below for those
      interested. Sections 1.10.32 and 1.10.33 from "de Finibus
      Bonorum et Malorum" by Cicero are also reproduced`,
    },
    {
      title: "Galerie Perrotin",
      backgroundImage: "assets/images/partners/bg2.png",
      imageSrc: "assets/images/partners/partner2.png",
      description: `Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
      Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
      Cicero, written in 45 BC. "Lorem ipsum dolor sit amet..",
      comes from a line in section 1.10.32. The standard chunk of
      Lorem Ipsum used since the 1500s is reproduced below for those
      interested. Sections 1.10.32 and 1.10.33 from "de Finibus
      Bonorum et Malorum" by Cicero are also reproduced`,
    },
    {
      title: "Galerie Du Marais",
      subtitle: "(Paule France Luciani)",
      backgroundImage: "assets/images/partners/bg3.png",
      imageSrc: "assets/images/partners/partner3.png",
      description: `Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
      Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
      Cicero, written in 45 BC. "Lorem ipsum dolor sit amet..",
      comes from a line in section 1.10.32. The standard chunk of
      Lorem Ipsum used since the 1500s is reproduced below for those
      interested. Sections 1.10.32 and 1.10.33 from "de Finibus
      Bonorum et Malorum" by Cicero are also reproduced`,
    },
    {
      title: "Galerie Jocelyn Wolff",
      backgroundImage: "assets/images/partners/bg4.png",
      imageSrc: "assets/images/partners/partner4.png",
      description: `Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
      Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
      Cicero, written in 45 BC. "Lorem ipsum dolor sit amet..",
      comes from a line in section 1.10.32. The standard chunk of
      Lorem Ipsum used since the 1500s is reproduced below for those
      interested. Sections 1.10.32 and 1.10.33 from "de Finibus
      Bonorum et Malorum" by Cicero are also reproduced`,
    },
    {
      title: "1831 Art Gallery Paris",
      backgroundImage: "assets/images/partners/bg5.png",
      imageSrc: "assets/images/partners/partner5.png",
      description: `Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
      Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
      Cicero, written in 45 BC. "Lorem ipsum dolor sit amet..",
      comes from a line in section 1.10.32. The standard chunk of
      Lorem Ipsum used since the 1500s is reproduced below for those
      interested. Sections 1.10.32 and 1.10.33 from "de Finibus
      Bonorum et Malorum" by Cicero are also reproduced`,
    },
  ];

  return (
    <div className="partners">
      <Header />
      <div className="partners-container container">
        {mapData.map((item, index) => (
          <div
            key={index}
            className={
              index % 2 === 0 ? "content row " : "content row flex-row-reverse"
            }
          >
            <div className="col-md-6">
              <div
                className="image"
                style={{ backgroundImage: `url(${item.backgroundImage})` }}
              >
                <img src={item.imageSrc} alt="image" />
              </div>
            </div>
            <div className="col-md-6 d-flex mr-1">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex flex-wrap align-items-center">
                  <p className="title">{item.title}</p>
                  <p className="subtitle">
                    {item.subtitle ? item.subtitle : null}
                  </p>
                </div>
                <p className="description">{item.description} </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Partners;
