import React, { useState } from "react";
import "./AboutPrimary.css";
import { useTranslation } from "react-i18next";

function AboutPrimary({ primaryPainting, baseURL }) {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(2);

  // Mouse Enter
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  // Mouse Leave
  const handleMouseLeave = () => {
    setHoveredIndex(2);
  };

  return (
    <div className="about-primary">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("AboutPrimaryHead")}</h2>
            </div>
          </div>

          <div className="col-md-7">
            <h3>{t("AboutPrimaryLeftHeading")}</h3>
            <p>{t("AboutPrimaryLeftPara1")}</p>
            <p>{t("AboutPrimaryLeftPara2")}</p>

            <div className="ctBox-wrapper">
              <div className="ct-box">
                <span>1</span>
                <p>{t("AboutPrimaryCtBox1Para")}</p>
              </div>

              <div className="ct-box">
                <span>2</span>
                <p>{t("AboutPrimaryCtBox2Para")}</p>
              </div>
            </div>

            <h4>
              <span>{t("AboutPrimaryLeftSubHeadSpan")}</span>{" "}
              {t("AboutPrimaryLeftSubHead")}
            </h4>
          </div>

          <div className="col-md-5">
            <div className="right-image" style={{ backgroundImage: `url(${ primaryPainting?.cover_img ? baseURL + primaryPainting?.cover_img : "/assets/images/about-banner.png" })`, }}>
              {[...Array(9)].map((_, index) => (
                <div
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  className={`item ${
                    hoveredIndex === index ? "with-after" : ""
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPrimary;
