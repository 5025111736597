import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for the web
import { getDefaultMiddleware } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  refresh_token: null,
  footerData: null,
};

export const profileImageSlice = createSlice({
  name: "profileImage",
  initialState: "",
  reducers: {
    setProfileImage: (state, action) => {
      return action.payload;
    },
    clearProfileImage: (state) => {
      return null;
    },
  },
});

export const firstNameSlice = createSlice({
  name: "firstName",
  initialState: "",
  reducers: {
    setFirstName: (state, action) => {
      return action.payload;
    },
  },
});

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    clearToken: (state) => {
      return {
        ...state,
        token: null,
      };
    },
  },
});

export const refreshtokenSlice = createSlice({
  name: "refresh_token",
  initialState,
  reducers: {
    setRefreshToken: (state, action) => {
      return {
        ...state,
        refresh_token: action.payload,
      };
    },
    clearRefreshToken: (state) => {
      return {
        ...state,
        refresh_token: null,
      };
    },
  },
});

// export const counterSlice = createSlice({
//   name: "counter",
//   initialState: 0, // Initial state is 0
//   reducers: {
//     increment: (state) => state + 1,
//   },

export const footerDataSlice = createSlice({
  name: "footerData",
  initialState: "",
  reducers: {
    setFooterData: (state, action) => {
      return action.payload;
    },
    clearFooterData: (state) => {
      return null;
    },
  },
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["register"], // Exclude "register" from being persisted
};

const persistedReducer = persistReducer(persistConfig, tokenSlice.reducer);
const persistReducerRefresh = persistReducer(
  persistConfig,
  refreshtokenSlice.reducer
);

const store = configureStore({
  reducer: {
    token: persistedReducer,
    refresh_token: persistReducerRefresh,
    //  counter: counterSlice.reducer,
    profileImage: profileImageSlice.reducer,
    firstName: firstNameSlice.reducer,
    footerData: footerDataSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: process.env.NODE_ENV !== "production",
});

export const { setToken, clearToken } = tokenSlice.actions;
export const { setRefreshToken, clearRefreshToken } = refreshtokenSlice.actions;
// export const { increment } = counterSlice.actions;
export const { setProfileImage, clearProfileImage } = profileImageSlice.actions;
export const { setFirstName } = firstNameSlice.actions;
export const { setFooterData, clearFooterData } = footerDataSlice.actions;

export const persistor = persistStore(store);

export default store;
