import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop,
} from "recharts";
import dayjs from "dayjs";
import { schemeAccent, schemeCategory10, schemeDark2  } from "d3-scale-chromatic";
import { generateColors } from "../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const uniqueID="##R3T##%@*G4&77R"

const data = [
  { year: "2012", Red: 20, Blue: 10, Green: 70 },
  { year: "2013", Red: 30, Blue: 50, Green: 60 },
  { year: "2014", Red: 20, Blue: 10, Green: 100 },
  { year: "2015", Red: 70, Blue: 20, Green: 80 },
  { year: "2016", Red: 10, Blue: 50, Green: 80 },
  { year: "2017", Red: 20, Blue: 80, Green: 40 },
  { year: "2018", Red: 40, Blue: 10, Green: 60 },
  { year: "2019", Red: 30, Blue: 20, Green: 80 },
  { year: "2020", Red: 20, Blue: 10, Green: 50 },
  { year: "2021", Red: 30, Blue: 20, Green: 80 },
  { year: "2022", Red: 10, Blue: 10, Green: 30 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload?.length) {
    const date = dayjs(label)?.format("YYYY");
    return (
      <div className="custom-tooltip">
         <div style={{ backgroundColor: 'black', color: 'white', padding: '8px', fontFamily: "Red Hat Display"}}>
          <p className="label" style={{ fontSize:16 }}>{date}</p>
          {payload?.map((entry, index) => {
            const paintingTitle = entry?.name?.split("_")[0];
            return (
            <div className="d-flex" key={index}>
              <div>
                <FiberManualRecordIcon sx={{fontSize:12, color: entry?.stroke}}/>
              </div>
               <p style={{ fontSize:15, paddingLeft:5, textTransform:"capitalize"}}>
                {paintingTitle?.split(uniqueID)[1]}:
              </p>
              <p style={{ fontSize:15, paddingLeft:5}}>
                {entry?.value} {entry.payload?.curr}
              </p>
            </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

// Evolution Grpah for Artist and Works Page
const MultiLineChart = ({ graph, path, activeLine, setBorder }) => {
  const { i18n } = useTranslation();

  // Generate Custom Colors Hexcode based on Graph Length
  let customColorArr = generateColors(graph?.length || []);
  let combinedColors = [ ...schemeCategory10, ...schemeDark2, ...schemeAccent, ...customColorArr];

  useEffect(() => {
    setBorder(combinedColors)
  }, [activeLine])

  const convertToRechartsData = (apiResponse) => {
    const rechartsData = [];
    apiResponse?.forEach((item, index) => {
      item?.evolution_list?.forEach((line) => {
        let entry = rechartsData?.find(
          (data) =>
            dayjs(data?._id).format("YYYY") === dayjs(line?._id).format("YYYY")
        );
        if (!entry) {
          entry = { _id: dayjs(line?._id).format("YYYY") };
          rechartsData?.push(entry);
        }
        entry[`${path ? ((index+1)+uniqueID+item?.name): ((index+1)+uniqueID+(i18n?.language === "en" ? item?.title?.en:item?.title?.fr))}`] =line?.totalValue
        entry[`curr`] = line?.currency
      });
    });

  // Sort rechartsData based on the _id field (assuming it contains years)
  rechartsData?.sort((a, b) => {
    const yearA = parseInt(a?._id);
    const yearB = parseInt(b?._id);
    return yearA - yearB;
  });

  return rechartsData;
  };
  
  const rechartsData = convertToRechartsData(graph);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={rechartsData || data}
        margin={{ top: 25, right: 5, left: 0, bottom: 0 }}
      >
        <defs>
          {graph?.map((item, index) => (
            <linearGradient
              key={index}
              id={`gradient${index}`}
              x1="0%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop
                offset="0%"
                stopColor={combinedColors[index]}
                stopOpacity={0.05}
              />
              <stop
                offset="100%"
                stopColor={combinedColors[index]}
                stopOpacity={0.15}
              />
            </linearGradient>
          ))}
        </defs>

        <XAxis 
          dataKey="_id" 
          tickCount={10} 
          axisLine={true} 
          tickLine={false} 
        />
        <YAxis
          interval="preserveStart"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => {
            if (value >= 1000000) {
              return `${value / 1000000}M`;
            } 
            // else if (value >= 1000) {
            //   return `${value / 1000}K`;
            // }
             else {
              return value;
            }
          }}
          // domain={[0, 100]}
          // tickCount={11}
          // ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        />
        {graph?.map((item, index) => (
          <Area
            key={index}
            type="monotone" 
            dataKey={`${path ? ((index+1)+uniqueID+item?.name) : ((index+1)+uniqueID+(i18n?.language === "en" ? item?.title?.en:item?.title?.fr))}`}
            stroke={combinedColors[index]}
            strokeWidth={activeLine === item?._id ? 3 : 1.5}
            fill={`url(#gradient${index})`}
            activeDot={{ r: 5 }}
            dot={{ stroke:combinedColors[index], strokeWidth: 3, r: activeLine === item?._id ? 2.5:1.5, fill: combinedColors[index] }}
            connectNulls
          />
        ))}
       
        <Tooltip content={<CustomTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MultiLineChart;
