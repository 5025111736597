import React, { useEffect } from "react";
import "./Artwork.css";
import { Table, Spin } from "antd";
import { Button } from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Accordion from "react-bootstrap/Accordion";
import dayjs from "dayjs";

function Artwork({ data }) {
  const {
    tableData,
    isLoading,
    baseURL,
    i18n,
    t,
    handleDownloadData,
    limit,
    artWorkLength,
    setArtworkOffset,
    setTableData,
    activeKey,
  } = data;

  useEffect(() => {
    setTableData([]);
    setArtworkOffset(0);
  }, [activeKey]);

  const columns = [
    {
      title: t("ArtworkIDNo"),
      dataIndex: "reference_id",
      key: "reference_id",
      render: (reference_id) => <div className="reference">{reference_id}</div>,
    },
    {
      title: t("ArtworkDate"),
      dataIndex: "t",
      key: "t",
      align: "center",
      render: (t) => <div>{dayjs(t).format("DD MMM YYYY")}</div>,
    },
    {
      title: t("ArtworkType"),
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: t("ArtworkPartPrice"),
      dataIndex: "part_price",
      key: "part_price",
      align: "center",
      render: (part_price) => (
        <div>
          {part_price?.value} {part_price?.curr}
        </div>
      ),
    },
    {
      title: t("ArtworkParts"),
      dataIndex: "part_quantity",
      key: "part_quantity",
      align: "center",
    },
    {
      title: t("ArtworkTotal"),
      dataIndex: "full_amount",
      key: "full_amount",
      align: "center",
      render: (full_amount) => (
        <div>
          {full_amount?.value} {full_amount?.curr}
        </div>
      ),
    },
  ];

  const renderTableRows = (data) => {
    return (
      <Table
        dataSource={data?.salePurchase}
        columns={columns}
        rowKey="reference_id"
        pagination={false}
      />
    );
  };

  // Load More
  const LoadMore = () => {
    setArtworkOffset((prev) => prev + limit);
  };

  // Scroll Top
  const ScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const collapsePanels = tableData?.map((artwork, index) => (
    <Accordion key={index}>
      <Accordion.Item eventKey={artwork?._id}>
        <Accordion.Header>
          <div className="acc-header d-flex align-items-center">
            <img src={baseURL + artwork?.painting_img} alt="image" />
            <p className="title">
              {i18n?.language === "en"
                ? artwork?.painting_title?.en
                : artwork?.painting_title?.fr}
            </p>
          </div>
        </Accordion.Header>
        <Accordion.Body>{renderTableRows(artwork)}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  ));

  return (
    <div className="artwork">
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h1 className="sub-heading">{t("Artwork")}</h1>
        <Button
          className="download-button"
          onClick={() => handleDownloadData()}
        >
          <p>{t("Download")}</p>
          <img src="/assets/images/download.png" alt="image" />
        </Button>
      </div>
      <div className="table">
        {isLoading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <div>{collapsePanels}</div>
        )}
      </div>
      
      {artWorkLength < limit ? null : (
        <Button className="load-more-button" onClick={() => LoadMore()}>
          {t("ArtworkLoadMore")}
        </Button>
      )}

      <Button onClick={() => ScrollTop()} className="scroll-top-button">
        <KeyboardArrowUpRoundedIcon color="#808080" />
      </Button>
    </div>
  );
}

export default Artwork;
