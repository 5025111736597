import React, { useState, useEffect } from "react";
import './Header.css';
import { Dropdown, notification } from 'antd';
import { useTranslation } from "react-i18next";
import { Link , useNavigate, useLocation} from "react-router-dom";
import { Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import { userData, changeLanguage, currencyOnChange, setCurrentCurr, getSavedLanguage, logOut, useScreenWidth, authRoutes, clearLocalStorage} from '../../Services/Utils/Utils';
import MobileHeader from "./MobileHeader/MobileHeader";
import { clearToken } from "../../Services/Store/Store";
import { useDispatch } from "react-redux";
import { MemberDropDown, SettingsDropDown, LangCurrDropDown} from "../DropDowns/DropDowns"

function Header({setCurr, setCurrent}) {
  const { token, iev } = userData();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;
  const auth = authRoutes(path);
  const currentLanguage = getSavedLanguage();
  const [currentCurrency, setCurrentCurrency] = useState("");

  useEffect(() => {
    setCurrentCurr(setCurrentCurrency)
    if(setCurr) setCurr(currentCurrency)
  }, [currentCurrency]);

  useEffect(() => {
    if(token && iev==undefined && !iev) {
      navigate("/verify-email")
    }
  }, [])

  const secMarket =() =>{
    if(setCurrent) setCurrent(0)
  }

  return (
    <div className={i18n.language != "fr" ? "header" : "header header-fr"}>
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <div className="head-logo">
              <Link to='/'><img src="/assets/images/weart-logo-upd.png" alt="Header-Menu" /></Link>
            </div>
          </div>

          <div className="col-md-7">
            {
              screenWidth <= 1024 ? <MobileHeader />
            :
            <div className="head-menu">
              <ul>
              
                <li><Link to='/about' className={path=="/about"?"active":null}>{t("headerAboutUs")}</Link></li>
                {/* <li><a href='https://www.figma.com/proto/ZqHkV8NNiFy7m37JBt8wVp/About-Us?type=design&node-id=1-21&scaling=min-zoom&page-id=0%3A1' className={path=="/about"?"active":null}>{t("headerAboutUs")}</a></li> */}
                <li><Link to='/artists' className={path=="/artists" || (path?.includes("/artists/details")) ?"active":null}>{t("headerArtists")}</Link></li>
                <li><Link to='/primary-market' className={path=="/primary-market"?"active":null}>{t("headerPrimaryMarket")}</Link></li>
                <li><Link to='/secondary-market' className={path=="/secondary-market" ||  (path?.includes("/secondary-market/details")) ?"active":null} onClick={()=>{secMarket()}}>{t("headerSecondaryMarket")}</Link></li>
                <li><Link to='/gallery' className={path=="/gallery"?"active":null}>{t("headerGallery")}</Link></li>
              </ul>

              {/* <ul>
                <li>
                  <Dropdown
                    menu={[]}
                    placement="bottomLeft"
                    trigger={["hover"]}
                    arrow={false}
                    className="custom-dropdown"
                    dropdownRender={(menu) => (
                      <div className='drop-menu-s'>
                        <Link to='/presentation' className={path=="/presentation"?"active":null}>Presentation</Link>
                        <Link to='/collaborators' className={path=="/collaborators"?"active":null}>Collaborators</Link>
                        <Link to='/press' className={path=="/press"?"active":null}>Press</Link>
                        <Link to='/partners' className={path=="/partners"?"active":null}>Partner</Link>
                        <Link to='/faqs' className={path=="/faqs"?"active":null}>FAQs</Link>
                      </div>
                    )}
                  >
                    <Link to='#'>{t("headerProject")}<img src="/assets/images/submenu-icon.png" /></Link>
                  </Dropdown>
                </li>

                <li><Link to='/artists' className={path=="/artists" || (path?.includes("/artists/details")) ?"active":null}>{t("headerArtists")}</Link></li>
                <li><Link to='/works' className={path=="/works" || (path?.includes("/works/details")) ?"active":null}>{t("headerWorks")}</Link></li>
                <li><Link to='/primary-market' className={path=="/primary-market"?"active":null}>{t("headerPrimaryMarket")}</Link></li>
                <li><Link to='/secondary-market' className={path=="/secondary-market" ||  (path?.includes("/secondary-market/details")) ?"active":null} onClick={()=>{secMarket()}}>{t("headerSecondaryMarket")}</Link></li>
                <li><Link to='/about' className={path=="/about"?"active":null}>{t("headerAboutUs")}</Link></li>
                <li><Link to='/location' className={path=="/location"?"active":null}>{t("headerLocation")}</Link></li>
              </ul> */}
            </div>}
          </div> 

          {/* {token ? null :
            <img src="/assets/images/location-after-image.png" alt="image" className="divider-image"/> 
          } */}

          <div className="col-md-3">
            <div className="head-account">
              <ul>
                {/* <li>
                  <Link to="/">
                    <Button className="head-signin">Login</Button>
                  </Link>
                </li>

                <li>
                  <Link to="/">
                    <Button className="head-signup">Signup</Button>
                  </Link>
                </li> */}

                <li>
                  <MemberDropDown auth={auth}/>
                </li>
                <li>
                  <SettingsDropDown auth={auth}/>
                </li>
                <li>
                  <LangCurrDropDown 
                    currentLanguage={currentLanguage} 
                    changeLanguage={changeLanguage} 
                    currentCurrency={currentCurrency} 
                    setCurrentCurrency={setCurrentCurrency}
                  />
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Header;
