import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { Modal, Input, Form, Select, notification } from "antd";
import { getAdminAddBank, getAdminProfileBank, sendEmailAgain } from "../../../../Services/Api/Api";
import { Button, RadioGroup, Radio, FormControlLabel, CircularProgress } from "@mui/material";
import "./WithdrawModal.css";
const { Option } = Select;

function WithdrawModal({ visible, onClose, handleRequestWithdraw, profileData, curr }) {
  const navigate = useNavigate();
  const [withdrawalMethod, setWithdrawalMethod] = useState("bank");
  const [loading, setLoading] = useState(false);
  const [selectBank, setSelectBank] = useState([]);

  useEffect(() => {
    setWithdrawalMethod("bank");
    getData()
  }, []);

  const onFinish = async (values) => {
    if(!profileData?.ipe) {
      navigate("/user/edit")
      notification.warning({ message : "Please enter your phone number, to receive OTP!"});
      return
    } 
    setLoading(true);
    const result = await getAdminAddBank(values);
    const { data, status, error } = result || {};
    if (status === 200) {
      setLoading(false);
      handleRequestWithdraw(values);
      notification.success({ message : data?.message?.en});
    } else {
      setLoading(false);
      notification.error({ message: error?.en  || error});
    }
  };

  // Get User Banks Data
  const getData = async () => {
    const result = await getAdminProfileBank();
    const { data, status, error } = result  || {};
    if (status == 200) {
      setSelectBank(data);
    } else {
      notification.error({message : "Internal server error"});  
    }
  };

  const initialValues = {
    withdrawalMethod: "bank",
    email: profileData?.paypal?.email
  };

  const onCloseModal = () => {
    onClose(false);
    setWithdrawalMethod("bank");
  };

  // Withdrawal Field Amount Validator
  const validator = (_, value) => {
    const maxAmount = profileData?.wallet_amount?.value || 0;
    if (!value || Number(value) < 1) {
      return Promise.reject(`Should be greater than or equal to 1 ${curr}`);
    }
    if (Number(value) > maxAmount) {
      return Promise.reject(`Your account balance is insufficient to complete this transaction. Please ensure that you have sufficient funds in your account before proceeding.`);
    }
    return Promise.resolve();
  };

  return (
    <Modal
      className="withdraw-modal"
      open={visible}
      onCancel={onCloseModal}
      centered={true}
      closable={true}
      width={620}
      destroyOnClose={true}
      footer={[]}
    >
      <div>
        <div className="container mt-4">
          <div className="row">
            <div className="head-tbox">
              <h2>Smart account withdrawal request</h2>
            </div>

            <div className="first">
              <p>
                You have <span>{profileData?.wallet_amount?.value} {curr}</span> in your smart account, please
                choose the amount you want to withdraw and choose the account
                you want to receive the amount to.
              </p>
            </div>

            <Form onFinish={onFinish} initialValues={initialValues}>
              <div className="second">
                <h4>Recipient account</h4>

                <div className="radio-box">
                  <Form.Item
                    name="withdrawalMethod"
                    rules={[
                      {
                        required: true,
                        message: "Please select an option",
                      },
                    ]}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={withdrawalMethod}
                      onChange={(e) => setWithdrawalMethod(e.target.value)}
                      row={true}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="bank"
                            sx={{
                              color: "#C1BDBD",
                              "&.Mui-checked": { color: "#333333" },
                            }}
                          />
                        }
                        label={
                          <div className="label-name">
                            <h5>Bank</h5>
                          </div>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            value="paypal"
                            sx={{
                              color: "#C1BDBD",
                              "&.Mui-checked": { color: "#333333" },
                            }}
                          />
                        }
                        label={
                          <div className="label-name">
                            <h5>Paypal</h5>
                            {/* <p>David Robertson</p> */}
                          </div>
                        }
                      />
                    </RadioGroup>
                  </Form.Item>
                </div>

                <div className="field-area">
                  {withdrawalMethod == "bank" ? (
                    <div className="select-box">
                      <Form.Item
                        name="bankAccount"
                        rules={[
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Select placeholder="Select Bank Account">
                          {selectBank?.map((data, index)=>{
                            return <Option key={index} value={data?._id}>{data?.account_owner}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="email-box">
                      <label>Please provide a valid PayPal email address to ensure successful withdrawals <span>*</span></label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Invalid email",
                          },
                          {
                            required: true,
                            message: "You cannot leave this field blank",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder="Enter Your Email Address"
                          size="large"
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              </div>

              <div className="third">
                <h4>Amount to withdraw</h4>
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      validator: validator,
                    },
                  ]}
                >
                  <Input 
                    placeholder={`0.00 ${curr}`} 
                    size="large" 
                    type="number" 
                    step="0.01"
                  />
                </Form.Item>
              </div>

              <div className="action-box">
                <Button className="withdraw" type="submit" disabled={loading}>
                  {loading ? 
                  <CircularProgress
                    disableShrink
                    size={24}
                    sx={{ color: "white" }}
                  />
                  : "Request withdrawal"
                  }
                </Button>
                <Button className="close" onClick={onCloseModal}>
                  Close
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WithdrawModal;
