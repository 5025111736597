import { useEffect, useState } from "react";
import { notification } from "antd";
import { auth } from "../Firebase/Firebase";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getUserData, logoutUser, loginLangChange, } from "../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import { setFirstName, setToken } from "../../Services/Store/Store";
import { useDispatch } from "react-redux";

// Get User Data From Local Storage
export const userData = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const token = localStorage.getItem("token")
  const data ={
    token,
    ...userData
  }
  return data
};

// Update User Data from Local Storage
export const userDetailsData = (updateData) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  Object.entries(updateData).forEach(([field, value]) => {
    userData[field] = value;
  });
  localStorage.setItem('userData', JSON.stringify(userData));
};

// Langugae Switch for (Responses which are  Successful)
export const succMess = (result) => {
  // const { i18n } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || {};
  if(language === "en"){
    return result?.data?.message?.en
  }
  else{
    return result?.data?.message?.fr
  }
};


// Langugae Switch for (Responses which are Rejected)
export const errMess = (result) => {
  // const { i18n } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || {};
  if(language === "en"){
    return result?.error?.en
  }
  else{
    return result?.error?.fr
  }
};


// Language Change Fun (i18n)
export const changeLanguage = async (lng, i18n, token) => {
  if (lng) {
    i18n.changeLanguage(lng);
    if(token !== null)  {
      //  Conditional Based Language Change Api, After User Login,  
      const result = await loginLangChange(lng);
      const { data, status, error, message } = result || {};
      if (status === 200) {
        notification.success({ message: data?.message?.en });
      } else {
        notification.error({ message: error?.en || message?.en });
      }
    }
  } else {
    i18n.changeLanguage("en");
  }
};


// Currency Change Fun
export const currencyOnChange = (value, setCurrentCurrency) => {
  setCurrentCurrency(value);
  localStorage.setItem("currency", value);
  sessionStorage.setItem("curr_flag", value);
};


// Get Local Saved Langguage
export const getSavedLanguage = () => {
  const data = localStorage.getItem('i18nextLng') || "en";
  return data;
};


// Get Local Saved Currency
export const getSavedCurr = () => {
  const data = localStorage.getItem('currency') || "USD";
  return data;
};


// Set Currency in Local Storage 
export const setCurrentCurr = (setCurrentCurrency) => {
  const savedCurrency = localStorage.getItem("currency") || {};
  if (savedCurrency) {
    setCurrentCurrency(savedCurrency);
  } else {
    localStorage.setItem("currency", "USD");
    setCurrentCurrency("USD");
  }
};


// Scroll To Top
export const scrollTop = () => {
  window.scrollTo(0, 0);
};


// Logout
export const logOut = async (navigate) => {
  const refresh_token = localStorage.getItem('refresh_token') || {};
  await logoutUser(refresh_token).then(res => {
    if (res.status === 200) {
      clearLocalStorage()
      notification.success({message: res?.data?.message?.en});
      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 500);
      // Firebase Logout Fun
      FirebaseLogout()
    } else {
        // if(res?.error?.en=="User does not exist." || res?.error?.fr=="L'utilisateur n'existe pas."){
        //   setTimeout(() => {
        //     clearLocalStorage()
        //     navigate("/login", { replace: true });
        //   }, 500);
        // }
      notification.error({message: res?.error?.en});
    }
  }).catch(err => {
   
    notification.error({message: err?.message});
  });
};


// Firebase Logout Function
export const FirebaseLogout = async () => {
  const auth = getAuth();
  await auth.signOut()
    .then(() => {
      // console.log("Logged out from Facebook");
    })
    .catch((error) => {
      console.error("Error signing out:", error);
    });
};


export const userLogin = async(i18n, setLoading, navigate)=>{
  const userDataApi = await getUserData();
  const { status, data } = userDataApi || {}
  if(status === 200){
    i18n.changeLanguage(data?.lang);
    localStorage.setItem("currency", data?.curr);
    localStorage.setItem("userData", JSON.stringify(data));
    setTimeout(() => {
      setLoading(false)
      window.history.replaceState(null, null, "/");
      navigate("/");
      setTimeout(() => {
        notification.success({ message : "Login successful" });
      }, 500);
    }, 500);
  }
  else{
    setLoading(false)
    notification.error({ message : "Internal server error"});
  }
}

// User Data (Set to Local Storage)
export const userAuthData = async (result, values, i18n, setLoading, navigate, remMe, dispatch) => {
  const { access_token, refresh_token, iev  } = result?.data || {}
  localStorage.setItem("token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
  if (remMe) {
    localStorage.setItem("rememberedEmail", values?.email);
  } else {
    localStorage.removeItem("rememberedEmail");
  }
  dispatch(setToken(access_token));
  if(!iev){
    window.history.replaceState(null, null, "/verify-email");
    navigate("/verify-email");
    notification.success({ message : "Verification code has been sent to you for email. please verify your email address."});
  }
  else{
    userLogin(i18n, setLoading, navigate)
  }
};


// Get Remember Email for LogIn
export const remEmail = (form, setRemMe) => {
  const remMe = localStorage.getItem("rememberedEmail");
  if (remMe) {
    form.setFieldsValue({
      email: remMe
    })
    setRemMe(true);
  }
}


// Get Screen Width
export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window?.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return screenWidth;
};


// Otp Validator for Un-Authorized Page
export const handleOtpSend = (otp, setOtp, setOtpValidation) => {
  if (!otp) {
    setOtpValidation("Plesae input Otp!");
  } else if (otp.length != 6) {
    setOtpValidation("Otp is not valid!");
  } else if (otp.length == 6) {
    // setOtp("");
    setOtpValidation("");
    return true
  } else {
    setOtpValidation("In-valid Otp!");
  }
};


// Otp Validator for Authorized Pages
export const handleOtpConfirm = (
  otp,
  // setOtp,
  setOtpValidation,
  // setOtpModal,
  // setSmartAccountModal,
  // setWithdrawProcessModal
) => {
  if (!otp) {
    setOtpValidation("Plesae input Otp!");
  } else if (otp.length != 6) {
    setOtpValidation("Otp is not valid!");
  } else if (otp.length == 6) {
    // setOtp("");
    setOtpValidation("");
    // setOtpModal(false);
    return true
    // if(setSmartAccountModal)
    // { 
    //   setSmartAccountModal(true);
    // }
    // else {
    //   setWithdrawProcessModal(true);
    // }
  } else {
    setOtpValidation("In-valid Otp!");
  }
};


// Ant-d Form Password Validator
export const validatePassword = (form, t) => (_, value) => {
  const { getFieldValue } = form;
  if (value && value !== getFieldValue("password")) {
    return Promise.reject(t("RegValConfirmPassMatch"));
  }
  return Promise.resolve();
};


// Ant-d Form Password Validator
export const validateCheckbox = (form, t) => (_, value) => {
  if (value) {
    return Promise.resolve();
  }
  return Promise.reject(t("RegValCheckBox"));
};


// Ant-d Form Validate Phone Number
export const validatePhone =
  (t, form, phoneNum, countryCode, setPhoneValidation, reg) => (_, value) => {
    const formatted_phone = phoneNum?.substring(countryCode?.length);
    if (!formatted_phone.length) {
      setPhoneValidation(true);
      return Promise.reject(reg==="reg" ? t("RegValBlank") : "You cannot leave this field blank");
    } else if (
      formatted_phone &&
      formatted_phone.length > 1 &&
      formatted_phone.length < 8
    ) {
      setPhoneValidation(true);
      return Promise.reject(reg==="reg" ? t("RegInvalidPhone") : "Phone number is not valid");
    }
    setPhoneValidation(false);
    return Promise.resolve();
  };


// OnChange Phone Number
export const onChangePhone = (
  form,
  value,
  country,
  setCountryCode,
  setPhoneNum,
  setSlug
) => {
  setCountryCode(country?.dialCode);
  setSlug(country?.countryCode)
  setPhoneNum(value);
  form.validateFields(["phone"]);
};


// Auth Routes for (header)
export const authRoutes = (path) => {
  const authPaths = ["/login", "/register", "/forgot", "/verify-email", "/reset-password", "/change-password", "/email-confirm"];
  return authPaths?.some((authPath) => path?.includes(authPath));
};


// Set Default Lang and Curr
export const DefaultLangCurr = () => {
  const lang = localStorage.getItem("i18nextLng");
  const curr = localStorage.getItem("currency");
  const supportedLanguages = ['en', 'fr']; 
  const supportedCurrencies = ['USD', 'EURO'];
  if (!lang || !supportedLanguages.includes(lang)) {
    localStorage.setItem("i18nextLng", "en");
  }
  if (!curr || !supportedCurrencies.includes(curr)) {
    localStorage.setItem("currency", "USD");
  }
};


// Clear Local Storage
export const clearLocalStorage = (navigate) => {
  localStorage.removeItem('userData');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token');
  localStorage.removeItem('persist:root');
  localStorage.removeItem("__paypal_storage__");
  if(navigate) {
    setTimeout(() => {
      navigate("/login")
    }, 1000);
  }
}


// Buy Drawer - PerParts Items Selection Boxes
export const renderPerPartsBoxes = (perPartsItems) => {
  const remainingParts = perPartsItems % 9;
  const totalBoxes = 9;
  const boxes = [];

  for (let i = 0; i < totalBoxes; i++) {
    const isSelected = i < remainingParts || (remainingParts === 0 && perPartsItems >= 9);
    // Add a specific class to selected boxes
    const boxClass = isSelected ? 'box selected' : 'box';
    boxes.push(
      <div key={i} className={boxClass}></div>
    );
  }
  return boxes;
};


// Buy Drawer - Logic for (1 Cm2 5 Parts) convertion 
export const partsItemsConvert = (selectedParts, measure) => {
  // Assuming 1 cm^2 is equivalent to 9 parts
  const partsPerSquareCentimeter = 9;
  // Calculate the total square centimeters and remaining parts
  const totalSquareCentimeters = Math.floor(selectedParts / partsPerSquareCentimeter);
  const remainingParts = selectedParts % partsPerSquareCentimeter;

  if (totalSquareCentimeters > 0 && remainingParts >= 0) {
    // Both centimeters and remaining parts are present
    return {
      displayText: (
        <span>
           {totalSquareCentimeters} cm<sup>2 </sup>
           {remainingParts === 0 ? '' : remainingParts + ' Parts'}
        </span>
    ),
      // totalSquareCentimeters,
      // remainingParts,
    };
  } else if (totalSquareCentimeters > 0) {
    // Only centimeters are present
    return {
      displayText: (
          <span>
              {totalSquareCentimeters} cm<sup>2 </sup>
          </span>
      ),
      // totalSquareCentimeters,
      // remainingParts,
    };
  } else if (remainingParts > 0) {
    // Only remaining parts are present
    return {
      displayText: `${remainingParts} parts`,
      // totalSquareCentimeters,
      // remainingParts,
    };
  } else {
    // No parts or centimeters present
    return {
      displayText: (
          <span>
              0 {measure=="cm"?<>{measure}<sup>2</sup></>:"parts"}
          </span>
      ),
      // totalSquareCentimeters,
      // remainingParts,
    };
  }
}

  // Function to handle scroll event - Artists Graph
  export const handleScroll = (scrollContainerRef, graphLimit, setGraphOffset) => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainer;
      if (scrollLeft + clientWidth + 10 >= scrollWidth) {
        setGraphOffset((prev) => prev + graphLimit)
      }
    }
  };

  // Validation Language Change
  export const valLangChange = (form) => {
    if (form) {
      const errorFields = form
        .getFieldsError()
        .reduce(
          (arr, field) => (field.errors.length && arr.push(field.name), arr),
          []
        );
      form.validateFields(errorFields);
    }
  };


  // Generate Custom Colors Hexcode based on Graph Length
  // Evolution Grpah for Artist and Works Page
  export const colors = [
    '#FF0000', // Red
    '#00FF00', // Green
    '#0000FF', // Blue
    '#000000', // Black
    '#FFFFFF', // White
    '#FFFF00', // Yellow
    '#FF00FF', // Magenta
    '#00FFFF', // Cyan
    '#800080', // Purple
    '#FFA500', // Orange
  ];

  export const generateColors = (count) => {
      const generatedColors = [];
      const numColors = colors.length;
      let currentIndex = 0;
      
      for (let i = 0; i < count; i++) {
          generatedColors.push(colors[currentIndex]);
          currentIndex = (currentIndex + 1) % numColors;
      }
      
      return generatedColors;
  };

