import React, { useEffect, useState } from "react";
import { notification, Spin } from "antd";
import {
  getAdminPortfolio,
  getAdminPortfolioDividendGraph,
} from "../../../Services/Api/Api";
import { useTranslation } from "react-i18next";
import PortfolioLineChart from "../../../Components/Charts/PortfolioLineChart";
import "./Portfolio.css";

function Portfolio() {
  const { t, i18n } = useTranslation();
  const [graph, setGraph] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [portfolio, setPortfolio] = useState({
    quantity: 0,
    total: 0,
    dividend: 0,
    total_credit: 0,
    currency: "USD",
  });
  const curr = portfolio?.currency == "USD" || !portfolio ? "$" : "€";

  useEffect(() => {
    getPortfolioData();
    getDividendData();
  }, []);

  const getPortfolioData = async () => {
    const result = await getAdminPortfolio();
    const { data, status, error } = result || {};
    if (status == 200) {
      setPortfolio(data[0]);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  // Get Portfolio Dividend Graph Data
  const getDividendData = async () => {
    setLoading(true);
    const result = await getAdminPortfolioDividendGraph();
    const { data, status, error } = result || {};
    if (status == 200) {
      setGraph(data);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  return (
    <div className="portfolio">
      <div className="head-box">
        <h2 className="heading">{t("Portfolio")}</h2>
      </div>

      <div className="portfolio-boxWrapper">
        <div className="portfolio-box">
          <div className="content">
            <h4>{t("PortfolioMyParts")}</h4>
            <h3>{portfolio ? portfolio?.quantity : 0}</h3>
          </div>

          <div className="imageBox">
            <img src="/assets/images/parts-image.png" alt="" />
          </div>
        </div>

        <div className="portfolio-box">
          <div className="content">
            <h4>{t("PortfolioTotalPartsVal")}</h4>
            <h3>
              {portfolio ? portfolio?.total : 0}
              <span>{curr}</span>
            </h3>
          </div>

          <div className="imageBox">
            <img src="/assets/images/partsValue-image.png" alt="" />
          </div>
        </div>

        <div className="portfolio-box">
          <div className="content">
            <h4>{t("PortfolioDiviEarn")}</h4>
            <h3>
              {portfolio ? portfolio?.dividend : 0}
              <span>{curr}</span>
            </h3>
          </div>

          <div className="imageBox">
            <img src="/assets/images/earning-image.png" alt="" />
          </div>
        </div>

        <div className="portfolio-box">
          <div className="content">
            <h4>{t("PortfolioTotalCredit")}</h4>
            <h3>
              {portfolio ? portfolio?.total_credit : 0}
              <span>{curr}</span>
            </h3>
          </div>

          <div className="imageBox">
            <img src="/assets/images/earning-image.png" alt="image" />
          </div>
        </div>
      </div>

      <div className="third">
        <h4>{t("PortfolioDiviEarn")}</h4>
        {isLoading ? (
          <div className="table">
            <div className="spin">
              <Spin />
            </div>
          </div>
        ) : graph?.length ? (
          <PortfolioLineChart graph={graph} curr={curr} />
        ) : null}
      </div>
    </div>
  );
}

export default Portfolio;
