import { apiHeader, apiNoHeader } from "./BaseUrl";
import axios from "axios";

//Register API
export const registerUser = async (values) => {
  let data;
  await apiHeader
    .post("/user/register", {
      first_name: values.firstname,
      last_name: values.lastname,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
      email: values.email,
      password: values.password,
      password_confirmation: values.confirm_password,
      curr: values.global.curr,
      lang: values.global.lang,
      device_token: "string",
      device_type: "web",
    })
    .then((res) => {
      data = {
        status: true,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: false,
        };
      }
    });
  return data;
};


//Login API
export const loginUser = async (values, fcmToken) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      device_type: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Send Email Again - (Verify E-Mial After Login)
export const sendEmailAgain = async () => {
  let data;
  await apiHeader
    .post("/user/resend/email")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Refresh Token Api
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiNoHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//User Email Verify (After Login) API
export const userEmailVerify = async (otp) => {
  let data;
  await apiHeader
    .post("/user/email/verify", {
      code: otp,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Forgot Password Api
export const forgotPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// User Profile Get Data 
export const getUserData = async () => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Reset Password Page Redirection Api
export const resetPasswordPage = async (token, user_id) => {
  let data;
  await apiHeader
    .get(`/auth/forgotpwd/verify-link?forgot_pwd_token=${token}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// Change Password Page Redirection Api
export const changePasswordPage = async (values, token) => {
  let data;
  await apiHeader
    .post(`/auth/forgotpwd/verify-email`, {
      email: values.email,
      forgot_password_token: token
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// Change Password Api
export const changePassword = async (values, token) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/reset", {
      email: values.email,
      forgot_pwd_token: token,
      new_password: values.password,
      confirm_new_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Logout Api
export const logoutUser = async (refresh_token) => {
  let data;
  await apiHeader
    .post("/auth/logout", {
      refresh_token: refresh_token,
      device_token: "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Langugae Change of a Particuler LogIn User
export const loginLangChange = async (lang) => {
  let data;
  await apiHeader
    .post("/user/language", {
      lang: lang
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Content API - Privacy Policy / Terms Conditions / Faqs
export const getContent = async (type) => {
  let data;
  await apiHeader
    .get(`/content/?type=${type}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Location / Contact API
// export const getLocation = async () => {
//   let data;
//   await apiHeader
//     .get("/locations/?limit=1000&offset=0")
//     .then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };

// Artist Data  API
export const getArtists = async (limit, offset, curr) => {
  let data;
  await apiHeader
    .get(`/artists/?limit=${limit}&offset=${offset}&currency=${curr}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Works Data  API
// export const getWorks = async (limit, offset, search, curr, lang) => {
//   let data;
//   let language = lang == "en-US" ? "en" : "fr";
//   await apiHeader
//     .get(`/paintings/works?search=${search}&limit=${limit}&offset=${offset}&currency=${curr}&language=${language}`)
//     .then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };

// Artist Details API
export const getArtistsDetails = async (artist_id, curr) => {
  let data;
  await apiHeader
    .get(`/artists/detail?artistId=${artist_id}&currency=${curr}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Works Details - To Get Specific Painting Details API
// Alos Use In - Admin - MyParts - Paintings Details
export const getPaintingDetails = async (painting_id, curr) => {
  let data;
  await apiHeader
    .get(`/paintings/detail?painting_id=${painting_id}&currency=${curr}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Primary,Secondary Market - API
export const getMarketPaintings = async (limit, offset, status, curr ) => {
  let data;
  await apiHeader
    .get(`/paintings/?type=${status}&limit=${limit}&offset=${offset}&currency=${curr}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Profile - User Bank View
export const getAdminProfileBank = async () => {
  let data;
  await apiHeader
    .get("/user/bank")
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
}


// Admin - Portfolio - Card  Data API
export const getAdminPortfolio = async () => {
  let data;
  await apiHeader
    .get(`/member/portfolio`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Portfolio - Dividend Graph Data API
export const getAdminPortfolioDividendGraph = async () => {
  let data;
  await apiHeader
    .get(`/member/dividend/graph`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Admin - Market - API
export const getAdminMarket = async (limit, offset, status) => {
  let data;
  await apiHeader
    .get(`/member/market/?type=${status}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Admin - Parts - Evolution Graph API 
export const getAdminPartsGraph = async () => {
  let data;
  await apiHeader
    .get(`/member/parts/graph`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Parts - API 
export const getAdminParts = async (limit, offset) => {
  let data;
  await apiHeader
    .get(`/member/parts/?limit=${limit}&offset=${offset}`
    )
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Withdrawals - API
export const getAdminWithdrawals = async (limit, offset, status) => {
  let data;
  await apiHeader
    .get(`/member/withdrawal/${status}?limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Withdrawals - Add Bank API
export const getAdminAddBank = async (values) => {
  let data;
  await apiHeader
    .post("/member/withdrawal", {
      payment_method: values.withdrawalMethod,
      account: values.bankAccount || values.email,
      amount: Number(values.amount)
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Withdrawals - Phone OTP Resend API
export const userPhoneResend = async (otp, profileData) => {
  const { number, code } = profileData?.phone
  let data;
  await apiHeader
    .post("/user/phone", {
      number: number,
      country_code: code,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Withdrawals - Verify OTP API
export const userPhoneVerify = async (otp, profileData) => {
  const { slug, number, code } = profileData?.phone
  let data;
  await apiHeader
    .post("/user/phone/verify", {
      slug: slug,
      number: number,
      country_code: code,
      verification_code: otp
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - Withdrawals - Submit OTP API
export const userWithdrawalConfirm = async (values) => {
  let data;
  await apiHeader
    .post("/member/withdrawal/store", {
      payment_method: values.withdrawalMethod,
      account: values.bankAccount || values.email,
      amount: Number(values.amount)
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - History - API
export const getAdminHistory = async (limit, offset, statusType) => {
  let data;
  await apiHeader
    .get(`/member/history/${statusType}?limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - History - Download Button Data API
export const getAdminHistoryDownload = async (statusType) => {
  let data;
  await apiHeader
    .get(`/member/download/${statusType}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Home / Art Strategic / Home content
export const artStrategic = async () => {
  let data;
  await apiHeader
    .get("/content/home")
    .then((res) => {
      data = {
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response.data,
        };
      }
    });
  return data;
};


// Footer
export const footer = async () => {
  let data;
  await apiHeader
    .get("/content/footer")
    .then((res) => {
      data = {
        data: res.data,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response.data,
        };
      }
    });
  return data;
}

//Get Profile
export const getProfile = async () => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Edit Profile
export const postProfile = async (values,slug) => {
  let data;
  await apiHeader
    .patch("/user/profile", {
      first_name: values.firstname,
      last_name: values.lastname,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
      // country_code : code,
      // number: modifiedNumber
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Update Profile Picture
export const updateProfilePicture = async (image) => {
  let data;
  const apiUrl = "/user/profile-image";
  const formData = new FormData();
  formData.append("profile_picture", image);

  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

//Edit Profile Reset Password
export const updatePassword = async (values) => {
  let data;
  await apiHeader
    .put("/auth/password/reset", {
      old_password: values.old_password,
      new_password: values.password,
      confirm_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Edit Profile User Bank Post
export const updateBank = async (values) => {
  let data;
  await apiHeader
    .post("/user/bank", {
      account_owner: values.bank_account_owner,
      iban: values.iban,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Edit Profile Email Paypal
export const updateEmailPaypal = async (values) => {
  let data;
  await apiHeader
    .patch("user/paypal", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Get Paintings
export const getPaintings = async (paintings) => {
  let data;
  await apiHeader
    .get(`paintings/?type=${paintings}&currency=USD&limit=50&offset=0`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Admin - MyParts - Paintings Details
// export const getPaintingDetails = async (id) => {
//   let data;
//   await apiHeader
//     .get(`/paintings/detail?painting_id=${id}&currency=USD`)
//     .then((res) => {
//       data = {
//         data: res.data,
//         status: res.status,
//       };
//     })
//     .catch((e) => {
//       if (e.response) {
//         data = {
//           ...e.response.data,
//           status: e.response.status,
//         };
//       }
//     });
//   return data;
// };

//Purchase Paintings
export const purchasePaintings = async (paymentType, paintingParts, paintingId) => {
  let data;
  await apiHeader
    .post("paintings/purchase", {
      total_part: paintingParts,
      payment_method: paymentType,
      painting_id: paintingId


    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Painting Order
export const orderPaintings = async (refId,paypal_payment_id,paypal_object, payment_method) => {
  let data;
  await apiHeader
    .post("paintings/order", {
      order_id: refId,
      paypal_payment_id:paypal_payment_id,
      paypal_object: paypal_object,
      payment_method: payment_method
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Painting Sale
export const salePaintings = async (paintingParts, paintingId) => {
  let data;
  await apiHeader
    .post("paintings/sale", {
      total_part: paintingParts,
      painting_id: paintingId
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Validate Paintings
export const validatePaintings = async (paymentType, paintingParts, paintingId) => {
  let data;
  await apiHeader
    .post("paintings/validate", {
      total_part: paintingParts,
      payment_method: paymentType,
      painting_id: paintingId
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Social Authentication
export const socialAuth = async (token, type) => {
  let data;
  await apiHeader
    .post("/auth/token/social", {
      token: token,
      token_type: type,
      device_token: "string",
      device_type: "web",
      curr: "USD",
      lang: "en",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Home - Wait List
export const waitList = async (values, type) => {
  let data;
  await apiHeader
    .post("/waitlist/", {
      email: values.email,
      type: type
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Home & Press - Get Blogs
export const getBlogs = async (page, per_page) => {
  let data;
  let blogsUrl = process.env.REACT_APP_BLOGS_URL
  await axios
    .get(`${blogsUrl}/api/blogsdata?page=${page}&per_page=${per_page}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Gallery - Get Galleries 
export const getGalleries = async (limit, offset) => {
  let data;
  await apiHeader
    .get(`/content/galleries?limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};