import React, { useState, useEffect, useRef } from "react";
import "./Artists.css";
import { notification, Spin } from "antd";
import { scrollTop, handleScroll } from "../../Services/Utils/Utils";
import { getArtists } from "../../Services/Api/Api";
import MultiLineChart from "../../Components/Charts/MultiLineChart";
import DashedLineCharts from "../../Components/Charts/DashedLineCharts";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import LoadMore from "../../Components/LoadMore/LoadMore";
import dayjs from "dayjs";
import AddIcon from '@mui/icons-material/Add';

function Artists() {
  const scrollContainerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const currency = localStorage.getItem("currency");
  const flag = sessionStorage.getItem("curr_flag");
  const [curr, setCurr] = useState(currency);
  const [artistsData, setArtistsData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [baseURL, setBaseURL] = useState("");
  const [graphBaseURL, setGraphBaseURL] = useState(null);
  const [activeLine, setActiveLine] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [limit, setLimit] = useState(9);
  const [offset, setOffset] = useState(0);
  const [checkLength, setLength] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [graphLimit, setGraphLimit] = useState(5);
  const [graphOffset, setGraphOffset] = useState(0);
  const [graphLength, setGraphLength] = useState(0);
  const [border, setBorder] = useState([]);

  const handleLineHighlight = (user) => {
    setActiveLine(user?._id);
    setSelectedUser(user?._id);
  };

  useEffect(() => {
    scrollTop();
    sessionStorage.setItem("curr_flag", currency);
  }, []);

  useEffect(() => {
    getGraphData()
  }, [graphOffset]);

  useEffect(() => {
    getArtistsData();
  }, [limit, offset]);

  // Get Artists MultiLine Graphs Data
  const getGraphData = async () => {
    const result = await getArtists(graphLimit, graphOffset, curr);
    const { data, status, error } = result || {};
    if (status === 200) {
      setGraphData((prev) => [...prev, ...data?.artists]);
      setGraphBaseURL(data?.base_url)
      setGraphLength(data?.artists?.length);
      setLoading(false);
      sessionStorage.setItem("curr_flag", currency);
    } else {
      setLoading(false);
      notification.error({ message: error || "Internal server error" });
    }
  };

  // Get Artists Data
  const getArtistsData = async () => {
    const result = await getArtists(limit, offset, curr);
    const { data, status, error } = result || {};
    if (status === 200) {
      setArtistsData((prev) => [...prev, ...data?.artists]);
      setLength(data?.artists?.length);
      setBaseURL(data?.base_url);
    } else {
      notification.error({ message: error || "Internal server error" });
    }
  };

  // Currency Change for Artists 
  useEffect(() => {
    if(curr){
      const dataLength = artistsData?.length
      if (dataLength > 0 && offset === 0) {
        setArtistsData([])
        getArtistsData()
      } else if (dataLength > 0) {
        setArtistsData([])
        setOffset(0)
      }
    }
  }, [curr]);

  // Currency Change for Artist Evolution Graph
  useEffect(() => {
    if (curr) {
      const dataLength = graphData?.length
      if (dataLength > 0 && graphOffset === 0) {
        setGraphData([]);
        getGraphData();
        setLoading(true);
      } else if (dataLength > 0) {
        setGraphData([]);
        setGraphOffset(0);
        setLoading(true);
        sessionStorage.removeItem("curr_flag");
      }
    }
  }, [curr]);

  // Function to handle scroll event - Artists Graph
  const onScroll = () => {
    if (graphLength > 0 && flag) {
      handleScroll(scrollContainerRef, graphLimit, setGraphOffset)
    }
  }

  return (
    <div className="artists">
      <Header setCurr={setCurr} />
      <div className="container">
        <div className="row">
          <h1 className="heading">{t("ArtistsEvolutionHead")}</h1>

          <div className="multi-line-chart">
            {isLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <MultiLineChart 
                activeLine={activeLine} 
                graph={graphData} 
                path={true} 
                setBorder={setBorder}
              />
            )}
          </div>

          <div className="users"
            ref={scrollContainerRef} 
            onScroll={()=>onScroll()}
          >
            {graphData?.map((user, index) => (
              <Button
                key={index}
                onClick={() => handleLineHighlight(user)}
                className={selectedUser === user?._id ? "active-line" : ""}
              >
                <span style={{ '--dot-border-color': border[index] }} >
                  <img src={graphBaseURL + user?.image} alt="image" />
                </span>
                <p>
                  {user?.name}
                </p>
              </Button>
            ))}
          </div>

          <div className="divider" />

          <div className="artists-section">
            <h1 className="artist-heading">{t("ArtistsHead")}</h1>
            <div className="artists-container d-flex flex-wrap ">
              {artistsData?.map((artist, index) => (
                <div
                  key={index}
                  className="details d-flex flex-column justify-content-between"
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={baseURL + artist?.image} alt="image" />
                      </div>

                      <div>
                        <div>
                          <p className="username">
                             {artist?.name}
                          </p>
                        </div>
                        <div>
                          <p className="years">
                            (
                            {dayjs(artist?.life_dates?.date_of_birth).format( "YYYY") + (
                             artist?.life_dates?.date_of_death !== ""  ? " - " +
                            dayjs(artist?.life_dates?.date_of_death).format( "YYYY") : "")}
                            )
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* 
                    <div className="d-flex align-items-start">
                      <p className="price">
                        {artist.price ? artist.price : null}
                      </p>
                      <p className="currency">
                        {artist.currency ? artist.currency : null}
                      </p>
                    </div> */}
                  </div>

                  <div className="dashed-chart">
                    <DashedLineCharts Chartdata={artist?.evolution_list} />
                  </div>

                  <div>
                    <Link to={`/artists/details/${artist?._id}`}  state={{ path: "artist" }}>
                      <Button variant="outlined">{t("ArtistsViewDetails")}</Button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Load More */}
            <LoadMore
              limit={limit}
              setOffset={setOffset}
              data={artistsData}
              checkLength={checkLength}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Artists;
