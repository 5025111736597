import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.css";
import { getContent } from "../../Services/Api/Api"
import { scrollTop } from "../../Services/Utils/Utils"
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const [privacyData, setPrivacyData] = useState([]);

  useEffect(() => {
    scrollTop();
    getData()
  }, []);

  // Get Privacy Policy Data
  const getData = async () => {
    const result = await getContent("privacy-policy");
    const { data, status, error } = result || {};
    if (status == 200) {
      setPrivacyData(data?.content);
    } else {
      notification.error({message : "Internal server error"});  
    }
  };
  
  return (
    <div className="privacy-policy">
      <Header />
      <div className="container privacy-policy-container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="privacy-policy-title">Privacy Policy</h1>
            <div className="content">
              {privacyData?.map((item, index) => (
                <div key={index}>
                  <h1 className="title">{i18n?.language === "en" ? item?.title?.en : item?.title?.fr}</h1>
                  <div className="border" />
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: i18n?.language === "en" ? item?.description?.en : item?.description?.fr }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
