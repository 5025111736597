import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import { message, notification } from "antd";
import "./Profile.css";
import { Link } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { getAdminProfileBank, getProfile, updateProfilePicture } from "../../../Services/Api/Api";
import { userDetailsData } from "../../../Services/Utils/Utils";
import {setProfileImage } from "../../../Services/Store/Store";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataToPass = {
    key1: 'value1',
    key2: 'value2',
  };
  const [profileData, setProfileData] = useState()
  const [selectBank, setSelectBank] = useState([]);
  const [baseUrl, setBaseUrl] = useState("")

  const GetProfileData = async () => {
    const result = await getProfile();
    if (result) {
      setProfileData(result?.data)
      setBaseUrl(result?.data?.base_url)
      userDetailsData({ img: result?.data?.img });
      dispatch(setProfileImage(result?.data?.img));
      const imagePreview = `${result?.data?.base_url}${result.data?.img}`;
      if (result.data?.img !== "") {
        setImagePreview(imagePreview);
      }
    } else {
      console.log(("serverError"));
    }
  };

  const getData = async () => {
    const result = await getAdminProfileBank();
    const { data, status, error } = result || {};
    if (status == 200) {
      setSelectBank(data);
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  useEffect(() => {
    GetProfileData();
    getData();
    
  }, [])

  const [imagePreview, setImagePreview] = useState(
    "/assets/images/default-profile.jpeg"
  );

  const handleImageUpload = async (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      if (selectedImage.size > 2 * 1024 * 1024) {
        message.error("Image size must be less than 2 MB");
      } else {
        try {
          const result = await updateProfilePicture(selectedImage);
          const { data, status, error } = result;
          if (status === 200) {
            GetProfileData();
            setTimeout(() => {
              notification.success({ message: data?.message?.en });
            }, [1500])
          } else {
            notification.error({ message: result?.message });
          }
        } catch (error) {
          console.error('Error updating profile picture:', error);
        }
      }
    }
  };

  return (
    <div className="profile">
      <div className="head-box">
        <h2 className="heading">{t("Profile")}</h2>

        <Link to={`/user/edit`}>
          <Button>
            Edit Profile
            <img src="/assets/images/editProfile-icon.png" alt="image" />
          </Button>
        </Link>
      </div>

      <div className="first">
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleImageUpload}
          id="image-upload-input"
          style={{ display: "none" }}
        />
        <div className="d-flex flex-column  align-items-end upload-image-s">
          {imagePreview ? <img src={imagePreview} alt="Selected" /> : null}
          <label htmlFor="image-upload-input">
            <CameraAltIcon />
          </label>
        </div>
        <div className="name-box">
          <h4>{profileData?.first_name} {profileData?.last_name}</h4>
          {/* <p>1969</p> */}
        </div>
      </div>

      <div className="second">
        <h3>Identity</h3>
        <h5>
          <img src="/assets/images/dashboard-hover2.png" alt="image" />
          My Profile:
        </h5>
        <p>Add or modify your personal information</p>
      </div>

      <div className="third">
        <h3>Connection</h3>
        <div className="top">
          <h5>Email:</h5>
          <p>{profileData?.email}</p>
        </div>
        <div className="bottom">
          <h5>Password:</h5>
          <p>***********</p>
        </div>
      </div>

      <div className="fourth">
        <h3>Accounts</h3>
        <h5>
          <span>
            <img src="/assets/images/bank-image.png" alt="image" />
          </span>
          {selectBank[0] ? selectBank[0]?.account_owner : "Add Bank Account"}{" "}
          <Link to="/user/edit">
            <img src="/assets/images/plus-image.png" alt="image" />
          </Link>
        </h5>
        <h5>
          <span>
            <img src="/assets/images/paypal-image.png" alt="image" />
          </span>
          Add PayPal Account{" "}
          <Link to="/user/edit">
            <img src="/assets/images/plus-image.png" alt="image" />
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default Profile;
