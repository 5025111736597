import React, { useEffect, useState } from "react";
import { Spin, notification } from "antd";
import "./Gallery.css";
import { getGalleries } from "../../Services/Api/Api";
import { scrollTop } from "../../Services/Utils/Utils";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";
import LoadMore from "../../Components/LoadMore/LoadMore";
import GalleryLightBox from "./GalleryLightBox/GalleryLightBox";

function Gallery() {
  const { t, i18n } = useTranslation();
  const [galleries, setGalleries] = useState([]);
  const [baseURL, setBaseURL] = useState("");
  const [limit, setLimit] = useState(9);
  const [offset, setOffset] = useState(0);
  const [checkLength, setLength] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, offset]);

  // Get Gallery Data
  const getData = async () => {
    const result = await getGalleries(limit, offset);
    const { data, error, status } = result || {};
    if (status === 200) {
      setGalleries((prev) => [...prev, ...data?.galleries]);
      setLength(data?.galleries?.length);
      setBaseURL(data?.base_url);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Open Carousal Image View;
  const openCarousel = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Close Carousal Image View;
  const closeCarousel = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery">
      <Header />
      <div className="container">
        <div className="row">
          <div className="gallery-section">
            <h1 className="gallery-heading">{t("GalleryHead")}</h1>

            {isLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : galleries?.length === 0 ? (
              <div className="head-tbox-no-data">
                <h4>{t("GalleryNoData")}</h4>
              </div>
            ) : (
              <div className="gallery-content d-flex flex-wrap">
                {galleries?.map((item, index) => (
                  <div
                    key={index}
                    className="details col-md-3 d-flex flex-column"
                    onClick={() => openCarousel(baseURL + item?.img)}
                  >
                    <div className="image-section">
                      <img src={baseURL + item?.img} alt="image" />
                    </div>

                    <div className="painting-details d-flex justify-content-between">
                      <p className="name">
                        {i18n?.language === "en"
                          ? item?.title?.en
                          : item?.title?.fr}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Load More */}
            <LoadMore
              limit={limit}
              setOffset={setOffset}
              data={galleries}
              checkLength={checkLength}
            />
          </div>
        </div>
      </div>
      <Footer />

      {/* Carousel Modal */}
      <GalleryLightBox
        images={galleries}
        selectedImage={selectedImage}
        onClose={closeCarousel}
        baseURL={baseURL}
        limit={limit}
        setOffset={setOffset}
        checkLength={checkLength}
      />
    </div>
  );
}

export default Gallery;
