import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./AdminLayout.css";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import { logOut, useScreenWidth } from "../../Services/Utils/Utils";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

function AdminLayout() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useScreenWidth();
  const path = location?.pathname;
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <div className={ i18n.language != "fr" ? "admin-layout" : "admin-layout admin-layout-fr" }>
      <div className="">
        <div className="row">
          <div className={openSidebar || screenWidth <= 500 ? "sidebar col-md-2" : "col-md-2"}>
            <div className="left">
              { screenWidth >= 500 ?
                <div className="admin-logo d-flex align-items-center">
                  <Link to="/">
                    <img src="/assets/images/dashboard-logo-upd.png" alt="image" />
                  </Link>
                  <MenuIcon
                    onClick={() => {
                      setOpenSidebar(!openSidebar);
                    }}
                  /> 
                  </div>
                : <div className="mt-4"/> 
              }
              {/* Navigation for admin section */}
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src="/assets/images/dashboard-hover1.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon1.png" alt="image" />
                    <p>{t("Home")}</p>
                  </Link>
                </li>

                <li className={path == "/user/profile" ? "active" : null} >
                  <Link to="/user/profile">
                    <img
                      src="/assets/images/dashboard-hover2.png"
                      alt="image"
                      className="hover-image" 
                    />
                    <img src="/assets/images/dashboard-icon2.png" alt="image" />
                    <p>{t("Profile")}</p>
                  </Link>
                </li>

                <li className={path == "/user/portfolio" ? "active" : null} >
                  <Link to="/user/portfolio">
                    <img
                      src="/assets/images/dashboard-hover3.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon3.png" alt="image" />
                    <p>{t("Portfolio")}</p>
                  </Link>
                </li>

                <li className={
                    path == "/user/market"
                      ? "active"
                      : path == "/user/market/primary-market"
                      ? "active"
                      : null
                  } >
                  <Link to="/user/market">
                    <img
                      src="/assets/images/dashboard-hover4.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon4.png" alt="image" />
                    <p>{t("Market")}</p>
                  </Link>
                </li>

                <li className={path == "/user/parts" ? "active" : null} >
                  <Link to="/user/parts">
                    <img
                      src="/assets/images/dashboard-hover5.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon5.png" alt="image" />
                    <p>{t("MyParts")}</p>
                  </Link>
                </li>

                <li className={path == "/user/withdraw" ? "active" : null} >
                  <Link to="/user/withdraw">
                    <img
                      src="/assets/images/dashboard-hover6.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon6.png" alt="image" />
                    <p>{t("Withdraw")}</p>
                  </Link>
                </li>

                <li className={path == "/user/history" ? "active" : null} >
                  <Link to="/user/history">
                    <img
                      src="/assets/images/dashboard-hover7.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon7.png" alt="image" />
                    <p>{t("History")}</p>
                  </Link>
                </li>

                <li>
                  <Link to="#" onClick={() => logOut(navigate)}>
                    <img
                      src="/assets/images/dashboard-hover8.png"
                      alt="image"
                      className="hover-image"
                    />
                    <img src="/assets/images/dashboard-icon8.png" alt="image" />
                    <p>{t("Logout")}</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className={openSidebar || screenWidth <= 500  ? "sidebar col-md-10" : "col-md-10"}>
            <div className="right">
              <AdminHeader />
              {/* Outlet to show admin pages */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
