import React, { useEffect, useState } from "react";
import { loginUser } from "../../../Services/Api/Api";
import { GoogleLogin, FacebookLogin } from "../../../Services/Firebase/FirebaseAuth";
import { scrollTop, userAuthData, remEmail, errMess, valLangChange } from "../../../Services/Utils/Utils";
import { Form, Input, Checkbox as AntCheckbox, notification } from "antd";
import { Button, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Carousal from "../../../Components/Carousal/Carousal";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setRefreshToken, setToken } from "../../../Services/Store/Store";
import "./Login.css";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [remMe, setRemMe] = useState(false);

  useEffect(() => {
    scrollTop();
    remEmail(form, setRemMe)
  }, [t]);

  useEffect(() => {
    valLangChange(form)
  }, [i18n.language]);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await loginUser(values);
    const { status } = result || {};
    const { access_token, refresh_token } = result?.data || {}
    if (status === 200) {
      dispatch(setToken(access_token));
      dispatch(setRefreshToken(refresh_token));
      userAuthData(result, values, i18n, setLoading, navigate, remMe, setLoading);
    } else {
      setLoading(false);
      notification.error({ message: errMess(result) });
    }
  };

  return (
    <div className={i18n.language != "fr" ? "login" : "login login-fr"}>
      <Header />
      <div className="container">
        <div className="login-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>

          <div className="col-md-6">
            <div className="login-section">
              <h1 className="heading">{t("LoginHead")}</h1>
              <p className="description">{t("LoginSubHead")}</p>

              <div>
                <Form
                  form={form}
                  className="login-form"
                  onFinish={onFinish}
                >
                  <div className="email">
                    <label>{t("LoginEmailLabel")}</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: t("LoginInvalidEmail"),
                        },
                        {
                          required: true,
                          message: t("LoginValBlank"),
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder= {t("LoginEmailPH")}
                        size="large"
                      />
                    </Form.Item>
                  </div>

                  <div className="password">
                    <label>{t("LoginPassLabel")}</label>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t("LoginValBlank"),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder= {t("LoginPassPH")}
                        size="large"
                      />
                    </Form.Item>
                  </div>

                  <div className="login-forgot-checkbox d-flex align-items-center justify-content-between">
                    <div>
                      <AntCheckbox checked={remMe} onChange={(e)=>setRemMe(e.target.checked)}>
                        {t("LoginRemember")}
                      </AntCheckbox>
                    </div>
                    <div className="d-flex">
                      <Link className="forgot text-right" to="/forgot">
                        {t("LoginForgotPass")}
                      </Link>
                    </div>
                  </div>

                  <div className="login-button">
                    <Button type="submit" variant="contained" color="primary" disabled={typeof loading === 'boolean' ? loading : false}>
                      {loading ? (
                        <CircularProgress
                          disableShrink
                          size={24}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <>
                          <p>{t("LoginSubmitButton")}</p>
                          <img src="assets/images/auth/arrow.png" alt="image" />
                        </>
                      )}
                    </Button>
                  </div>
                </Form>

                <div className="continue-with d-flex align-items-center justify-content-center">
                  <span />
                  <p>{t("LoginOrConWith")}</p>
                  <span />
                </div>

                <div className="google-facebook d-flex align-items-center justify-content-center">
                  <Button variant="text" color="primary" onClick={() => {GoogleLogin(navigate, i18n)} }>
                    <img src="assets/images/auth/google.png" alt="image" />
                  </Button>
                  {/* <Button
                    variant="text"
                    color="primary"
                    onClick={() => {FacebookLogin(navigate, i18n)} }
                  >
                    <img
                      src="assets/images/auth/facebook.png"
                      alt="image"
                      className="fb-icon"
                    />
                  </Button> */}
                </div>

                <div className="register-section d-flex align-items-center justify-content-between">
                  <p>{t("LoginDontHaveAcc")}</p>
                  <Link to="/register">
                    <Button color="primary" varient="text">
                      {t("LoginWantToRegister")}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
