import React from 'react'
import "./HomeInvest.css"
import { useTranslation } from "react-i18next";


function HomeInvest() {
  const { t, i18n } = useTranslation();
  
  return (
    <div className={i18n.language != 'fr' ? "home-invest" : "home-invest home-invest-fr"}>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>

                    <div className='head-tbox'>
                        <h2>{t("HomeInvestHeading")}</h2>
                    </div>

                    <div className='invest-wrapper'>

                        <div className='invest-box'>
                            <h3>1</h3>
                            <p>{t("HomeInvestStep1")}</p>
                        </div>

                        <div className='invest-box'>
                            <h3>2</h3>
                            <p>{t("HomeInvestStep2")}</p>
                        </div>

                        <div className='invest-box'>
                            <h3>3</h3>
                            <p>{t("HomeInvestStep3")}</p>
                        </div>

                        <div className='invest-box'>
                            <h3>4</h3>
                            <p>{t("HomeInvestStep4")}</p>
                        </div>

                        <div className='invest-box'>
                            <h3>5</h3>
                            <p>{t("HomeInvestStep5")}</p>
                        </div>

                    </div>

                    <h4>{t("HomeInvestPara1")}</h4>
                    <p>{t("HomeInvestPara2")}</p>
                    <p className='artist-word'>{t("InvestArtistWord")}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeInvest