import React, { useEffect, useState } from "react";
import "./About.css";
import { scrollTop } from "../../Services/Utils/Utils";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import AboutBanner from "../../Components/AboutBanner/AboutBanner";
import AboutPrimary from "../../Components/AboutPrimary/AboutPrimary";
import AboutTeams from "../../Components/AboutTeams/AboutTeams";
import { useTranslation } from "react-i18next";
import HomeBlogs from "../../Components/HomeBlogs/HomeBlogs";
import { getMarketPaintings } from "../../Services/Api/Api";

function About() {
  const currency = localStorage.getItem("currency");
  const { t, i18n } = useTranslation();
  const [primaryPainting, setPrimaryPainting] = useState({});
  const [baseURL, setBaseURL] = useState(null);
  const [curr, setCurr] = useState(currency);

  useEffect(() => {
    scrollTop();
    getDataPrimary();
  }, []);
  // Get Primary-Painting Data
  const getDataPrimary = async () => {
    const result = await getMarketPaintings(1, 0, "primary", curr);
    const { data, status, error } = result || {};
    if (status === 200) {
      setBaseURL(data?.base_url);
      setPrimaryPainting(data?.paintings[0]);
    } else {
      console.log("Internal server error");
    }
  };

  useEffect(() => {
    if (curr && primaryPainting?._id) {
      getDataPrimary();
    }
  }, [curr]);

  return (
    <div>
      <Header setCurr={setCurr} />

      <div className="about">
        <AboutBanner
          primaryPainting={primaryPainting}
          baseURL={baseURL}
        />
        <AboutPrimary
          primaryPainting={primaryPainting}
          baseURL={baseURL}
        />

        <section className="sect3">
          <div className="container">
            <div className="row">
              <div className="head-tbox">
                <h2>{t("AboutSect3Heading")}</h2>
              </div>

              <div className="textBox-wrapper">
                <div className="imageBox">
                  <img src="/assets/images/aboutExcellence1.png" alt="" />
                </div>

                <div className="textBox">
                  <h3>{t("AboutSect3TextBox1Head")}</h3>
                  <p>{t("AboutSect3TextBox1Para")}</p>
                </div>
              </div>
            </div>

            <div className="row bottom">
              <div className="textBox-wrapper">
                <div className="textBox">
                  <h3>{t("AboutSect3TextBox2Head1")}</h3>
                  <h5>{t("AboutSect3TextBox2SubHead1")}</h5>
                  <p>{t("AboutSect3TextBox2Para1")} </p>

                  <h3>{t("AboutSect3TextBox2Head2")}</h3>
                  <h5>{t("AboutSect3TextBox2SubHead2")}</h5>
                  <p>{t("AboutSect3TextBox2Para2")} </p>
                </div>

                <div className="imageBox">
                  <img src="/assets/images/aboutExcellence2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sect4">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="video-wrapper">
                  <h2>{t("AboutVideoHead")}</h2>
                  <img src="/assets/images/about-video.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <AboutTeams />
        <HomeBlogs />
      </div>

      <Footer />
    </div>
  );
}

export default About;
