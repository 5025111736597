import React from 'react'
import "./HomeWorks.css"
import { useTranslation } from "react-i18next";

function HomeWorks() {
  const { t, i18n } = useTranslation();

  return (
    <div className={i18n.language != "fr" ? "home-works" : 'home-works home-works-fr'}>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>

                    <div className='head-tbox'>
                        <h2>{t("HomeWorksHeading")}</h2>
                    </div>

                    <div className='works-wrapper'>
                        <ul>
                            <li>{t("HomeWorksList1")}</li>
                            <li>{t("HomeWorksList2")}</li>
                            <li>{t("HomeWorksList3")}</li>
                            <li>{t("HomeWorksList4")}</li>
                            <li>{t("HomeWorksList5")}</li>
                            <li>{t("HomeWorksList6")}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeWorks