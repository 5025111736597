import React, { useEffect, useState } from "react";
import "./MarketSlider.css";
import { useNavigate, Link } from "react-router-dom";
import { userData, scrollTop } from "../../Services/Utils/Utils";
import LineCharts from "../Charts/LineCharts";
import BuyDrawer from "../BuyDrawer/BuyDrawer";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { Button, LinearProgress } from "@mui/material";
import ReadMoreModal from "../Modals/ReadMoreModal/ReadMoreModal";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

function MarketSlider({ primaryPainting, baseURL, path }) {
  const navigate = useNavigate();
  const { token } = userData();
  const { t, i18n } = useTranslation();
  const progressPercentage = Math.round(
    (primaryPainting?.sold_out_part / primaryPainting?.total_part) * 100
  );
  const [images, setImages] = useState([]);

  useEffect(() => {
    const {cover_img, images} = primaryPainting || {}
    if (images !== undefined || null && baseURL) {
      const newArr = [];
      if (cover_img) {
        newArr?.push({
          original: baseURL + cover_img,
          thumbnail: baseURL + cover_img,
        });
      }
      if (images && Array.isArray(images)) {
        images?.forEach((img) => {
          newArr?.push({
            original: baseURL + img,
            thumbnail: baseURL + img,
          });
        });
      }
      // const newArr = images.map((image) => ({
      //   original: baseURL + image,
      //   thumbnail: baseURL + image,
      // }));
      setImages(newArr);
      setSelectedImage(newArr[currentIndex]?.original);
    }
  }, [primaryPainting, baseURL]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(
    images[currentIndex]?.original
  );

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    setSelectedImage(images[index]?.original);
  };

  // Drawer Open
  const [openDraw, setOpenDraw] = useState(false);

  const showDrawer = () => {
    if (token) {
      setOpenDraw(true);
    } else {
      navigate("/login");
    }
  };

  const onClose = () => {
    setOpenDraw(false);
  };

  // Read More Modal
  const [readMoreModal, setReadMoreModal] = useState(false);
  const handleReadMore = () => {
    setOpenDraw(false);
    setReadMoreModal(true);
  };

  return (
    <div className="marketslider">
      <div className="container">
        <div className="row">
          <div className="selected-image col-md-8">
            {/* <span className="top">{primaryPainting?.dimension?.width.toFixed(2)} cm</span>
            <span className="left">
              {primaryPainting?.dimension?.height.toFixed(2)} cm
            </span> */}
            <img
              src={selectedImage}
              alt="Selected Image"
              className="image-transition"
              loading="lazy"
            />
          </div>

          <div className="top-right col-md-4">
            <div className="primary-box">
              <div className="first">
                <Link to={`/artists/details/${primaryPainting?.artist?.id}`}  state={{ path: "artist" }}>
                  <img
                    src={baseURL + primaryPainting?.artist?.img}
                    alt="cover-image"
                  />
                </Link>
                <div className="info-box">
                  <Link to={`/artists/details/${primaryPainting?.artist?.id}`}  state={{ path: "artist" }}>
                    <h4>
                        {primaryPainting?.artist?.name}
                    </h4>
                    <p> 
                      {dayjs(primaryPainting?.artist?.life_dates?.date_of_birth).format("YYYY")} {
                        primaryPainting?.artist?.life_dates?.date_of_death !== ""  ? " - " +
                        dayjs(primaryPainting?.artist?.life_dates?.date_of_death).format( "YYYY") : ""}
                    </p>
                  </Link>
                </div>
              </div>
              <div className="second">
                <h3>
                  {i18n?.language === "en"
                    ? primaryPainting?.title?.en
                    : primaryPainting?.title?.fr}{" "}
                  <span>({dayjs(primaryPainting?.year).format("YYYY")})</span>
                </h3>
                <div className="second-box">
                    <h4>{primaryPainting?.price_per_part?.value} {primaryPainting?.price_per_part?.curr === "USD" ? "$" : "€"} <span className="last">/Part</span></h4>
                    <div>
                        {primaryPainting?.percentage !=0 ? (
                          primaryPainting?.percentage >= 0 ? 
                          <p className="increament">+{primaryPainting?.percentage} %</p>
                          : 
                          <p className="decreament">{primaryPainting?.percentage} %</p>
                          ) : null
                        }
                    </div>
                </div>
              </div>
              <div className="third">
                <LineCharts details="buy" Chartdata={primaryPainting?.evolution_list} />
              </div>
              <div className="fourth">
                <p>
                  {i18n?.language === "en"
                    ? primaryPainting?.description?.en?.substring(0, 100)
                    : primaryPainting?.description?.fr?.substring(0, 100)}

                  {primaryPainting?.description?.en?.substring(0, 100) ||
                  primaryPainting?.description?.fr?.substring(0, 100) ? (
                    <span
                      className="read-more"
                      onClick={() => handleReadMore()}
                    >
                      ... Read More
                    </span>
                  ) : null}
                </p>
              </div>
              <div className="progress-box">
                <h4>
                  {" "}
                  {primaryPainting?.sold_out_part} /
                  {primaryPainting?.total_part} Parts ({progressPercentage} %)
                </h4>
                <LinearProgress
                  variant="determinate"
                  value={progressPercentage}
                  sx={{
                    height: "17px",
                    width: "100%",
                    color: "#57B251",
                    backgroundColor: "#CCCCCC",
                  }}
                />
              </div>
              <Button
                onClick={showDrawer}
                disabled={
                  token
                    ? (primaryPainting?.market == 1) ||
                      (primaryPainting?.sold_out_part === primaryPainting?.total_part)
                    : false
                }
                className="detail-btn"
              >
                {t("PrimaryMarketBuyNowButton")}
              </Button>
            </div>
          </div>
        </div>

        <div className="image-container">
          {/* React Image Gallery */}
          <ReactImageGallery
            items={images}
            thumbnailPosition="bottom"
            infinite={false}
            showPlayButton={false}
            showIndex={false}
            itemsPerRow={4}
            showFullscreenButton={false}
            onSlide={handleThumbnailClick}
            currentIndex={currentIndex}
            renderLeftNav={(onClick, disabled) => (
              <CustomLeftArrow onClick={onClick} disabled={disabled} />
            )}
            renderRightNav={(onClick, disabled) => (
              <CustomRightArrow onClick={onClick} disabled={disabled} />
            )}
          />
        </div>
      </div>

      {/* Buy Drawer - For Primary and Secondary Paintings*/}
      <BuyDrawer
        onClose={onClose}
        open={openDraw}
        primaryPainting={primaryPainting}
        path={path}
        baseURL={baseURL}
      />

      {/* Read More Modal - Primary and Secondary Paintings */}
      <ReadMoreModal
        onClose={setReadMoreModal}
        visible={readMoreModal}
        primaryPainting={primaryPainting}
        baseURL={baseURL}
      />
    </div>
  );
}

const CustomRightArrow = ({ onClick, disabled }) => (
  <button
    className={`image-gallery-custom-arrow image-gallery-custom-arrow-right ${disabled ? "disabled" : ""
      }`}
    onClick={onClick}
    aria-label="Next Slide"
  >
    <span>
      <EastIcon />
    </span>
  </button>
);

const CustomLeftArrow = ({ onClick, disabled }) => (
  <button
    className={`image-gallery-custom-arrow image-gallery-custom-arrow-left ${disabled ? "disabled" : ""
      }`}
    onClick={onClick}
    aria-label="Previous Slide"
  >
    <span>
      <WestIcon />
    </span>
  </button>
);
export default MarketSlider;
