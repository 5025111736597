import React from "react";
import { Modal } from "antd";
import PayPalButtonComponent from "../PayPalButtonComponent";

function PaypalModal({ visible, onClose, setPaymentType, perPartsItems, refId, onCloseDrawer, perParts, paintingID }) {
  return (
    <Modal
      className="success-modal"
      open={visible}
      onCancel={() => { onClose(false); setPaymentType(""); }}
      centered={true}
      closable={true}
      width={620}
      footer={[]}
      destroyOnClose
    >
      <div className="success">
        <div className="container mt-4">
          <div className="row">
            <div className="success-box">
              <PayPalButtonComponent 
                paintingID={paintingID} 
                perParts={perParts} 
                perPartsItems={perPartsItems} 
                refIdPainting={refId} 
                onClose={onClose} 
                onCloseDrawer={onCloseDrawer} 
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PaypalModal