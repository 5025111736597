import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { orderPaintings, getProfile } from "../../Api/Api";
import { notification } from "antd";
import { useNavigate } from "react-router";

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

function PayPalButtonComponent({
  perPartsItems,
  refIdPainting,
  onClose,
  onCloseDrawer,
  perParts,
  paintingID,
}) {
  const navigate = useNavigate();
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [load, setLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [curr, setCurr] = useState("");
  const calculatedAmount = (perPartsItems * perParts).toFixed(2);

  useEffect(() => {
    getProfileData();
  }, []);

  // Get Profile Data for Updated Currency
  const getProfileData = async () => {
    const result = await getProfile();
    const { data, status, error } = result || {};
    if (status === 200) {
      if (data && data?.curr) {
        const mod_curr = data?.curr === "EURO" ? "EUR" : "USD";
        setCurr(mod_curr);
      }
    } else {
      console.log("Internal server error");
    }
  };

  // Render paypal modal only if curr is present
  if (!curr) {
    return null;
  }

  const paypalOptions = {
    "client-id": clientId,
    currency: curr,
    "disable-funding": "paylater",
    // intent: "capture",
  };

  const handleOnApprove = async (data, actions, refIdPainting) => {
    setLoad(true);
    const captureDetails = await actions.order.capture();
    if (captureDetails?.status === "COMPLETED") {
      const orderResult = await orderPaintings(
        refIdPainting,
        captureDetails?.id,
        captureDetails,
        "paypal"
      );
      if (orderResult) {
        setLoad(false);
        setIsPaymentSuccess(true);
        setTimeout(() => {
          onClose(false);
          onCloseDrawer();
          navigate("/");
          notification.success({
            message: "Congratulations! Your parts purchase is complete",
            placement: "top",
          });
        }, 3000);
      }
    }

    // const customId = data.order.id;
    // const payerInfo = data.payer;
    // const { name, email_address } = payerInfo;
  };

  const handleOnError = (err) => {
    setErrorMessage(
      "An error occurred during the payment process. Please try again."
    );
    console.log("PayPal Error:", err);
  };

  return (
    <div>
      <PayPalScriptProvider options={paypalOptions}>
        {isPaymentSuccess || errorMessage ? null : (
          <PayPalButtons
            className="custom-paypal-s"
            style={{ layout: "vertical" }}
            forceReRender={[calculatedAmount]}
            disabled={load}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: curr,
                      value: calculatedAmount,
                    },
                    custom_id: refIdPainting,
                  },
                ],
                intent: "capture",
              });
            }}
            onApprove={(data, actions) =>
              handleOnApprove(data, actions, refIdPainting)
            }
            onError={handleOnError}
          />
        )}
      </PayPalScriptProvider>

      {isPaymentSuccess && (
        <div>
          <h2>Payment Successful!</h2>
          <p>Payment is done successfully</p>
        </div>
      )}

      {errorMessage && (
        <div>
          <h2>Error</h2>
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default PayPalButtonComponent;
