import React, { useState } from "react";
import "./AboutTeams.css";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const teamData = [
  {
    name: "Flore Durand",
    role: "Communication",
    image: "/assets/images/About-Team1.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Sarah Lamine",
    role: "Communication",
    image: "/assets/images/About-Team2.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Cristian Mocanu",
    role: "Communication",
    image: "/assets/images/About-Team3.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Me Laurent Hugelin",
    role: "Communication",
    image: "/assets/images/About-Team4.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Me Marine Lebihan",
    role: "Communication",
    image: "/assets/images/About-Team5.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Stéphane Ruiz",
    role: "Communication",
    image: "/assets/images/About-Team6.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Enora Budet",
    role: "Communication",
    image: "/assets/images/About-Dummy.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Jérôme Gascoin",
    role: "Communication",
    image: "/assets/images/About-Team7.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },

  {
    name: "Gaston Moluka",
    role: "Communication",
    image: "/assets/images/About-Dummy.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
  {
    name: "Jean-Louis William",
    role: "Communication",
    image: "/assets/images/About-Dummy.png",
    moreInfo:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here".',
  },
];

function AboutTeams() {
  const { t, i18n } = useTranslation();

  const collaboratersData = [
    {
      name: "Souheil Skander",
      role: t("AboutCollaboraters1"),
      image: "/assets/images/collaboraters1.png",
    },

    {
      name: "Ahmed Hodieb",
      role: t("AboutCollaboraters2"),
      image: "/assets/images/collaboraters2.png",
    },

    {
      name: "Steve Levy",
      role: t("AboutCollaboraters3"),
      image: "/assets/images/collaboraters3.png",
    },
    {
      name: "Emmanuel Huynh",
      role: t("AboutCollaboraters4"),
      image: "/assets/images/collaboraters4.png",
    },
    {
      name: "Mourad Ben Chaabane",
      role: t("AboutCollaboraters5"),
      image: "/assets/images/collaboraters5.png",
    },
    {
      name: "Khaled El Sayyad",
      role: t("AboutCollaboraters6"),
      image: "/assets/images/collaboraters6.png",
    },
    {
      name: "Me Omar Ferchiou",
      role: t("AboutCollaboraters7"),
      image: "/assets/images/collaboraters7.png",
    },
    {
      name: "Dr Karim Fekih",
      role: t("AboutCollaboraters8"),
      image: "/assets/images/collaboraters8.png",
    },
    {
      name: "Eric Eludut",
      role: t("AboutCollaboraters9"),
      image: "/assets/images/collaboraters9.png",
    },
    {
      name: "Paul Charlot",
      role: t("AboutCollaboraters10"),
      image: "/assets/images/collaboraters10.png",
    },
  ];

  const [isOpen, setIsOpen] = useState(Array(teamData.length).fill(false));

  const toggleCollapse = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  return (
    <div className="aboutTeams">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("AboutTeamHead")}</h2>
              <h5>
                <span>{t("AboutTeamSubHeadSpan")}</span> {t("AboutTeamSubHead")}
              </h5>
              <p>
                <span>{t("AboutTeamPara1Span")}</span> {t("AboutTeamPara1")}
              </p>
            </div>

            <div className="teams-wrapper">
              {teamData?.map((member, index) => (
                <div className="team-box" key={index}>
                  <div className="imageBox">
                    <img src={member.image} alt="" />
                  </div>

                  <div className="box">
                    <div className="info-box">
                      <h3>{member.name}</h3>
                      <p>{member.role}</p>
                    </div>
                    {/* Toggle "more" and "less" images based on collapse state */}
                    <img
                      onClick={() => toggleCollapse(index)}
                      src={
                        isOpen[index]
                          ? "/assets/images/about-readLess.png"
                          : "/assets/images/about-readMore.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="more-box">
                    <Collapse in={isOpen[index]}>
                      <p>{member.moreInfo}</p>
                    </Collapse>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="collaboratersBox">
              <div className="head-tbox">
                <p>
                  <span>{t("AboutTeamPara2Span")}</span> {t("AboutTeamPara2")}
                </p>
              </div>

              <div className="teams-wrapper">
                {collaboratersData.map((member, index) => (
                  <div className="team-box" key={index}>
                    <div className="imageBox">
                      <img src={member.image} alt="" />
                    </div>

                    <div className="box">
                      <div className="info-box">
                        <h3>{member.name}</h3>
                        <p>{member.role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutTeams;
