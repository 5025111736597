import React from "react";
import "./HomeExplore.css";
import { useTranslation } from "react-i18next";

function HomeExplore({ home, baseUrl }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-explore">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="explore-content">
              <h3>
                WE<b>ART</b>
              </h3>
              <p>{t("ExploreLeftPara1")}</p>
              <span>{t("ExploreLeftSpan")}</span>
              <p className="bottom-para">{t("ExploreLeftPara2")}</p>
            </div>
          </div>

          <div className="col-md-4 image-col-s">
            <div className="explore-image">
              <img src="/assets/images/weArtInfo-img.png" alt="" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="explore-content">
              <h3>
                WE<b>ART</b>
              </h3>
              <p>{t("ExploreRightPara1")}</p>
              <span className="right-span">{t("ExploreRightSpan")}</span>
              <p className="bottom-para">{t("ExploreRightPara2")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeExplore;
