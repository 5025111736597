import React from "react";
import { Table, Spin } from "antd";
import { Button } from "@mui/material";
import CustomPagination from "../../../../Components/Pagination/CustomPagination";
import dayjs from "dayjs";

function Sale({ data }) {
  const {
    tableData,
    isLoading,
    page,
    setPage,
    baseURL,
    i18n,
    t,
    handleDownloadData,
  } = data;

  const columns = [
    {
      title: t("SaleIDNo"),
      dataIndex: "reference_id",
      key: "reference_id",
      render: (reference_id) => <div className="reference">{reference_id}</div>,
    },
    {
      title: t("SaleWorks"),
      dataIndex: "painting_title",
      key: "painting_title",
      align: "center",
      width: "370px",
      render: (painting_title, record) => (
        <div className="image-details">
          <div>
            <img src={baseURL + record?.painting_img} alt="image" />
          </div>

          <div>
            <p className="title">
              {i18n?.language === "en"
                ? painting_title?.en
                : painting_title?.fr}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: t("SaleDate"),
      dataIndex: "t",
      key: "t",
      align: "center",
      render: (t) => <div>{dayjs(t).format("DD MMM YYYY")}</div>,
    },
    {
      title: t("SaleDate"),
      dataIndex: "part_listed",
      key: "part_listed",
      align: "center",
    },
    {
      title: t("SalePartPrice"),
      dataIndex: "part_price",
      key: "part_price",
      align: "center",
      render: (part_price) => (
        <div>
          {part_price?.value} {part_price?.curr}
        </div>
      ),
    },
    {
      title: t("SalePartSold"),
      dataIndex: "part_sold",
      key: "part_sold",
      align: "center"
    },
    {
      title: t("SaleSellingAmount"),
      dataIndex: "selling_amount",
      key: "selling_amount",
      align: "center",
      render: (selling_amount) => (
        <div>
          {selling_amount?.value} {selling_amount?.curr}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h1 className="sub-heading">{t("Sale")}</h1>
        <Button
          className="download-button"
          onClick={() => handleDownloadData()}
        >
          <p>{t("Download")}</p>
          <img src="/assets/images/download.png" alt="image" />
        </Button>
      </div>
      <div className="table">
        {isLoading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <div>
            <Table
              pagination={false}
              dataSource={tableData}
              columns={columns}
              rowKey="_id"
            />
            <CustomPagination
              tableLength={tableData?.length < 4}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Sale;
