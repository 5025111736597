import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import { Form, Input, notification } from "antd";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  changePasswordPage,
  resetPasswordPage,
} from "../../../Services/Api/Api";
import Carousal from "../../../Components/Carousal/Carousal";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

function ResetPassword() {
  let { token } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    resetPassPage();
  }, []);

  // Check If Token Is Valid
  const resetPassPage = async () => {
    const result = await resetPasswordPage(token);
    const { data, error, status } = result?.data || {};
    if (status === 200) {
      notification.success({ message: "Enter your email to change password." });
    } else {
      backToLogin();
      notification.error({ message: error?.en });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const result = await changePasswordPage(values, token);
    const { data, error, status } = result?.data || {};
    if (status === 200) {
      setLoading(false);
      setTimeout(() => {
        window.history.replaceState(null, null, "/change-password");
        navigate("/change-password", {
          state: {
            email: values?.email,
            token: token,
          },
        });
        form.resetFields();
      }, 500);
    } else {
      setLoading(false);
      backToLogin()
      notification.error({ message: error?.en });
    }
  };

  // Back To Login
  const backToLogin = async () => {
    window.history.replaceState(null, null, "/login");
    navigate("/login");
  };

  return (
    <div className="reset">
      <Header />
      <div className="container">
        <div className="reset-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>

          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="reset-section">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="main-image"
                  src="/assets/images/auth/reset.png"
                  alt="image"
                />
                <h1 className="heading">Verify Email</h1>
                <p className="description">
                  Enter the email address associated with your account below
                </p>
              </div>
              <div>
                <Form form={form} className="reset-form" onFinish={onFinish}>
                  <div className="email">
                    <label>Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email",
                        },
                        {
                          required: true,
                          message: "You cannot leave this field blank",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder="Enter your email"
                        size="large"
                      />
                    </Form.Item>
                  </div>

                  <div className="reset-section d-flex align-items-center justify-content-between">
                    <Button
                      className="reset-button"
                      type="submit"
                      color="primary"
                      varient="text"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress
                          disableShrink
                          size={24}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <>
                          <p>Verify</p>
                          <img
                            src="/assets/images/auth/arrow.png"
                            alt="arrow"
                          />
                        </>
                      )}
                    </Button>
                    <Button onClick={()=>{backToLogin()}} className="cancel-button" color="primary">
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassword;
