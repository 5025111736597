import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { scrollTop } from "../../Services/Utils/Utils"
import "./Presentation.css";

function Presentation() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="presentation">
      <Header />
      <section className="sect1">
        <div className="container">
          <div className="row first">
            <div className="col-md-6">
              <div className="ct-box">
                <h2>THE ART SCHOLARSHIP</h2>
                <h3>WEART BREAKS CODES AND DEMOCRATIZES ACCESS TO ART</h3>
                <h5>WEART powered by WEEX</h5>
                <p>
                  WEART is based on a major innovation allowing
                  revolutionize the world of art by becoming the first platform
                  in Europe for the collective acquisition of first-rate works.
                </p>
                <p>
                  WEART is aimed at a wide audience of investors and
                  enthusiasts, offering to invest according to their means, in
                  exclusive Art, to take advantage of the success of the
                  greatest painters of recent centuries.
                </p>
                <h4>IMMERSION IN THE UNIVERSE OF "WEART"</h4>
              </div>
            </div>

            <div className="col-md-6">
              <div className="image-box">
                <img
                  src="/assets/images/pres-image1.png"
                  alt=""
                  className="normal fadeIn"
                />
                <img
                  src="/assets/images/pres-image1-hover.png"
                  alt=""
                  className="hover fadeIn"
                />
              </div>
            </div>
          </div>

          <div className="row second">
            <div className="col-md-12">
              <div className="ct-box">
                <h4>Splitting of works and pooling of entry costs</h4>
                <p>
                  An ingenious mechanism making access to the high-end Art
                  market technically possible, by removing barriers to entry and
                  by offering everyone the opportunity to invest in the
                  best-known painters and the most popular works.
                </p>
              </div>
            </div>
          </div>

          <div className="row third">
            <div className="col-md-6">
              <div className="ct-box">
                <h3>For What?</h3>
                <h5>
                  Since the 2000s, the Contemporary Art market has resisted all
                  the crises that followed one another.
                </h5>
                <p>
                  With an average return of 13.8%, Contemporary Art was not only
                  better than investments in the stock market, around 10%, but
                  has also in recent years outperformed safe haven values
                  traditional, such as real estate and gold, at 8.9% and 7.2%
                  respectively. This is why the exclusive Art market,
                  particularly secure, today attracts more and more capital. But
                  the market art, more than 1000 years old, has long been a
                  financial investment profitable, reserved only for the
                  richest. An elitist universe, closed and coveted, which has
                  become much more democratized in recent decades, especially
                  with the advent of the internet.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="ct-box">
                <h3>How?</h3>
                <h5>
                  By purchasing Shares collectively and in legal joint ownership
                  of these works of art.
                </h5>
                <p>
                  WeArt organizes the splitting, then the listing of the
                  Units. There "Coast" is a concept inherited from the Middle
                  Ages, which is universally, become at the heart of the
                  acquisitions of works of art. The coast index o Parts, serves
                  as a reference for the estimation of the works and conditions
                  their transaction values. To this end, it is essential that
                  the coast be the most fair, clear and transparent as possible.
                  As such, stock market processes, articulated around the
                  confrontation of supply and demand, allow the rating to be
                  scientifically defined. An art grant built on evolving coast
                  indices, inspired by the markets; leading to healthy and
                  thoughtful investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ct-box">
                <h3>
                  HIGH-END ART: REFUGE PAR EXCELLENCE, IN A CAPTIVE MARKET
                </h3>
                <p>
                  The Art market today represents 3% of global GDP, reaching
                  more than $2500 billion. It is one of the fastest growing
                  sectors of the global economy and experts argue that the value
                  of this sector will increase by 40% by 2030. Note that between
                  1997 and 2017, i.e. space of 20 years, the international Art
                  market has grown by 456%.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="image-box">
                <img src="/assets/images/pres-image2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect3">
        <div className="container">
          <div className="row first">
            <div className="col-md-12">
              <div className="ct-box">
                <h3>IN SHORT, THE FOLLOWING ARE THE PRINCIPLES</h3>
                <p>
                  Group purchasing or collective purchasing aims to offer as
                  many people as possible to come together to collectively
                  acquire a good; they become owners together, in joint
                  ownership.
                </p>
              </div>

              <div className="gray-ctBox">
                <p>
                  At WeArt, this group of individuals holds in common works
                  of Art, through Parts.These Parts of works of Art,
                  materialized by title deeds, are Assets. There scarcity of
                  these Assets, tangible, limited and non-replicable, coupled
                  stock market mechanisms, allows their individual holder, to
                  potentially claim capital gains from their resales, while
                  offering recurring revenues, in the framework of the rental
                  placements of the works.
                </p>
              </div>

              <div className="ct-box3">
                <p>
                  Indeed, in recent years, the number of museums open around the
                  world has continued to grow. More museums have been open
                  between 2000 and 2014, only during the 19th and 20th century
                  combined. This phenomenon is at the origin of a strong demand
                  for works of art, pushing museums to rent from from third
                  parties, a growing quantity of works. Best parts benefiting
                  their owners, a better rental yield.
                </p>
              </div>
            </div>
          </div>

          <div className="row second">
            <div className="col-md-12">
              <div className="head-tbox">
                <h3>REFERENCE ARTISTS: A STRATEGIC PLACEMENT</h3>
                <h6>Some Features</h6>
              </div>

              <div className="features-wrapper">
                <div className="feature-box">
                  <p>
                    After PICASSO's death, 12 of his paintings were among the 50
                    most expensive artistic works in the world; ranging from $50
                    million for the least expensive work, to more than $200
                    million for the most expensive work.
                  </p>
                  <h4>1991</h4>
                </div>

                <div className="feature-box">
                  <p>
                    WARHOL's portrait of Marilyn Monroe sold for $17.3 million,
                    after it was purchased in 1964 for $1,800.
                  </p>
                  <h4>1998</h4>
                </div>

                <div className="feature-box">
                  <p>
                    BANKSY sold his paintings around 70 €. Today, his even works
                    are sold for €140,000.
                  </p>
                  <h4>2004</h4>
                </div>

                <div className="feature-box">
                  <p>
                    This year alone totals $550 million in painting salesby the
                    Chinese artist DAQIAN, while his works sold 20 years
                    earlier, on average for $5,000.
                  </p>
                  <h4>2011</h4>
                </div>

                <div className="feature-box">
                  <p>
                    A painting by Edward Hopper titled Chop Suey sold for $91.8
                    million. It was purchased for $170,000 in 1973 by Barney
                    Ebsworth, an American businessman and art collector.
                  </p>
                  <h4>2018</h4>
                </div>

                <div className="feature-box">
                  <p>
                    “Art” in the most noble and prestigious sense of the term,
                    is a precious asset and one of the few in the world, which
                    does not depreciate over time, but which on the contrary
                    continues to gain value throughout. of the life cycle of its
                    author and even beyond; evidenced by the expression
                  </p>
                  <h4>2023</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect4">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>

            <div className="col-md-6">
              <div className="ct-box">
                <h2>The artist is dead, long live the artist!</h2>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Presentation;
