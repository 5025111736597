import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousal.css";

function Carousal() {
    
  const mapData = [
    {
      title: "5k+ Satisfied Clients",
      description:
        "Explore the world-famous masterpiece and delve into the enigmatic smile that has fascinated art enthusiasts for centuries.",
      subtitle: "Utilisateur WeArt",
    },
    {
      title: "Art  History Treasure",
      description:
        "Make Mona Lisa a part of your art collection and own a piece of art history that transcends time and captivates the soul.",
      subtitle: "Cherished Beauty",
    },
    {
      title: "Da Vinci's Brilliance",
      description:
        "Leonardo da Vinci's genius shines through Mona Lisa. Dive into the brilliance of the artist and his legendary creation.",
      subtitle: "Timeless Masterpiece",
    },
  ];

  return (
    <div className="auth-carousal">
      <div
        className="image"
        style={{
          backgroundImage: `url(/assets/images/auth/monalisa.png)`,
        }}
      >
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showStatus={false}
          autoPlay={true}
        >
          {mapData.map((item, index) => (
            <div key={index}>
              <p className="title">{item.title}</p>
              <p className="description">{item.description}</p>
              <p className="subtitle">{item.subtitle}</p>
              <img src="" alt="image" className="thumb-image d-none"/>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Carousal;
