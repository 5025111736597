import React from "react";
import { Modal } from "antd";
import StripeElement from "../StripeElement"

function DebitCardModal({ visible, onClose, setPaymentType, perPartsItems, refId, onCloseDrawer, perParts, paintingID, keys, curr }) {
  return (
    <Modal
      className="success-modal"
      open={visible}
      onCancel={() => { onClose(false); setPaymentType(""); }}
      centered={true}
      closable={true}
      width={620}
      footer={[]}
      destroyOnClose
    >
      <div className="success">
        <div className="container mt-4">
          <div className="row">
            <div className="success-box">
              <StripeElement
                paintingID={paintingID} 
                perParts={perParts} 
                perPartsItems={perPartsItems} 
                refIdPainting={refId} 
                onClose={onClose} 
                onCloseDrawer={onCloseDrawer} 
                keys={keys}
                curr={curr}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DebitCardModal