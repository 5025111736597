import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { Button, CircularProgress } from "@mui/material";
import "./StripeElement.css";
import { notification } from "antd";
import { orderPaintings } from "../../Api/Api";
import { useNavigate } from "react-router";

export const CheckoutForm = ({ props }) => {
  const {
    perPartsItems,
    refIdPainting,
    onClose,
    onCloseDrawer,
    perParts,
    paintingID,
    keys,
    curr,
  } = props;
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    try {
      if (keys?.payment_client_secret) {
        // Submit the payment form first
        const submitResult = await elements.submit();
        if (submitResult?.error) {
          setPaymentError(submitResult?.error?.message);
          setLoading(false);
          return;
        } else {
          setPaymentError(null);
        }

        // Confirm the payment with the PaymentIntent
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/`,
          },
          redirect: "if_required",
        });
        // console.log(error);
        // console.log(paymentIntent);
        if (error) {
          setPaymentError(error?.message);
          setLoading(false);
        } else {
          if (paymentIntent?.status === "succeeded") {
            orderCreate(paymentIntent);
          }
        }
      }
    } catch (error) {
      setPaymentError(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stripe && elements) {
      setIsFormLoading(false);
    }
  }, [stripe, elements]);

  // Order Purchase Confirm APi
  const orderCreate = async (paymentIntent) => {
    const orderResult = await orderPaintings(
      refIdPainting,
      paymentIntent?.id,
      paymentIntent,
      "card"
    );
    if (orderResult) {
      setTimeout(() => {
        setLoading(false);
        onClose(false);
        onCloseDrawer();
        navigate("/");
        notification.success({
          message: "Congratulations! Your parts purchase is complete",
          placement: "top",
        });
      }, 3000);
    }
  };

  return (
    <div className="stripe">
      {isFormLoading ? (
        <div className="d-flex justify-content-center align-item-center mb-5">
          <CircularProgress style={{ color: "#333333ec" }} size={30} />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <PaymentElement 
            id="payment-element"
            options={{ hidePostalCode: true }} 
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!stripe || loading}
            className="pay-button"
          >
            {loading ? (
              <CircularProgress
                disableShrink
                size={24}
                sx={{ color: "white" }}
              />
            ) : (
              `Pay ${curr}${perParts * perPartsItems}`
            )}
          </Button>
          
          { paymentError && 
           <p className="error">{paymentError}</p>
          } 
         
        </form>
      )}
    </div>
  );
};

function StripeElement(props) {
  // Only render Stripe elements when publicKey is available
  const { stripe_publishable_key, payment_client_secret } = props?.keys || {};
  const renderStripeElement = () => {
    if (!stripe_publishable_key || !payment_client_secret) {
      return (
        <div className="d-flex justify-content-center align-item-center mb-5">
          <CircularProgress style={{ color: "#333333ec" }} size={30} />
        </div>
      );
    }

    const stripePromise = loadStripe(stripe_publishable_key);
    return (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: payment_client_secret }}
      >
        <CheckoutForm props={props} />
      </Elements>
    );
  };

  return <>{renderStripeElement()}</>;
}

export default StripeElement;