import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop,
} from "recharts";
import dayjs from "dayjs";
import { schemeAccent, schemeCategory10, schemeDark2 } from "d3-scale-chromatic";
import { generateColors } from "../../Services/Utils/Utils";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const uniqueID="##R3T##%@*G4&77R"

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload?.length) {
    const date = dayjs(label)?.format("DD MMM YYYY");
    return (
      <div className="custom-tooltip">
         <div style={{ backgroundColor: 'black', color: 'white', padding: '8px', fontFamily: "Red Hat Display"}}>
          <p className="label" style={{ fontSize:16 }}>{date}</p>
          {payload?.map((entry, index) => {
            const paintingTitle = entry?.name?.split("_")[0];
            return (
            <div className="d-flex" key={index}>
              <div>
                <FiberManualRecordIcon sx={{fontSize:12, color: entry?.stroke}}/>
              </div>
               <p style={{ fontSize:15, paddingLeft:5, textTransform:"capitalize"}}>
                {paintingTitle?.split(uniqueID)[1]}:
              </p>
              <p style={{ fontSize:15, paddingLeft:5}}>
                {entry?.value} {entry.payload?.curr}
              </p>
            </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

// Evolution Grpah for Paintings (Admin - MyParts)
const MultiLineChartAdminMyParts = ({ graph }) => {
  const { i18n } = useTranslation();
  
  // Generate Custom Colors Hexcode based on Graph Length
  const customColorArr = generateColors(graph?.length || []);
  const combinedColors = [ ...schemeCategory10, ...schemeDark2, ...schemeAccent, ...customColorArr];

  const convertToRechartsData = (apiResponse) => {
    const rechartsData = [];
    apiResponse?.forEach((item, index) => {
      item?.graph_lines?.forEach((line) => {
        let entry = rechartsData?.find(
          (data) =>
            dayjs(data?.date).format("DD MM YYYY") ===
            dayjs(line?.date).format("DD MM YYYY")
        );
        if (!entry) {
          entry = { date: dayjs(line?.date).format("DD MMM YYYY") };
          rechartsData?.push(entry);
        }
        entry[`${(index+1)+uniqueID+(i18n?.language === "en" ?item?.painting_title?.en : item?.painting_title?.fr)}`] = line?.prices?.value;
        entry[`curr`] = line?.prices?.curr
      });
    });
    return rechartsData;
  };

  const rechartsData = convertToRechartsData(graph);
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={rechartsData}
        margin={{ top: 25, right: 20, left: 0, bottom: 0 }}
      >
        <defs>
          {graph?.map((item, index) => (
            <linearGradient
              key={index}
              id={`gradient${index}`}
              x1="0%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop
                offset="0%"
                stopColor={combinedColors[index]}
                stopOpacity={0.05}
              />
              <stop
                offset="100%"
                stopColor={combinedColors[index]}
                stopOpacity={0.15}
              />
            </linearGradient>
          ))}
        </defs>

        <XAxis
          dataKey="date"
          tickCount={10}
          axisLine={true}
          tickLine={false}
        />
        <YAxis
          interval="preserveStart"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => {
            if (value >= 1000000) {
              return `${value / 1000000}M`;
            } 
             else {
              return value;
            }
          }}
          // tickCount={11}
        />
        {graph?.map((item, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={`${(index+1)+uniqueID+(i18n?.language === "en" ? item?.painting_title?.en : item?.painting_title?.fr)}` }
            stroke={combinedColors[index]}
            fill={`url(#gradient${index})`}
            activeDot={{ r: 5 }}
            dot={{ stroke: combinedColors[index], strokeWidth: 3, r:  1.5, fill: combinedColors[index] }}
            connectNulls
          />
        ))}
       <Tooltip content={<CustomTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MultiLineChartAdminMyParts;