import React, { useEffect, useState } from "react";
import "./Newsletter.css";
import { Form, Input, notification } from "antd";
import { waitList } from "../../Services/Api/Api";
import { succMess, errMess  } from "../../Services/Utils/Utils";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Newsletter({ type }) {
  const storedData = useSelector((state) => state?.footerData);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [footerData, setFooterData] = useState({});
  const { facebook, google } =
    footerData?.siteSettings?.social_media_links || {};

  useEffect(() => {
    if (storedData) {
      setFooterData(storedData);
    }
  }, [storedData]);

  // Submit WaitList Email
  const onFinish = async (values) => {
    setLoading(true);
    const result = await waitList(values, type);
    const { data, status, error } = result || {};
    if (status === 200) {
      setLoading(false);
      form.resetFields();
      notification.success({ message: succMess(result) });
    } else {
      setLoading(false);
      notification.error({ message: errMess(result)});
    }
  };

  return (
    <div className="newsletter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Form form={form} onFinish={onFinish}>
              <Form.Item name="email">
                <Input
                  type="email"
                  placeholder={t("NewsletterEmailPlaceholder")}
                  size="large"
                  required
                />
              </Form.Item>

              <Button type="submit" variant="contained" color="primary">
                {loading ? (
                  <CircularProgress
                    disableShrink
                    size={24}
                    sx={{ color: "white" }}
                  />
                ) : (
                  t("NewsletterButton")
                )}
              </Button>
            </Form>

            <span className="or-s">{t("NewsletterOr")}</span>

            <div className="social-links">
              <a target="_blank" href={facebook}>
                <img src="/assets/images/fb-icon.png" alt="image" />
              </a>
              <a target="_blank" href={google}>
                <img src="/assets/images/google-icon.png" alt="image" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
