import React, { useEffect, useState } from "react";
import "./MobileHeader.css";
import { Card, Drawer } from "antd";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";

function MobileHeader({ details }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const [submenuOpen, setSubmenuOpen] = useState(false);

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  return (
    <div className="mobile-menu" id="demo">
      <div className="menuIcon">
        <Button onClick={() => handleOpenDrawer()}>
          <MenuIcon />
        </Button>

        <Drawer
          title=""
          width={300}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          closable={true}
          className="mobile-drawer-s"
        >
          <div className="side-logo">
            <img src="/assets/images/weart-logo-upd.png" alt="" />
          </div>

          <ul>
            <li>
              <Link to="/about" className={path == "/about" ? "active" : null}>
                {t("headerAboutUs")}
              </Link>
            </li>
            <li>
              <Link
                to="/artists"
                className={
                  path == "/artists" || (path == "/details" && details)
                    ? "active"
                    : null
                }
              >
                {t("headerArtists")}
              </Link>
            </li>
            {/* <li>
              <Link
                to="/works"
                className={
                  path == "/works" || (path == "/details" && !details)
                    ? "active"
                    : null
                }
              >
                {t("headerWorks")}
              </Link>
            </li> */}
            <li>
              <Link
                to="/primary-market"
                className={path == "/primary-market" ? "active" : null}
              >
                {t("headerPrimaryMarket")}
              </Link>
            </li>
            <li>
              <Link
                to="/secondary-market"
                className={path == "/secondary-market" ? "active" : null}
              >
                {t("headerSecondaryMarket")}
              </Link>
            </li>
            <li>
              <Link
                to="/gallery"
                className={path == "/gallery" ? "active" : null}
              >
                {t("headerGallery")}
              </Link>
            </li>
          </ul>
        </Drawer>
      </div>
    </div>
  );
}

export default MobileHeader;
