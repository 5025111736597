import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop,
} from "recharts";

const CustomTooltip = ({ active, payload, curr }) => {
  if (active && payload && payload?.length) {
    const data = payload[0]?.payload;

    return (
      <div className="custom-tooltip">
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <span
            style={{
              border: "1px solid white",
              borderRadius: 100,
              height: 20,
              width: 20,
              marginRight: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowDownwardIcon sx={{ fontSize: 16 }} />
          </span> */}
          <span style={{ fontSize: 15 }}>
            {data?.value}
            {curr}
          </span>
          {/* <span style={{ fontSize: 12, marginLeft: 5 }}>
            (+{data?.value * 2}%)
          </span> */}
        </div>
      </div>
    );
  }

  return null;
};

const PortfolioLineChart = ({ graph, curr }) => {
  // Preprocess data to include (0, 0) if there's only a single data point add { month: "", value: 0 }
  const processedData = [{ month: "", value: 0 }, ...graph];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={processedData || []}
        margin={{ top: 10, right: 10, left: 20, bottom: 0 }}
      >
        <defs>
          <linearGradient id="redGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(239, 84, 94, 0.8)" />
            <stop offset="100%" stopColor="rgba(239, 84, 94, 0)" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="month"
          tickCount={10}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          // domain={[0, 100]}
          tickCount={10}
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => {
            if (value >= 1000000) {
              return `${value / 1000000}M`;
            } 
             else {
              return value;
            }
          }}
          // ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          // label={{ value: 'Y-axis Label', position: 'top', dy: -50 }}
        />
        <Tooltip content={<CustomTooltip curr={curr} />} />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#FB2A3A"
          strokeWidth={1.5}
          fill="url(#redGradient)"
          dot={{ stroke: "red", strokeWidth: 2, r: 2.5, fill: "red" }}
          activeDot={{ r: 7 }}
          connectNulls={true}
          // label={{ value: 'X-axis Label', position: 'right', dy: 0 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default PortfolioLineChart;
