import React, { useState } from "react";
import "./Forgot.css";
import { Form, Input, notification } from "antd";
import { Button, CircularProgress } from "@mui/material";
import { forgotPassword } from "../../../Services/Api/Api";
import { Link, useNavigate } from "react-router-dom";
import Carousal from "../../../Components/Carousal/Carousal";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

function Forgot() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await forgotPassword(values);
    const { data, error, status } = result;
    if (status === 200) {
      setLoading(false);
      notification.success({ message: data?.message?.en });
      navigate("/email-confirm", { state: values });
    } else {
      setLoading(false);
      notification.error({ message: error?.en });
    }
  };

  return (
    <div className="forgot">
      <Header />
      <div className="container">
        <div className="forgot-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>

          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="forgot-section">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="main-image"
                  src="assets/images/auth/reset.png"
                  alt="image"
                />
                <h1 className="heading">Reset your password</h1>
                <p className="description">
                  Enter the email address associated with your account below
                </p>
              </div>
              <div>
                <Form className="forgot-form" onFinish={onFinish}>
                  <div className="email">
                    <label>Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email",
                        },
                        {
                          required: true,
                          message: "You cannot leave this field blank",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder="Enter your email"
                        size="large"
                      />
                    </Form.Item>
                  </div>

                  <div className="forgot-section d-flex align-items-center justify-content-between">
                    <Button
                      className="forgot-button"
                      type="submit"
                      color="primary"
                      varient="text"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress
                          disableShrink
                          size={24}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <>
                          <p>Send </p>
                          <img src="assets/images/auth/arrow.png" alt="arrow" />
                        </>
                      )}
                    </Button>
                    <Link to="/login">
                      <Button
                        className="cancel-button"
                        color="primary"
                        varient="text"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Forgot;
