import React from "react";
import { Modal } from "antd";
import "./WithdrawProcessModal.css";
import { Link } from "react-router-dom";

function WithdrawProcessModal({ visible, onClose }) {
  return (
    <Modal
      className="withdraw-process"
      title=""
      open={visible}
      onCancel={() => onClose(false)}
      centered={true}
      closable={true}
      width={620}
      footer={[]}
    >
      <div>
        <div className="container mt-4">
          <div className="row">
            <div className="head-tbox">
              <h2>Purchase of shares</h2>
            </div>
            <div className="purchase-box">
              <img src="/assets/images/Withdraw-Process-Image.png" alt="" />
              <h3>Request in progress</h3>
              {/* <p>
                Your request is being processed Transaction link{" "}
                <Link to="#">#DEP201</Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WithdrawProcessModal;
