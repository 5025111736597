import React, { useEffect, useState } from "react";
import "./Details.css";
import { Tabs, notification, Spin } from "antd";
import { getArtistsDetails, getPaintingDetails } from "../../Services/Api/Api";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { scrollTop, useScreenWidth } from "../../Services/Utils/Utils"
import LineChart from "../../Components/Charts/LineCharts";
import MarketSlider from "../../Components/MarketSlider/MarketSlider";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import dayjs from "dayjs";

function Details() {
  let { artist_id, painting_id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currency = localStorage.getItem("currency");
  const [curr, setCurr] = useState(currency);
  const [artistsDetails, setArtistsDetails] = useState();
  const [paintingDetails, setPaintingDetails] = useState();
  const [baseURL, setBaseURL] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [painting_list, setPaintingList] = useState([]);
  const page = location?.pathname?.includes("/artist");

  useEffect(() => {
    if (!artist_id && !page) {
      navigate("/artists");
    }
  }, []);

  useEffect(() => {
    scrollTop();
    getArtistsData()
    if(painting_id) getPaintingData(painting_id);
  }, []);

  useEffect(() => {
    if (curr && painting_list?.length > 0) {
      setPaintingList([])
      getArtistsData();
    }
    if (curr && paintingDetails && painting_id) {
      setPaintingDetails()
      getPaintingData(painting_id);
    }
  }, [curr]);

  // Get Artist Data For Graph and Its Paintings Listing Data
  const getArtistsData = async () => {
    setLoading(true)
    const result = await getArtistsDetails(artist_id, curr);
    const { data, status, error } = result;
    if (status == 200) {
      setArtistsDetails(data?.artistDetails);
      setPaintingList(data?.artistDetails?.painting_list)
      setBaseURL(data?.base_url)
      setLoading(false)
    } else {
      setLoading(false)
      notification.error({ message: "Internal server error" });
    }
  };

  // Get Works Details Data - To Get Specific Painting Details And Graph Details
  const getPaintingData = async (painting_id) => {
    const result = await getPaintingDetails(painting_id, curr);
    const { data, status, error } = result;
    if (status == 200) {
      setPaintingDetails(data?.paintings)
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  return (
    <div className="details">
      <Header setCurr={setCurr} />
        <div className="container details-container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="heading">
                {t("ArtistsProfileHead")} {page ? t("ArtistsHead") : "Work"}
              </h1>

              <div className="details-chart d-flex flex-column justify-content-between">
                {isLoading ? (
                  <div className="spin">
                    <Spin />
                  </div>
                  ) : (
                  <>
                    <div className="content">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <img
                              src={baseURL + (page ? artistsDetails?.image : paintingDetails?.cover_img)}
                              alt="image"
                            />
                          </div>
                          <div>
                            <p className="name">
                            { page ? (artistsDetails?.name)
                              : 
                              (i18n?.language === "en" ? paintingDetails?.title?.en : paintingDetails?.title?.fr)
                            }
                            </p>
                            <p className="year">
                              {page ?
                              (dayjs(artistsDetails?.life_dates?.date_of_birth).format("YYYY") + (
                                artistsDetails?.life_dates?.date_of_death !== ""  ? " - " +
                              dayjs(artistsDetails?.life_dates?.date_of_death).format( "YYYY") : ""))
                              :
                              <>
                              {paintingDetails?.artist?.name} - {(paintingDetails?.year)}
                              </>
                              }
                            </p>
                          </div>
                        </div>

                        {page? null : (
                          <div className="d-flex align-items-center">
                            <p className="price">{paintingDetails?.price_per_part?.value}</p>
                            <p className="currency">{paintingDetails?.price_per_part?.curr == "USD" ? "$" : "€"}</p>
                            <p className="parts">/Part</p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="line-chart">
                      <LineChart
                        evolution_list={page ? artistsDetails?.evolution_list: paintingDetails?.evolution_list}
                        // details={screenWidth < 475 ? "" : "details"}
                        details="details"
                    
                      />
                    </div>
                  </>
                )}
              </div>

              {page?
                <div className="description" dangerouslySetInnerHTML={{ __html: i18n?.language === "en"? artistsDetails?.description?.en : artistsDetails?.description?.fr }} />
              :
                <div className="description" dangerouslySetInnerHTML={{ __html: i18n?.language === "en"? paintingDetails?.description?.en : paintingDetails?.description?.fr }} />
              }
              {/* <div className="key-number">
                <h1 className="heading">Key numbers</h1>
                {page ? (
                  <p>
                    <li>
                      Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                      of
                    </li>
                    <li> "de Finibus Bonorum et Malorum" </li>
                    <li>
                      (The Extremes of Good and Evil) by Cicero, written
                      in 45 BC.
                    </li>
                    <li>
                      "Lorem ipsum dolor sit amet..", comes from a line in
                      section1.10.32.
                    </li>
                  </p>
                ) : (
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply
                    random text. It has roots in a piece of classical
                    Latin literature from 45 BC, making it over 2000 years
                    old. Richard McClintock, a Latin professor at
                    Hampden-Sydney College in Virginia, looked up one of
                    the more obscure Latin words, consectetur, from a
                    Lorem Ipsum passage, and going through the cites of
                    the word in classical literature, discovered the
                    undoubtable source.
                  </p>
                )}
              </div> */}

              {/* <div className="history-section">
                <h1 className="heading">History</h1>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply
                  random text. It has roots in a piece of classical Latin
                  literature from 45 BC, making it over 2000 years old.
                  Richard McClintock, a Latin professor at Hampden-Sydney
                  College in Virginia, looked up one of the more obscure
                  Latin words, consectetur, from a Lorem Ipsum passage,
                  and going through the cites of the word in classical
                  literature, discovered the undoubtable source. Lorem
                  Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                  Finibus Bonorum et Malorum" (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a
                  treatise on the theory of ethics, very popular during
                  the Renaissance.
                  <br />
                  <br />
                  The first line of Lorem Ipsum, "Lorem ipsum dolor sit
                  amet..", comes from a line in section 1.10.32.
                  <br />
                  <br />
                  The standard chunk of Lorem Ipsum used since the 1500s
                  is reproduced below for those interested. Sections
                  1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum"
                  by Cicero are also reproduced in their exact original
                  form, accompanied by English versions from the 1914
                  translation by H. Rackham. Contrary to popular belief,
                  Lorem Ipsum is not simply random text. It has roots in a
                  piece of classical Latin literature from 45 BC, making
                  it over 2000 years old. Richard McClintock, a Latin
                  professor at Hampden-Sydney College in Virginia, looked
                  up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites
                  of the word in classical literature, discovered the
                  undoubtable source. Lorem Ipsum comes from sections
                  1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"
                  (The Extremes of Good and Evil) by Cicero, written in 45
                  BC. This book is a treatise on the theory of ethics,
                  very popular during the Renaissance.
                  <br />
                  <br />
                  Contrary to popular belief, Lorem Ipsum is not simply
                  random text. It has roots in a piece of classical Latin
                  literature from 45 BC, making it over 2000 years old.
                  Richard McClintock, a Latin professor at Hampden-Sydney
                  College in Virginia, looked up one of the more obscure
                  Latin words, consectetur, from a Lorem Ipsum passage,
                  and going through the cites of the word in classical
                  literature, discovered the undoubtable source. Lorem
                  Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                  Finibus Bonorum et Malorum" (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a
                  treatise on the theory of ethics, very popular during
                  the Renaissance.
                </p>
              </div> */}
            </div>

            <div className="col-md-6">
              <h1 className="heading">&nbsp;</h1>
              <div className="row">
                {painting_list?.map((suggestion, index) => (
                  <div className="col-md-6 res-container">
                  {/* <Link to={`/secondary-market/details/${suggestion?._id}`}  state={{ path: "secondary-market" }}
                     className="col-md-6 res-container"
                     key={index}
                   > */}
                    <div className="suggestions">
                      <div>
                        <img src={baseURL + suggestion?.cover_img} alt="image" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="name">{i18n?.language === "en" ? suggestion?.title?.en:suggestion?.title?.fr}</p>
                        <p className="price">{suggestion?.price?.curr== "USD" ? "$" : "€"} {suggestion?.price?.value}</p>
                      </div>
                    </div>
                  {/* </Link> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
}

export default Details;
