import React, { useState, useEffect } from "react";
import { getAdminParts, getAdminPartsGraph, getPaintingDetails, salePaintings } from "../../../Services/Api/Api";
import { Table, Spin, notification } from "antd";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MultiLineChartAdminMyParts from "../../../Components/Charts/MultiLineChartAdminMyParts";
import BuyDrawer from "../../../Components/BuyDrawer/BuyDrawer";
import ConfirmModal from "../../../Components/Modals/MyParts/ConfirmModal/ConfirmModal";
import SuccessModal from "../../../Components/Modals/MyParts/SuccessModal/SuccessModal";
import CustomPagination from "../../../Components/Pagination/CustomPagination";
import { useTranslation } from "react-i18next";
import "./MyParts.css";

function MyParts() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [isLoading, setLoading] = useState(true);
  const [isGraphLoading, setGraphLoading] = useState(true);
  const [baseURL, setBaseURL] = useState("");
  const [graph, setGraph] = useState([]);
  const [sellPartsData, setSellPartsData] = useState({})
  const [paintingData, setPaintingData] = useState();
  const curr = localStorage.getItem("currency");

  useEffect(() => {
    getEvolutionData();
  }, []);

  useEffect(() => {
    getData();
  }, [page, limit]);

  // Get Parts Evolutions Graph Data
  const getEvolutionData = async () => {
    const result = await getAdminPartsGraph();
    const { data, status, error } = result || {};
    if (status == 200) {
      setGraph(data);
      setGraphLoading(false);
    } else {
      setGraphLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  // Get Parts Table Data
  const getData = async () => {
    const offset = (page - 1) * limit;
    const result = await getAdminParts(limit, offset);
    const { data, error, status } = result || {};
    if (status === 200) {
      setTableData(data?.getParts);
      setBaseURL(data?.base_url);
      setLoading(false);
    } else {
      setLoading(false);
      notification.error({ message: "Internal server error" });
    }
  };

  const getPaintingData = async (id) => {
    const result = await getPaintingDetails(id, curr);
    const { data, status, error } = result;
    if (status == 200) {
      setPaintingData(data?.paintings)
    } else {
      notification.error({ message: "Internal server error" });
    }
  };

  // Modal -Sell
  const [drawOpen, setDrawOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [sellData, setSellData] = useState({})

  // Open Draw
  const showDrawer = (record) => {
    setDrawOpen(true);
    setSellPartsData(record)
    getPaintingData(record.painting.id)
  };

  // Close Draw
  const onCloseDraw = () => {
    setDrawOpen(false);
  };

  // Sell Confirm
  const handleSellConfirm = (perParts, Id) => {
    setConfirmModal(true);
    setSellData({
      ...sellData,
      perParts,
      Id,
    });
  };

  // Sell Success
  const handleSellSuccess = async () => {
    setConfirmModal(false);
    const result = await salePaintings(sellData.perParts, sellData.Id);
    const { data, status, error } = result || {};
    if (status === 200) {
      setTimeout(()=>{
        setDrawOpen(false);
        setLimit(4);
        setPage(1)
        getData();
        setSuccessModal(true);
      }, 2000)
      notification.success({message: data?.message?.en ? data?.message?.en : data?.message})
    } else {
      notification.error({ message: error.en? error.en : error });
    }
  };

  // History Click
  const handleHistoryClick = () => {
    navigate("/user/history", { state: { key: "ARTWORK" } });
  };

  const columns = [
    {
      title: t("MyPartsWorks"),
      dataIndex: "painting",
      key: "painting",
      align: "center",
      width: "370px",
      render: (painting, record) => (
        <div className="image-details">
          <div>
            <img src={baseURL + painting?.img} alt="image" />
          </div>

          <div>
            <p className="title">
              {i18n?.language === "en"
                ? painting?.title?.en
                : painting?.title?.fr}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: t("MyPartsPartPrice"),
      dataIndex: "part_price",
      key: "part_price",
      align: "center",
      width: "200px",
      render: (part_price, record) => (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div>{part_price?.value} {part_price?.curr}</div>
        {record?.percentage !=0 ? (
          record?.percentage >= 0 ? 
          <p className="increament">+{record?.percentage} %</p>
          : 
          <p className="decreament">{record?.percentage} %</p>
          ) : null
        }
      </div>
      )
    },
    {
      title: t("MyPartsParts"),
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "160px",
    },
    {
      title: t("MyPartsTotal"),
      dataIndex: "total",
      key: "total",
      align: "center",
      width: "160px",
      render: (total) => <div>{total}</div>,
    },
    {
      title: t("MyPartsValueOfWork"),
      dataIndex: "value_of_the_work",
      key: "value_of_the_work",
      align: "center",
      width: "180px",
      render: (value_of_the_work) => (
        <div style={{ width: 150 }}>
          {value_of_the_work?.value} {value_of_the_work?.curr}
        </div>
      ),
    },
    {
      title: t("MyPartsAction"),
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (_id, record) => (
        <div className="details-button details-button-my-parts ">
          <Button onClick={() => showDrawer(record)}>{t("MyPartsSell")}</Button>
          <Button onClick={() => handleHistoryClick()}>{t("MyPartsHistory")}</Button>
        </div>
      ),
    },
  ];

  return (
    <div className="my-parts">
      <h1 className="heading">{t("MyParts")}</h1>

      <div className="multi-chart">
        {isGraphLoading ? (
          <div className="table">
            <div className="spin">
              <Spin />
            </div>
          </div>
        ) : (
          <MultiLineChartAdminMyParts graph={graph} />
        )}
        <div className="chart-name">
          <p>{t("MyPartsICEvolution")}</p>
        </div>
      </div>

      <div className="secondary">
        <div className="table">
          {isLoading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <div>
              <Table
                pagination={false}
                dataSource={tableData}
                columns={columns}
                rowKey="_id"
              />
              <CustomPagination
                tableLength={tableData?.length < 4}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>

      {/* My Parts Sell - Buy Drawer */}
      <BuyDrawer
        onClose={onCloseDraw}
        open={drawOpen}
        path="parts"
        baseURL={baseURL}
        handleSellConfirm={handleSellConfirm}
        primaryPainting={paintingData}
        ownParts={sellPartsData}
      />

      {/* My Parts Sell - Confirm with OTP Modal */}
      <ConfirmModal
        onClose={setConfirmModal}
        visible={confirmModal}
        handleSellSuccess={handleSellSuccess}
      />

      {/* My Parts Sell - Purchase Successfull Modal */}
      <SuccessModal 
        onClose={setSuccessModal} 
        visible={successModal} 
      />
    </div>
  );
}

export default MyParts;
