import { auth } from "../Firebase/Firebase";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { socialAuth } from "../../Services/Api/Api";
import { notification } from "antd";

// Social Aut Login Fun
export const socialAuthFun = async (token, type, navigate, i18n) => {
  const result = await socialAuth(token, type);
  const { data, status, error } = result || {};
  if (status === 200) {
    const { lang, curr, iev, token } = data || {};
    const { access_token, refresh_token } = token || {};
    localStorage.setItem("token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem("currency", curr);
    i18n.changeLanguage(lang);
    setTimeout(() => {
      if (iev) {
        navigate("/");
      } else {
        navigate("/verify-email");
      }
    }, 500);
    notification.success({ message: "Login Successful" });
  } else {
    notification.error({ message: error?.en });
  }
};

// Google Login
export const GoogleLogin = (navigate, i18n) => {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  const auth = getAuth();
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const { accessToken } = result?.user || {};
      if (accessToken) {
        socialAuthFun(accessToken, "google", navigate, i18n);
      }
    })
    .catch((error) => {
      const errorCode = error?.code;
      if (errorCode === "auth/cancelled-popup-request") {
        return;
      } else if (errorCode === "auth/cancelled-popup-request") {
        return;
      } else if (errorCode === "auth/popup-closed-by-user") {
        return;
      } else if (errorCode === "auth/email-already-in-use") {
        notification.error({
          message: "Email already exists. Please log in or use another email.",
        });
      } else if (
        errorCode === "auth/account-exists-with-different-credential"
      ) {
        notification.error({
          message: "An account already exists with the same email address.",
        });
      } else {
        notification.error({
          message: "Something went wrong. Please try again later.",
        });
      }
    });
};

// Facebook Login
export const FacebookLogin = (navigate, i18n) => {
  // const provider = new FacebookAuthProvider();
  // const auth = getAuth();
  // signInWithPopup(auth, provider)
  //   .then((result) => {
  //     // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //     const credential = FacebookAuthProvider.credentialFromResult(result);
  //     // Do something with the user data
  //     if (credential.accessToken) {
  //       socialAuthFun(credential.accessToken, "facebook", navigate, i18n);
  //     }
  //   })
  //   .catch((error) => {
  //      const errorCode = error?.code;
  //     if (errorCode === 'auth/email-already-in-use') {
  //       notification.error({ message: 'Email already exists. Please log in or use another email.' });
  //     }
  //     else if (errorCode === 'auth/cancelled-popup-request') {
  //       return
  //     }
  //     else if (errorCode === 'auth/account-exists-with-different-credential') {
  //       notification.error({ message: "An account already exists with the same email address." });
  //     }
  //     else{
  //       notification.error({ message: 'Something went wrong. Please try again later.' });
  //     }
  // Handle Errors here.
  // const errorCode = error?.code;
  // const errorMessage = error?.message;
  // The email of the user's account used.
  // const email = error?.email;
  // AuthCredential type that was used.
  // const credential = FacebookAuthProvider.credentialFromError(error);
  // console.error(errorCode, errorMessage);
  // notification.error({ message: errorMessage });
  // });
};
