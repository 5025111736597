import React, { useState, useEffect } from "react";
import "./Press.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import { scrollTop } from "../../Services/Utils/Utils";
import { getBlogs } from "../../Services/Api/Api";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

function Press() {
  const { t, i18n } = useTranslation();
  const [blogs, setBlogs] = useState([]);
  const [total, setTotal] = useState(0);
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    getData();
  }, [currentPage]);

  // Get Blog Data
  const getData = async () => {
    const result = await getBlogs(currentPage, itemsPerPage);
    const { data, status, error } = result || {};
    if (status === 200) {
      setBlogs(data?.data);
      setTotal(data?.total_posts)
    } else {
      console.log("Internal server error");
    }
  };
  
  // Pagination
  const paginate = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="press">
      <Header />
      <div className="container press-container">
        <div className="row">
          <div className="media d-flex align-items-center justify-content-start">
            <div>
              <h1 className="heading">Press & Media News</h1>
            </div>
            <div>
              <p className="description">
                As opposed to using 'Content here, content here', making it look
                like readable English. Many desktop publishing packages and web
                page editors now use Lorem Ipsum as their default model text,
                and a search for 'lorem ipsum' will uncover many web sites still
                in their infancy. Various versions have evolved over the years,
                sometimes by accident, sometimes on purpose (injected humour and
                the like).
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="blogs-container">
              {blogs?.map((item, index) => (
                <div className="news d-flex" key={index}>
                  <div>
                    <img
                      src={i18n?.language === "en" ? item?.image : item?.image_fr}
                      alt="image"
                    />
                  </div>
                  <div className="content">
                    <div>
                      <p className="title">
                        {i18n?.language === "en" ? item?.title : item?.title_fr}
                      </p>
                      <p className="description">
                        {i18n?.language === "en" ? item?.des : item?.des_fr}
                      </p>
                    </div>
                    <div>
                      <span>
                        <a href={i18n?.language === "en" ? item?.url : item?.url_fr }
                          target="_blank"
                          className="read-more d-flex align-items-center justify-content-start"
                        >
                          <p>Read More</p>
                          <img src="assets/images/media/arrow.png" alt="image" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination">
              {blogs?.length !== 0 ?
                <Pagination
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={total}
                  onChange={paginate}
                  showSizeChanger={false}
                />
                : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Press;
