import React from "react";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { Button } from "@mui/material";
import "./OtpModal.css";

function OtpModal({
  visible,
  onClose,
  handleConfirmRequest,
  otp,
  setOtp,
  otpValidation,
  handlePhoneOTPResend,
  profileData
}) {
  return (
    <Modal
      className="otp-modal"
      open={visible}
      onCancel={() => onClose()}
      centered={true}
      closable={true}
      width={620}
      footer={[]}
      destroyOnClose
    >
      <div className="phone-verification">
        <div className="head-tbox">
          <h2>Verification Code</h2>
          <p>
            A verification code has been sent to the phone number ******** {(profileData?.phone?.number)?.toString()?.slice(-5)}
          </p>
        </div>
        <div className="otp-input">
          <h4>Enter Code:</h4>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
          />
          <p className="error">{otpValidation}</p>
          <h5>
            Code not received yet?{" "}
            <Button onClick={() => handlePhoneOTPResend()}>Send again</Button>
          </h5>
        </div>
        <div className="btn-box">
          <Button
            onClick={() => handleConfirmRequest()}
            color="primary"
            varient="text"
            className="confirm"
          >
            <p>Confirm </p>
          </Button>
          <Button
            onClick={() => onClose()}
            color="primary"
            varient="text"
            className="cancel"
          >
            <p>cancel </p>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default OtpModal;
