import React from "react";
import "./FinancialJourney.css";
import { useTranslation } from "react-i18next";

function FinancialJourney() {
  const { t, i18n } = useTranslation();

  return (
    <div className="financial-journey">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="head-tbox">
              <h2>{t("FinancialHeading")}</h2>
            </div>

            {i18n.language != "fr" ? (
              <img src="/assets/images/financial-journey.png" alt="" />
            ) : (
              <img src="/assets/images/financial-journey.png" alt="" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialJourney;
