import React from "react";
import Button from "@mui/material/Button";
import "./LoadMore.css";
import { useTranslation } from "react-i18next";

function LoadMore({ data, limit, setOffset, checkLength }) {
  const { t } = useTranslation();

  const addMore = () => {
    setOffset((prev) => prev + limit);
  };

  return (
    <div className="load-more-button">
      {data?.length != 0 ? (
        <div
          className={
            checkLength == 0 || checkLength < limit
              ? "load-more load-more-empty"
              : "load-more"
          }
        >
          <Button
            onClick={() => {
              addMore();
            }}
            disabled={checkLength == 0}
          >
            {checkLength == 0 ? null : t("LoadMore")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default LoadMore;
