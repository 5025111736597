import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop,
} from "recharts";

const data = [
  { year: "", value: 1 },
  { year: "0999", value: 3 },
  { year: "1546", value: 4 },
  { year: "1789", value: 2 },
  { year: "1888", value: 4 },
  { year: "1952", value: 5 },
  { year: "2025", value: 2 },
  { year: "2450", value: 1 },
  { year: "", value: 0.5 },
];

const DashedLineCharts = ({details, Chartdata}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={Chartdata && Chartdata?.length ? Chartdata: data} margin={{ right: 25, left: 25 }}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(51, 51, 51, 0.3)" />
            <stop offset="100%" stopColor="rgba(51, 51, 51, 0.01)" />
          </linearGradient>
        </defs>
        <XAxis 
          dataKey={Chartdata?"_id":"year"} 
          tickCount={8} 
          interval={0} 
          axisLine={false} 
          tickLine={false} 
        />
        <YAxis hide tickCount={8} />
        <CartesianGrid strokeDasharray="2 10" horizontal={false} />
        {/* <Tooltip content={<div />} /> */}
        <Area
          strokeDasharray="8 8"
          type="monotone"
          dataKey={Chartdata?"totalValue":"value"}
          stroke="#333333" // Use the gradient fill
          strokeWidth={2}
          fill="url(#gradient)" // Use the gradient fill
          // fillOpacity={1} // Set fill opacity to 1 for the gradient to be fully visible
          connectNulls
          dot={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashedLineCharts;
// right: -25, left: -25