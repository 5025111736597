import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "../Pages/AdminLayout/AdminLayout";
import Profile from "../Pages/AdminLayout/Profile/Profile";
import EditProfile from "../Pages/AdminLayout/Profile/EditProfile/EditProfile";
import Portfolio from "../Pages/AdminLayout/Portfolio/Portfolio";
import Market from "../Pages/AdminLayout/Market/Market";
import PrimaryMarket from "../Pages/AdminLayout/Market/PrimaryMarket/PrimaryMarket";
import MyParts from "../Pages/AdminLayout/MyParts/MyParts";
import Withdraw from "../Pages/AdminLayout/Withdraw/Withdraw";
import History from "../Pages/AdminLayout/History/History";

function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit" element={<EditProfile />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/market" element={<Market />} />
        <Route path="/market/primary-market" element={<PrimaryMarket />} />
        <Route path="/parts" element={<MyParts />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/history" element={<History />} />
      </Route>
    </Routes>
  );
}

export default AdminRoutes;
