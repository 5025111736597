import React, { useState, useEffect } from "react";
import { Modal, Input, Form } from "antd";
import "./ReadMoreModal.css";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

function ReadMoreModal({ visible, onClose, page,primaryPainting, baseURL }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      className="read-more-modal"
      open={visible}
      onCancel={() => onClose()}
      centered={true}
      closable={true}
      width={936}
      destroyOnClose={true}
      footer={[]}
    >
      <div className="read-more">
        <div className="container mt-3">
          {!page ?
          <div className="row mb-4">
            <div className="col-md-4">
              <img className="main-image" src={baseURL + primaryPainting?.cover_img} alt="cover-image" />
            </div>
            <div className="col-md-8">
              <div className="first">
                <img src={baseURL + primaryPainting?.artist?.img} alt="image" />
                <div className="info-box">
                  <h4>{primaryPainting?.artist?.name}</h4>
                  <p>
                    {dayjs(primaryPainting?.artist?.life_dates?.date_of_birth).format("YYYY")} {
                      primaryPainting?.artist?.life_dates?.date_of_death !== ""  ? " - " +
                      dayjs(primaryPainting?.artist?.life_dates?.date_of_death).format( "YYYY") : ""}
                   </p>
                </div>
              </div>
              <div className="second">
                <h3>
                {i18n?.language === "en" ? primaryPainting?.title?.en : primaryPainting?.title?.fr} <span>({`${primaryPainting?.year}`})</span>
                </h3>
                <h4>{primaryPainting?.price_per_part?.value} <span className="currency">{primaryPainting?.price_per_part?.curr === "USD" ? "$" : "€"}</span> <span className="price">/Part</span></h4>
              </div>
            </div>
          </div>
          :null} 
          <div className="row">
            <div className="content">
              <p>
                {i18n?.language === "en" ? primaryPainting?.description?.en : primaryPainting?.description?.fr}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ReadMoreModal;
