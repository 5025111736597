import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import { Form, Input, notification } from "antd";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { validatePassword, clearLocalStorage } from "../../../Services/Utils/Utils";
import { changePassword, resetPasswordPage } from "../../../Services/Api/Api";
import Carousal from "../../../Components/Carousal/Carousal";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

function ChangePassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { email, token } = location?.state || {};
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email || !token) {
      backToLogin()
    } else {
      resetPassPage();
    }
  }, []);

  // Check If Token Is Valid
  const resetPassPage = async () => {
    const result = await resetPasswordPage(token);
    const { data, error, status } = result?.data || {};
    if (status === 200) {
      return;
    } else {
      backToLogin()
      notification.error({ message: error?.en });
    }
  };

  const onFinish = async (values) => {
    values.email = email;
    setLoading(true);
    const result = await changePassword(values, token);
    const { data, error, status } = result;
    if (status === 200) {
      setLoading(false);
      notification.success({ message: data?.message?.en });
      clearLocalStorage()
      backToLogin()
    } else {
      setLoading(false);
      notification.error({ message: error?.en });
    }
  };

  // Back To Login
  const backToLogin = async () => {
    window.history.replaceState(null, null, "/login");
    navigate("/login");
  }

  return (
    <div className="change-password">
      <Header />
      <div className="container">
        <div className="change-password-conatiner row">
          <div className="col-md-6">
            <Carousal />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="change-password-section">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="main-image"
                  src="/assets/images/auth/reset.png"
                  alt="image"
                />
                <h1 className="heading">Reset your password</h1>
                <p className="description">Enter your new password</p>
              </div>
              <div>
                <Form
                  form={form}
                  className="change-password-form"
                  onFinish={onFinish}
                >
                  <div className="password">
                    <label>Password</label>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "You cannot leave this field blank",
                        },
                        { min: 8, message: "Should be 8 characters." },
                        {
                          max: 100,
                          message: "Can not be more than 100 characters.",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter your password"
                        size="large"
                      />
                    </Form.Item>
                  </div>

                  <div className="confirm-password">
                    <label>Confirm Password</label>
                    <Form.Item
                      name="confirm_password"
                      rules={[
                        {
                          required: true,
                          message: "You cannot leave this field blank",
                        },
                        {
                          max: 100,
                          message: "Can not be more than 100 characters.",
                        },
                        {
                          validator: validatePassword(form),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter your password"
                        size="large"
                      />
                    </Form.Item>
                  </div>

                  <div className="change-password-section d-flex align-items-center justify-content-between">
                    <Button
                      className="change-password-button"
                      type="submit"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress
                          disableShrink
                          size={24}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <>
                          <p>Reset</p>
                          <img
                            src="/assets/images/auth/arrow.png"
                            alt="arrow"
                          />
                        </>
                      )}
                    </Button>
                    <Button onClick={()=>{backToLogin()}} className="cancel-button" color="primary">
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ChangePassword;
